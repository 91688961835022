import { Component } from '@angular/core';
import { DowntimeNotificationVm } from '../generated/Administration/Models/DowntimeNotification/DowntimeNotificationVm';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'pcg-downtime-notification',
  templateUrl: './downtime-notification.component.html',
  styleUrls: ['./downtime-notification.component.scss']
})
export class DowntimeNotificationComponent {
  downtimeNotifications: DowntimeNotificationVm[];

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    this.checkForDowntimeNotifications()
  }

  checkForDowntimeNotifications() {
    this.httpClient.get<DowntimeNotificationVm[]>('api/Administration/DowntimeNotification/GetDowntimeNotifications')
    .subscribe((model: DowntimeNotificationVm[]) => {
      let dismissedNotificationIds: number[] = JSON.parse(localStorage.getItem("downtimeNotification")) ?? [];

      this.downtimeNotifications = model.filter(o => !dismissedNotificationIds?.includes(o.downtimeNotificationId));
    });
   }

   dismiss(downtimeNotificationId: number) {
    this.downtimeNotifications = this.downtimeNotifications.filter(o => o.downtimeNotificationId != downtimeNotificationId);
    
    let dismissedNotificationIds: number[] = JSON.parse(localStorage.getItem("downtimeNotification")) ?? [];
    dismissedNotificationIds.push(downtimeNotificationId);
    localStorage.setItem('downtimeNotification', JSON.stringify(dismissedNotificationIds));
   }
}
