<form [formGroup]="formGroup" pcgSubmitUrl="api/Sales/SalesRepresentative/SaveSalesRep"
    (messageReceive)="saveSalesRep($event)">
    <div class="row">
        <input type="hidden" formControlName="salesRepId" />
        <div class="col-md-6">
            <div class="row">
                <pcg-control-group class="col-md-8" label="Full Name">
                    <input pcgControlRef class="form-control" type="text" formControlName="fullName" />
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Email">
                    <input pcgControlRef class="form-control email" type="text" formControlName="email" />
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Phone Number">
                    <input pcgPhoneExt pcgControlRef class="form-control" type="text" inputmode="tel"
                        formControlName="phoneNumber" maxlength="21" />
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Team">
                    <input pcgControlRef class="form-control" type="text" formControlName="team" />
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Manager?">
                    <input pcgControlRef class="checkbox" type="checkbox" formControlName="isManager" />
                </pcg-control-group>
            </div>
        </div>

        <div class="col-md-6">
            <div class="row">
                <pcg-control-group class="col-md-8" label="Territory">
                    <pcg-select dataSource="api/Select/Territories" placeholder="Select a Territory"
                        formControlName="territory" [multiple]="true" pcgControlRef></pcg-select>
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Region">
                    <pcg-select dataSource="api/Select/Regions" placeholder="Select a Region" formControlName="region"
                        [multiple]="true" pcgControlRef></pcg-select>
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Commission">
                    <input pcgNumberDecimal pcgPercentage pcgControlRef class="form-control" type="text"
                        formControlName="commission" />
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Discount">
                    <input pcgNumberDecimal pcgPercentage pcgControlRef class="form-control percent" type="text"
                        formControlName="discount" />
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Payroll ID">
                    <input pcgControlRef class="form-control percent" type="number" formControlName="payrollId" />
                </pcg-control-group>
            </div>

        </div>
        <pcg-fixed-buttons>
            <a class="btn btn-cancel btn-lg" backButton="/sales-representatives">Back</a>
            <button type="submit" *ngIf="id !== 0" class="btn btn-save btn-lg">Save Changes</button>
            <button type="submit" *ngIf="id === 0" class="btn btn-save btn-lg">Save</button>
        </pcg-fixed-buttons>
    </div>
</form>

<mat-tab-group>
    <mat-tab label="Customers">
        <pcg-customer-list [salesRepId]="id"></pcg-customer-list>
    </mat-tab>
</mat-tab-group>