import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { CustomerListVm } from '../../shared/generated/Customers/Models/CustomerListVm'
import { CustomerListApprovalVm } from '../../shared/generated/Customers/Models/CustomerListApprovalVm'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomerFileUploadVm } from 'app/shared/generated/Transactions/Models/Upload/CustomerFileUploadVm';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCustomerModalComponent } from '../../sales/sales-representatives/add-customer-modal/add-customer-modal.component';
import { TableComponent } from 'app/shared/table/table.component';
import { HttpClient } from '@angular/common/http';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { de } from 'date-fns/locale';


@Component({
  selector: 'pcg-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrl: './customer-list.component.scss'
})
export class CustomerListComponent {
  @Input() salesRepId: number | null = null;
  @Output() customerListApproved: EventEmitter<void> = new EventEmitter();
  @ViewChild(TableComponent, { static: false }) table: TableComponent<CustomerListVm>;
  @ViewChild('importCustomers', { static: true }) modalContent: TemplateRef<any>;
  formGroup = CustomerFileUploadVm.Form;
  columns = CustomerListVm.ColumnDefs;
  dataSource = "api/Customers/Customers/GetCustomerList/";
  deleteUrl = `api/Customers/Customers/DeleteCustomer`

  isUsingMobileCards: boolean = true;

  cusomerListApprovalModel: CustomerListApprovalVm;
  constructor(public title: Title, public modalService: NgbModal, public router: Router, private httpClient: HttpClient, private systemMessageService: SystemMessageService) {
  }

  ngOnInit() {
    if(this.salesRepId != null) {
      this.dataSource += this.salesRepId;
      this.deleteUrl = `api/Sales/SalesRepresentative/DeleteCustomerFromSalesRep`
      return;
    }

    this.httpClient.get<CustomerListApprovalVm>('api/Customers/Customers/GetCustomerApproval').subscribe((approval: CustomerListApprovalVm) => {
      this.cusomerListApprovalModel = approval;
      if(this.cusomerListApprovalModel.isCustomerListApproved) {
        this.customerListApproved.emit();
      }
    });

    this.title.setTitle("Customers");
  }

  redirectCustomer(row) {
    this.router.navigate(['master-customers/edit-master-customer', row.customerId])
  }

  openImportModal() {
    this.modalService.open(this.modalContent);
  }

  openAddCustomerModal(){
    const modalRef = this.modalService.open(AddCustomerModalComponent, {size: "lg"});
    modalRef.componentInstance.salesRepId = this.salesRepId;
    modalRef.result.then((isSuccessful: boolean) => {
      if(isSuccessful) {
        this.table.ajaxReload();
      }
    });
  }

  approveCusomerList() {
    this.httpClient.get<SystemMessage>('api/Customers/Customers/ApproveCustomerList').subscribe((message: SystemMessage) => {
      this.systemMessageService.setSystemMessage(message.message, message.messageClass);
      this.cusomerListApprovalModel = message.model;
      this.customerListApproved.emit();
    });
  }
}
