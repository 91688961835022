export interface ISilkierHistoryTbl {
	fireInstanceId: number;
	schedulerInstanceId?: string;
	schedulerName?: string;
	job?: string;
	trigger?: string;
	scheduledFireTimeUtc?: Date;
	scheduledFireTimeUtcStr?: string;
	actualFireTimeUtc: Date;
	actualFireTimeUtcStr?: string;
	recovering: boolean;
	finishedTimeUtc?: Date;
	finishedTimeUtcStr?: string;
	logMessage?: string;
	exceptionMessage?: string;
	wasSuccessful?: boolean;
};

export class SilkierHistoryTbl implements ISilkierHistoryTbl {
	fireInstanceId = 0;
	schedulerInstanceId = '';
	schedulerName = '';
	job = '';
	trigger = '';
	scheduledFireTimeUtc = new Date();
	scheduledFireTimeUtcStr = '';
	actualFireTimeUtc = new Date();
	actualFireTimeUtcStr = '';
	recovering = false;
	finishedTimeUtc = new Date();
	finishedTimeUtcStr = '';
	logMessage = '';
	exceptionMessage = '';
	wasSuccessful = false;
};
