export interface IAuditHistoryTbl {
	dateChanged: Date;
	dateChangedFormatted?: string;
	field?: string;
	changedFrom?: string;
	changedTo?: string;
	changedBy?: string;
};

export class AuditHistoryTbl implements IAuditHistoryTbl {
	dateChanged = new Date();
	dateChangedFormatted = '';
	field = '';
	changedFrom = '';
	changedTo = '';
	changedBy = '';
};
