import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ButtonFilterService } from '../button-filter/button-filter.service';

@Component({
    selector: 'pcg-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() id: string;
    
    @ViewChild('filter', { static: false }) filter: ElementRef;
    @ViewChild('filterBody', { static: false }) filterBody: ElementRef;

    public element: any;
    isVisible = false;

    constructor(private buttonFilterService: ButtonFilterService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    @HostListener('window:keyup', ['$event'])
    @HostListener('window:change', ['$event']) onChange() { this.positionFilter(); }
    @HostListener('window:resize', ['$event']) onResize() { this.close(); }  

    ngOnInit(): void {
        // Ensure id attribute exists
        if (this.id) {
            // Add self (this modal instance) to the modal service so it's accessible from controllers
            this.buttonFilterService.addFilter(this);
        }
    }

    ngAfterViewInit(): void {
        // Add filter component to DOM after pcg-root
        document.body.firstElementChild.appendChild(this.element);

        // Close modal on background click
        window.addEventListener('click', (el: Event) => {
            // Classes associated to the target element
            const classes = el.target['className']?.toString();
            // If filters are open
            if (this.isVisible) {
                if (classes === 'pcg-table-filter-overlay') { this.close(); } // If target element is filter overlay, close filter
                if (classes.includes('filter-reset-btn')) // If filter button is click, adjust filter position after page is updated,
                setTimeout(() => { this.positionFilter(); }, 100);
            }
        });
    }

    // Open modal
    open(): void {
        this.isVisible = true;
        this.positionFilter();
    }

    // Make sure filters are lined up with the filter toggle button
    positionFilter() {
        // Position and dimensions of the filter dropdown button
        let rect: DOMRect = document.getElementById('pcg-button-filter')?.getBoundingClientRect();
        
        if (rect?.bottom === 0 && rect?.left === 0) {
            rect = document.getElementById('isMobile').children.namedItem('pcg-button-filter')?.getBoundingClientRect();            
        } 
        
        this.filterBody.nativeElement.style.top = `${parseInt((rect?.bottom + 1).toString(), 10)}px`;
        this.filterBody.nativeElement.style.left = `${parseInt((rect?.left - 39).toString(), 10)}px`;    
    }

    // Close modal
    close() { this.isVisible = false; }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.buttonFilterService.removeFilter(this.id);
        this.element.remove();
    }
}
