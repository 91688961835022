<br />
<img class="system-logo" src="assets/images/logos/logo_transparent.png" />
<br />
<h2 class="text-center">Change Password</h2>
<br />

<div class="change-password-container clearfix">
	<form 
		*ngIf="!hasExpired" 
		(ngSubmit)="onSubmit()" 
		[formGroup]="changePasswordForm"
	>
		<pcg-show-hide-password 
			[controlName]="'password'" 
			[parentForm]="changePasswordForm" 
			label="New Password"
			[validations]="{ 
				commonPassword: 'Pick a less common password.', 
				pattern: 'Password cannot contain more than 2 repeating characters.', 
				sequentialChars: 'Password cannot contain more than 3 sequential characters.'
			}" 
			inputCssClass="form-control"
		></pcg-show-hide-password>

		<pcg-show-hide-password 
			[controlName]="'confirmPassword'" 
			[parentForm]="changePasswordForm" 
			label="Confirm New Password"
			[validations]="{ passwordsNotEqual: 'Passwords do not match.' }" 
			inputCssClass="form-control"
		></pcg-show-hide-password>
		<button type="submit" class="btn btn-secondary btn-lg">Change Password</button>
	</form>
	
	<div *ngIf="hasExpired" class="text-danger">{{errorMessage}}</div>
</div>
<br /><br />

<div class="text-center">
	<u>Password Requirements</u><br />
	Must be at least 10 characters in Length.<br />
	Cannot match any of the passwords in our list of commonly used passwords.<br />
	(Examples of Commonly Used Passwords: "Password", "Love", "Password123", etc.)<br />
</div>
