<div 
    id="time-picker-wrapper" 
    class="{{config.theme}}" 
    [ngClass]="{'active': activeModal, 'static': !isPopup}" 
    (click)="Close($event);"
>
    <div 
        id="time-picker" 
        [ngClass]="{'active': activeModal, 'static': !isPopup}"
    >
        <div class="time-picker-header">
            <div class="time-picker-selected-time">
                <div 
                    class="time-picker-hour" 
                    (click)="HourClick()" 
                    [attr.disabled]="(config.onlyMinute) ? 'disabled' : null"
                    [ngClass]="{'selected' : clockType === 'hour'}"
                >{{GetHour()}}</div>
                <span class="time-seprator">{{GetSeparator()}}</span>
                <div 
                    class="time-picker-minute" 
                    (click)="MinuteClick();" 
                    [attr.disabled]="(config.onlyHour) ? 'disabled' : null"
                    [ngClass]="{'selected' : clockType === 'minute'}"
                >{{GetMinute()}}</div>
            </div>
            <div class="time-picker-selected-ampm">
                <div 
                    class="time-picker-am" 
                    (click)="SetAM();" 
                    [attr.disabled]="(config.onlyPM) ? 'disabled' : null" 
                    [ngClass]="{'selected' : time.ampm === 'AM'}"
                >{{GetPeriod('AM')}}</div>
                <div 
                    class="time-picker-pm" 
                    (click)="SetPM();" 
                    [attr.disabled]="(config.onlyAM) ? 'disabled' : null" 
                    [ngClass]="{'selected' : time.ampm === 'PM'}"
                >{{GetPeriod('PM')}}</div>
            </div>
        </div>
        <div class="time-picker-content">
            <div 
                class="time-picker-clock" 
                [ngClass]="{'hide-time-picker-clock': changeToMin}" 
                (pointermove)="getDegree($event);" 
                (pointerdown)="updateClockDown($event)" 
                (pointerup)="setTime();"
            >
                <button 
                    *ngFor="let clock of clockObject" 
                    [ngClass]="{'active' : nowTime === clock.time}" 
                    [id]="'timepicker-item-id-' + clock.time" 
                    [disabled]="checkDisabled(clock.time)" 
                    [ngStyle]="{
                        top: clock.top,
                        left: clock.left, 
                        color: nowTime === clock.time ? config.arrowStyle.color : '', 
                        background: nowTime === clock.time ? config.arrowStyle.background : 'transparent'}"
                    >
                    {{GetClockTime(clock)}}
                </button>
                <div 
                    class="time-picker-clock-origin" 
                    [ngStyle]="{ background: config.arrowStyle.background}"
                ></div>
                <div 
                    id="tpc-arrow" 
                    class="time-picker-clock-arrow" 
                    [ngStyle]="getClockArrowStyle()"
                >
                    <span [ngStyle]="{ background: config.arrowStyle.background }"></span>
                </div>
            </div>
        </div>
        <div class="time-picker-footer">
            <button (click)="Close($event);">{{GetLabel('cancel')}}</button>
            <button (click)="GetTime();Close($event);" class="atp-ref-dialog-close">{{GetLabel('ok')}}</button>
        </div>
    </div>
</div>
