import { Injectable } from "@angular/core";

//Class used to format things in the system
@Injectable({
	providedIn: 'root'
})
export class FormatHelperService {
    //Keep these static where anyone can use them without DI
    static FormatNdc = (ndc: string): string => ndc?.replace(/(\d{5})(\d{4})(\d{2})/, '$1-$2-$3');
    static GetIsNully = (value: any): boolean => value === undefined || value === null;
    static GetIsNullyOrWhitespace = (value: string): boolean => FormatHelperService.GetIsNully(value) || value?.replace(/\s/g, '') === '';
    static ToTitleCase = (value: string) => !FormatHelperService.GetIsNullyOrWhitespace(value) 
        ? value.replace(/\w\S*/g, function(txt) { return txt.charAt(0).toUpperCase() + txt.substring(1); })
        : '';

    //Keep these non-static so legacy places that were using DI can still do so
    FormatNdc = (ndc: string): string => FormatHelperService.FormatNdc(ndc);
    GetIsNully = (value: any): boolean => FormatHelperService.GetIsNully(value);
    GetIsNullyOrWhitespace = (value: string): boolean => FormatHelperService.GetIsNullyOrWhitespace(value);
    ToTitleCase = (value: string) => FormatHelperService.ToTitleCase(value);
}
