export interface Permission {
	id: number;
	coreFeatures: boolean;
	manageUsers: boolean;
	applicationSettings: boolean;
	canChangePermissions: boolean;
	adminFeatures: boolean;
	viewQuartzJobs: boolean;
	permissionRole?: any;
}

export class PermissionConst {
	public static coreFeatures = "coreFeatures";
	public static manageUsers = "manageUsers";
	public static applicationSettings = "applicationSettings";
	public static canChangePermissions = "canChangePermissions";
	public static adminFeatures = "adminFeatures";
	public static viewQuartzJobs = "viewQuartzJobs";
}
