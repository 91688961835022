/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuditHistoryVm } from 'app/shared/generated/Models/AuditHistoryVm';
import { TableComponent } from '../table/table.component';
import { GlobalVariablesService } from 'app/services/global-variables.service';

@Component({
    selector: 'pcg-shared-audit-history',
    templateUrl: './audit-history.component.html',
    styleUrls: ['./audit-history.component.scss']
})
export class AuditHistoryComponent implements OnInit {

    @ViewChild(TableComponent, { static: true }) table: TableComponent<AuditHistoryVm>;

	@Input() id: number;
    @Input() viewModel: string;
    @Input() auditHistoryName: string;

    columns = AuditHistoryVm.ColumnDefs;
	auditName: string;
	filters = {
		id: null,
		viewModel: null,
		auditName: null
	};

    constructor(private activatedRoute: ActivatedRoute, private globalVariablesService: GlobalVariablesService) { }

    ngOnInit() {

        if (this.id && this.viewModel && this.auditHistoryName) {
            this.filters.id = this.id;
            this.filters.viewModel = this.viewModel;
            this.filters.auditName = this.auditHistoryName;
        } else {
			this.getFiltersFromLocalStorage();
        }
    }

	getFiltersFromLocalStorage() {
		this.filters.id = this.activatedRoute.snapshot.params.id;
        if (window.location.href.indexOf("user") > -1) {
            this.filters.viewModel = 'Administration.Models.User.EditUser.EditUserVm';
            this.filters.auditName = 'Edit User Audit History';
        } else {
            this.filters.viewModel = localStorage?.getItem('vmType');
		    this.filters.auditName = localStorage?.getItem('auditName');
        }
	}

    setTableRecieve(event) {
        this.auditName = event.value.auditName;
        this.globalVariablesService.userName.next(event.value.fullName)
    }
}
