<pcg-table-filter id="{{filterId}}" (change)="table.ajaxReload()">
	<div class="filters-ctnr">
		<div class="filter-block">
			<label for="batchSelect" class="for">Batch Number</label>
			<pcg-select 
				labelForId="batchSelect" 
				[(ngModel)]="filters.importHistoryId" 
				dataSource="api/Select/ImportHistories" 
				placeholder="Select a Batch Number"
			></pcg-select>
		</div>
		<div class="filter-block">
			<label for="importDate" class="for">Date Imported</label>
			<pcg-date-range 
				labelForId="importDate" 
				[(dateRange)]="filters.importDate"
			></pcg-date-range>
		</div>
	</div>
</pcg-table-filter>

<pcg-table 
	#table 
	[dataSource]="dataSource"
    [ajaxData]="filters" 
    filterMap="filterMap"
	filterId="{{filterId}}"
	[pcgSort]="[['importDate', 'desc']]"
	[columnDefs]="columns" 
	[canExportTableToExcel]="true"
	isUsingMobileCard="true"
	uniqueColumn="importedCustomer"
>
    <ng-container matColumnDef="batchNumber">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('batchNumber').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('batchNumber').displayName">{{ row.batchNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="fileSource">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('fileSource').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('fileSource').displayName">{{ row.fileSource }}</td>
    </ng-container>

    <ng-container matColumnDef="importDate">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('importDate').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('importDate').displayName">{{ row.importDate | date: 'shortDate' }}</td>
    </ng-container>

    <ng-container matColumnDef="importedCustomer">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('importedCustomer').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('importedCustomer').displayName">
            {{ row.name }} <br /> {{ row.streetAddress }} <br /> {{ row.city }}, {{ row.state }}  {{ row.zipCode }} 
        </td>
    </ng-container>
    
    <ng-container matColumnDef="makeMaster">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('makeMaster').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('makeMaster').displayName">
            <button (click)="makeMasterCustomer(row)" class="btn btn-save">
                Make Master
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="customerId">
		<th mat-header-cell *matHeaderCellDef>{{ columns.get('customerId').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('customerId').displayName">
            <ng-select
                [items]="customers" 
                bindLabel="text" 
                bindValue="value"
                appendTo="body"
                addTagText="Create New"
                [addTag]="openCustomerDetails"
                [(ngModel)]="row.customerId"
                (click)="selectedRow = row;"
            >
            </ng-select>
        </td>
	</ng-container>

    <ng-container matColumnDef="save">
        <th mat-header-cell *matHeaderCellDef>{{ columns.get('save').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('save').displayName">
            <button (click)="saveRow(row)" class="save-btn" *ngIf="row.customerId != null">
                <fa-icon [icon]="faIconName.faFloppyDisk"></fa-icon>
            </button>
        </td>
    </ng-container>
</pcg-table>

<ng-template #createCustomer let-modal>
    <form [formGroup]="customerFormGroup" pcgSubmitUrl="api/Customers/Customers/SaveCustomer" (messageReceive)="saveCustomer($event); modal.dismiss();">
        <div class="modal-header">
			<h5 class="modal-title" id="modal-basic-title">Create Master Customer</h5>
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
        <div class="modal-body">
            <pcg-control-group label="Name">
                <input pcgControlRef class="form-control" type="text" formControlName="name" />
            </pcg-control-group>
            
            <pcg-control-group label="Shipping Street Address">
                <input pcgControlRef class="form-control" type="text" formControlName="shippingStreetAddress" />
            </pcg-control-group>
            
            <pcg-control-group label="Shipping City">
                <input pcgControlRef class="form-control" type="text" formControlName="shippingCity" />
            </pcg-control-group>
            
            <pcg-control-group label="Shipping State">
                <input pcgControlRef class="form-control" type="text" formControlName="shippingState" />
            </pcg-control-group>
    
            <pcg-control-group label="Shipping Zip Code">
                <input pcgControlRef class="form-control" type="text" formControlName="shippingZipCode" />
            </pcg-control-group>
        </div>
        <pcg-fixed-buttons>
            <a class="btn btn-cancel btn-lg" (click)="modal.dismiss();">Cancel</a>
            <button type="submit" class="btn btn-save btn-lg">Save</button>
            <button type="button" (click)="autofillFromTransaction()" class="btn btn-save btn-lg">Autofill from Transaction</button>
        </pcg-fixed-buttons>
    </form>
</ng-template>