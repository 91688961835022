import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list'; 
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { TopClickNavComponent } from './top-click-nav/top-click-nav.component';
import { TopSecondaryNavComponent } from './top-secondary-nav/top-secondary-nav.component';
import { SearchNavComponent } from './search-nav/search-nav.component';
import { MatSideNavComponent } from './mat-side-nav/mat-side-nav.component';

@NgModule({
	declarations: [
		TopClickNavComponent
		, TopSecondaryNavComponent
		, SearchNavComponent
		, MatSideNavComponent
	],
	imports: [
		RouterModule
		, CommonModule
		, FormsModule
		, MatTabsModule
		, MatListModule
		, FontAwesomeModule
	],
	exports: [ 
		TopClickNavComponent
		, TopSecondaryNavComponent
		, SearchNavComponent
		, MatTabsModule 
		, MatListModule
		, FontAwesomeModule
		, MatSideNavComponent
	]
})
export class NavigationModule { }
