import { Component, Input } from '@angular/core';
import { CustomerSalesRepVm } from '../../../shared/generated/Customers/Models/CustomerSalesRepVm';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemMessage } from '../../../core/system-message/system-message-service';
import { SharedModule } from '../../../shared/shared.module'

@Component({
  selector: 'pcg-add-customer-modal',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './add-customer-modal.component.html',
  styleUrl: './add-customer-modal.component.scss'
})
export class AddCustomerModalComponent {
  @Input() salesRepId;
  
  formGroup = CustomerSalesRepVm.Form;
  model = CustomerSalesRepVm.GetModel(this.formGroup);

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.formGroup.get('salesRepId')?.setValue(this.salesRepId);
    this.formGroup.get('customerId')?.setValue(null);
  }

  saveCustomer(message: SystemMessage) {
    if(message.isSuccessful){
      this.activeModal.close(true);
    }
  }
}
