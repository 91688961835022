// tslint:disable: quotemark
export enum ActivationEnum {
	User = 0
}

export namespace ActivationEnum {
	export function toDescription(val: ActivationEnum) {
		switch (val) {
			case 0:
				return "User";
		}
		return null;
	}
}
