/* eslint-disable @typescript-eslint/dot-notation */
import { Directive, ElementRef } from '@angular/core';
import { NgControl, AbstractControl, FormGroupDirective, ControlContainer } from '@angular/forms';

// This directive was originally copied from here:
// https://www.codeproject.com/Articles/1239543/Angular-Custom-Validation-Component
@Directive({
	selector: '[pcgControlRef]'
})
export class ControlRefDirective {
	
	constructor(private formControl: NgControl, private elRef: ElementRef, private fg: ControlContainer) {
		// Set the id based on the form control name, if none given
		if (!elRef.nativeElement.id && elRef.nativeElement.attributes['formcontrolname']) {
			elRef.nativeElement.id = elRef.nativeElement.attributes['formcontrolname'].value;
		}
	}

	get hasError() { return this.formControl.dirty && this.formControl.touched && this.formControl.invalid; }

	get isRequired() {
		const validator = this.formControl.control.validator ? this.formControl.control.validator({} as AbstractControl) : '';
		return validator && validator.required;
	}

	get errors() {
		if (this.hasError && this.formControl.errors) { return this.formControl.errors; }
		return '';
	}

	get id() { return this.elRef.nativeElement.id; }

	get form() { return this.fg.formDirective ? (this.fg.formDirective as FormGroupDirective).form : null; }
}
