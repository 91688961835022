export interface IGlobalAuditHistoryTbl {
	dateChanged: Date;
	dateChangedFormatted?: string;
	module?: string;
	entityId?: number;
	recordAffected?: string;
	secureArea?: string;
	field?: string;
	changedFrom?: string;
	changedTo?: string;
	changedBy?: string;
};

export class GlobalAuditHistoryTbl implements IGlobalAuditHistoryTbl {
	dateChanged = new Date();
	dateChangedFormatted = '';
	module = '';
	entityId = 0;
	recordAffected = '';
	secureArea = '';
	field = '';
	changedFrom = '';
	changedTo = '';
	changedBy = '';
};
