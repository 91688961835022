export interface IEditSalesRepTbl {
	salesRepId: number;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	team?: string;
	territory?: number[];
	region?: number[];
	commission?: number;
	discount?: number;
	isManager: boolean;
	payrollId?: number;
};

export class EditSalesRepTbl implements IEditSalesRepTbl {
	salesRepId = 0;
	fullName = '';
	email = '';
	phoneNumber = '';
	team = '';
	territory = [];
	region = [];
	commission = 0;
	discount = 0;
	isManager = false;
	payrollId = 0;
};
