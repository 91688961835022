<div *ngIf='showPwaNotification' class="header-update-warning col-md-12">
	<div class="row">
		<div class="col-md-11">
			<span>{{PwaNotificationText}}</span>&nbsp;
			<button (click)='navToPwaInfo()' class="link-style">Click here to learn more!</button>&nbsp;
		</div>		
		<div class="col-md-1">
			<button (click)='dismissPwa()' class="link-style">Dismiss</button>
		</div>
	</div>	
</div>

<div *ngIf='showNotification' class="header-update-warning">
	<span>Innova has been updated with new features.</span>&nbsp;
	<a href='#' (click)='hardReload()' class="header-update-button">Click here to reload the page.</a>
</div>
