import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { timer, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { SecurityService } from '../security/security.service';

@Component({
	selector: 'pcg-inactivity-timeout',
	templateUrl: './inactivity-timeout.component.html',
	styleUrls: ['./inactivity-timeout.component.scss']
})
export class InactivityTimeoutComponent implements OnInit, OnDestroy {

	subscribeTimer: number;
	subsriptions = new Subscription();

	constructor(
		private http: HttpClient
		, public modal: NgbActiveModal
		, private sec: SecurityService
		, private router: Router
	) { }

	get timeLeft() {
		const now: any = new Date();
		if(localStorage.getItem('lastAccess')) {
			const lastAccess: any = new Date(JSON.parse(localStorage.getItem('lastAccess')));
			const theTimeLeft = 300 - (now - lastAccess) / 1000;
			return parseInt(theTimeLeft.toString(), 10);
		} else { return 0; }
	}

	ngOnInit() {
		this.subscribeTimer = this.timeLeft;
		const source = timer(0, 1000);
		this.subsriptions.add(source.subscribe(() => {
			this.subscribeTimer = this.timeLeft;
			if (this.subscribeTimer <= 0) { // Times up
				// Nullify token, security, and user info
				this.sec.setSecurity(null, null, null);

				// Close this modal
				this.modal.dismiss();

				// Redirect to login
				const redirectUrl = '/' + window.location.href.replace(document.getElementsByTagName('base')[0].href, '');
				this.router.navigateByUrl(`/login?redirectUrl=${encodeURIComponent(redirectUrl)}`);
			} else if (this.subscribeTimer > 30) { // Session was probably extended in another window
				this.modal.dismiss();
			}
		}));
	}

	ngOnDestroy() {
		this.subsriptions.unsubscribe();
		const user = this.sec.getUser();
		if (user && this.timeLeft <= 30) { // Need to do a server hit to reset timeout server-side
			this.http.get(`/api/Account/GetUserInfo/${user.id}`).subscribe();
		}
	}
}
