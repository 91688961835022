import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class SalesTerritoryListVm {
	territoryId: number;
	name?: string;
	region?: string;
	canDelete: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'territoryId': new UntypedFormControl(0, [...(validators['territoryId'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[]]),
			'region': new UntypedFormControl(null, [...(validators['region'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(false, [...(validators['canDelete'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['name', {"orderId":0,"sortColumn":"name","searchColumn":"name","includeInExcel":true,"exportColumn":null,"displayName":"Name","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['region', {"orderId":1,"sortColumn":"region","searchColumn":"region","includeInExcel":true,"exportColumn":null,"displayName":"Regions","isVisible":true,"canSearch":false,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['canDelete', {"orderId":2,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":false,"exportColumn":null,"displayName":"Delete","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): SalesTerritoryListVmFromFg {
		return new SalesTerritoryListVmFromFg(fg);
	}
}

export class SalesTerritoryListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get territoryId(): number { return this.fg.get('territoryId').value; }
	set territoryId(val) { this.fg.get('territoryId').setValue(val); }

	get name(): string { return this.fg.get('name').value; }
	set name(val) { this.fg.get('name').setValue(val); }

	get region(): string { return this.fg.get('region').value; }
	set region(val) { this.fg.get('region').setValue(val); }

	get canDelete(): boolean { return this.fg.get('canDelete').value; }
	set canDelete(val) { this.fg.get('canDelete').setValue(val); }

}
