import { LoadedStores } from '../tables/loaded.store';
import { AuditHistoryTbl } from '../tables/Models/AuditHistoryTbl';
import { ReleaseNotesTbl } from '../tables/Models/ReleaseNotesTbl';
import { ImportHistoryListTbl } from '../tables/Transactions/Models/ImportHistoryListTbl';
import { TransactionEditTbl } from '../tables/Transactions/Models/TransactionEditTbl';
import { TransactionListFiltersTbl } from '../tables/Transactions/Models/TransactionListFiltersTbl';
import { TransactionListTbl } from '../tables/Transactions/Models/TransactionListTbl';
import { CustomersFromImportTbl } from '../tables/Transactions/Models/Upload/CustomersFromImportTbl';
import { OssurUploadTbl } from '../tables/Transactions/Models/Upload/OssurUploadTbl';
import { SalesRegionFromImportTbl } from '../tables/Transactions/Models/Upload/SalesRegionFromImportTbl';
import { UploadFileTbl } from '../tables/Transactions/Models/Upload/UploadFileTbl';
import { CustomerFileUploadTbl } from '../tables/Transactions/Models/Upload/CustomerFileUploadTbl';
import { ExceptionListFiltersTbl } from '../tables/Transactions/Models/Exceptions/ExceptionListFiltersTbl';
import { ExceptionListTbl } from '../tables/Transactions/Models/Exceptions/ExceptionListTbl';
import { SilkierHistoryTbl } from '../tables/Silkier/Models/SilkierHistoryTbl';
import { SilkierLogMessageTbl } from '../tables/Silkier/Models/SilkierLogMessageTbl';
import { SilkierExceptionMessageTbl } from '../tables/Silkier/Models/SilkierExceptionMessageTbl';
import { EditSalesRepTbl } from '../tables/Sales/Models/EditSalesRepTbl';
import { EditSalesTerritoryTbl } from '../tables/Sales/Models/EditSalesTerritoryTbl';
import { SalesRepListTbl } from '../tables/Sales/Models/SalesRepListTbl';
import { SalesTerritoryListTbl } from '../tables/Sales/Models/SalesTerritoryListTbl';
import { HelpFilesTbl } from '../tables/HelpFiles/Models/HelpFilesTbl';
import { CustomerAliasListTbl } from '../tables/Customers/Models/CustomerAliasListTbl';
import { CustomerEditTbl } from '../tables/Customers/Models/CustomerEditTbl';
import { CustomerImportExceptionTbl } from '../tables/Customers/Models/CustomerImportExceptionTbl';
import { CustomerListApprovalTbl } from '../tables/Customers/Models/CustomerListApprovalTbl';
import { CustomerListTbl } from '../tables/Customers/Models/CustomerListTbl';
import { CustomerSalesRepTbl } from '../tables/Customers/Models/CustomerSalesRepTbl';
import { GlobalAuditHistoryTbl } from '../tables/Administration/Models/GlobalAuditHistoryTbl';
import { LoginHistoryTbl } from '../tables/Administration/Models/LoginHistoryTbl';
import { ReleaseNoteTbl } from '../tables/Administration/Models/ReleaseNoteTbl';
import { EditUserTbl } from '../tables/Administration/Models/User/EditUser/EditUserTbl';
import { DowntimeNotificationTbl } from '../tables/Administration/Models/DowntimeNotification/DowntimeNotificationTbl';

const AuditHistoryTblInstance = new AuditHistoryTbl();
const ReleaseNotesTblInstance = new ReleaseNotesTbl();
const ImportHistoryListTblInstance = new ImportHistoryListTbl();
const TransactionEditTblInstance = new TransactionEditTbl();
const TransactionListFiltersTblInstance = new TransactionListFiltersTbl();
const TransactionListTblInstance = new TransactionListTbl();
const CustomersFromImportTblInstance = new CustomersFromImportTbl();
const OssurUploadTblInstance = new OssurUploadTbl();
const SalesRegionFromImportTblInstance = new SalesRegionFromImportTbl();
const UploadFileTblInstance = new UploadFileTbl();
const CustomerFileUploadTblInstance = new CustomerFileUploadTbl();
const ExceptionListFiltersTblInstance = new ExceptionListFiltersTbl();
const ExceptionListTblInstance = new ExceptionListTbl();
const SilkierHistoryTblInstance = new SilkierHistoryTbl();
const SilkierLogMessageTblInstance = new SilkierLogMessageTbl();
const SilkierExceptionMessageTblInstance = new SilkierExceptionMessageTbl();
const EditSalesRepTblInstance = new EditSalesRepTbl();
const EditSalesTerritoryTblInstance = new EditSalesTerritoryTbl();
const SalesRepListTblInstance = new SalesRepListTbl();
const SalesTerritoryListTblInstance = new SalesTerritoryListTbl();
const HelpFilesTblInstance = new HelpFilesTbl();
const CustomerAliasListTblInstance = new CustomerAliasListTbl();
const CustomerEditTblInstance = new CustomerEditTbl();
const CustomerImportExceptionTblInstance = new CustomerImportExceptionTbl();
const CustomerListApprovalTblInstance = new CustomerListApprovalTbl();
const CustomerListTblInstance = new CustomerListTbl();
const CustomerSalesRepTblInstance = new CustomerSalesRepTbl();
const GlobalAuditHistoryTblInstance = new GlobalAuditHistoryTbl();
const LoginHistoryTblInstance = new LoginHistoryTbl();
const ReleaseNoteTblInstance = new ReleaseNoteTbl();
const EditUserTblInstance = new EditUserTbl();
const DowntimeNotificationTblInstance = new DowntimeNotificationTbl();
const loadedStoresInstance = new LoadedStores();

function generateColumns<T extends Record<string, any>>(instance: T): string {
	return (Object.keys(instance) as (keyof T)[]).join(',');
}

export const DBStores = {
	AuditHistoryTbl: {
		TableName: 'AuditHistoryTbl',
		Columns: generateColumns(AuditHistoryTblInstance)
	},
	ReleaseNotesTbl: {
		TableName: 'ReleaseNotesTbl',
		Columns: generateColumns(ReleaseNotesTblInstance)
	},
	ImportHistoryListTbl: {
		TableName: 'ImportHistoryListTbl',
		Columns: generateColumns(ImportHistoryListTblInstance)
	},
	TransactionEditTbl: {
		TableName: 'TransactionEditTbl',
		Columns: generateColumns(TransactionEditTblInstance)
	},
	TransactionListFiltersTbl: {
		TableName: 'TransactionListFiltersTbl',
		Columns: generateColumns(TransactionListFiltersTblInstance)
	},
	TransactionListTbl: {
		TableName: 'TransactionListTbl',
		Columns: generateColumns(TransactionListTblInstance)
	},
	CustomersFromImportTbl: {
		TableName: 'CustomersFromImportTbl',
		Columns: generateColumns(CustomersFromImportTblInstance)
	},
	OssurUploadTbl: {
		TableName: 'OssurUploadTbl',
		Columns: generateColumns(OssurUploadTblInstance)
	},
	SalesRegionFromImportTbl: {
		TableName: 'SalesRegionFromImportTbl',
		Columns: generateColumns(SalesRegionFromImportTblInstance)
	},
	UploadFileTbl: {
		TableName: 'UploadFileTbl',
		Columns: generateColumns(UploadFileTblInstance)
	},
	CustomerFileUploadTbl: {
		TableName: 'CustomerFileUploadTbl',
		Columns: generateColumns(CustomerFileUploadTblInstance)
	},
	ExceptionListFiltersTbl: {
		TableName: 'ExceptionListFiltersTbl',
		Columns: generateColumns(ExceptionListFiltersTblInstance)
	},
	ExceptionListTbl: {
		TableName: 'ExceptionListTbl',
		Columns: generateColumns(ExceptionListTblInstance)
	},
	SilkierHistoryTbl: {
		TableName: 'SilkierHistoryTbl',
		Columns: generateColumns(SilkierHistoryTblInstance)
	},
	SilkierLogMessageTbl: {
		TableName: 'SilkierLogMessageTbl',
		Columns: generateColumns(SilkierLogMessageTblInstance)
	},
	SilkierExceptionMessageTbl: {
		TableName: 'SilkierExceptionMessageTbl',
		Columns: generateColumns(SilkierExceptionMessageTblInstance)
	},
	EditSalesRepTbl: {
		TableName: 'EditSalesRepTbl',
		Columns: generateColumns(EditSalesRepTblInstance)
	},
	EditSalesTerritoryTbl: {
		TableName: 'EditSalesTerritoryTbl',
		Columns: generateColumns(EditSalesTerritoryTblInstance)
	},
	SalesRepListTbl: {
		TableName: 'SalesRepListTbl',
		Columns: generateColumns(SalesRepListTblInstance)
	},
	SalesTerritoryListTbl: {
		TableName: 'SalesTerritoryListTbl',
		Columns: generateColumns(SalesTerritoryListTblInstance)
	},
	HelpFilesTbl: {
		TableName: 'HelpFilesTbl',
		Columns: generateColumns(HelpFilesTblInstance)
	},
	CustomerAliasListTbl: {
		TableName: 'CustomerAliasListTbl',
		Columns: generateColumns(CustomerAliasListTblInstance)
	},
	CustomerEditTbl: {
		TableName: 'CustomerEditTbl',
		Columns: generateColumns(CustomerEditTblInstance)
	},
	CustomerImportExceptionTbl: {
		TableName: 'CustomerImportExceptionTbl',
		Columns: generateColumns(CustomerImportExceptionTblInstance)
	},
	CustomerListApprovalTbl: {
		TableName: 'CustomerListApprovalTbl',
		Columns: generateColumns(CustomerListApprovalTblInstance)
	},
	CustomerListTbl: {
		TableName: 'CustomerListTbl',
		Columns: generateColumns(CustomerListTblInstance)
	},
	CustomerSalesRepTbl: {
		TableName: 'CustomerSalesRepTbl',
		Columns: generateColumns(CustomerSalesRepTblInstance)
	},
	GlobalAuditHistoryTbl: {
		TableName: 'GlobalAuditHistoryTbl',
		Columns: generateColumns(GlobalAuditHistoryTblInstance)
	},
	LoginHistoryTbl: {
		TableName: 'LoginHistoryTbl',
		Columns: generateColumns(LoginHistoryTblInstance)
	},
	ReleaseNoteTbl: {
		TableName: 'ReleaseNoteTbl',
		Columns: generateColumns(ReleaseNoteTblInstance)
	},
	EditUserTbl: {
		TableName: 'EditUserTbl',
		Columns: generateColumns(EditUserTblInstance)
	},
	DowntimeNotificationTbl: {
		TableName: 'DowntimeNotificationTbl',
		Columns: generateColumns(DowntimeNotificationTblInstance)
	},
	LoadedStores: {
		TableName: 'LoadedStores',
		Columns: generateColumns(loadedStoresInstance)
	}
};

