export interface ICustomerImportExceptionTbl {
	customerImportExceptionsId: number;
	customerId: number;
	masterCustomer?: string;
	masterName?: string;
	masterStreetAddress?: string;
	masterCity?: string;
	masterState?: string;
	masterZipCode?: string;
	importedCustomer?: string;
	importedName?: string;
	importedStreetAddress?: string;
	importedCity?: string;
	importedState?: string;
	importedZipCode?: string;
};

export class CustomerImportExceptionTbl implements ICustomerImportExceptionTbl {
	customerImportExceptionsId = 0;
	customerId = 0;
	masterCustomer = '';
	masterName = '';
	masterStreetAddress = '';
	masterCity = '';
	masterState = '';
	masterZipCode = '';
	importedCustomer = '';
	importedName = '';
	importedStreetAddress = '';
	importedCity = '';
	importedState = '';
	importedZipCode = '';
};
