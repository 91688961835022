import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

//Pipe to use in angular markup files to get a trusted url from a source.
//Used in help-videos.component.html w/ vimeo help files.
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    transform(url) { return this.sanitizer.bypassSecurityTrustResourceUrl(url); }
}
