export interface IImportHistoryListTbl {
	importHistoryId: number;
	fileName?: string;
	importDate: Date;
	fileSource?: string;
	importedBy?: string;
	recordsImported: number;
	exceptionCount: number;
	canDelete: boolean;
};

export class ImportHistoryListTbl implements IImportHistoryListTbl {
	importHistoryId = 0;
	fileName = '';
	importDate = new Date();
	fileSource = '';
	importedBy = '';
	recordsImported = 0;
	exceptionCount = 0;
	canDelete = false;
};
