import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class AuditHistoryVm {
	dateChanged: Date;
	dateChangedFormatted?: string;
	field?: string;
	changedFrom?: string;
	changedTo?: string;
	changedBy?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'dateChanged': new UntypedFormControl('', [...(validators['dateChanged'] ?? []), ...[Validators.required]]),
			'dateChangedFormatted': new UntypedFormControl(null, [...(validators['dateChangedFormatted'] ?? []), ...[]]),
			'field': new UntypedFormControl(null, [...(validators['field'] ?? []), ...[]]),
			'changedFrom': new UntypedFormControl(null, [...(validators['changedFrom'] ?? []), ...[]]),
			'changedTo': new UntypedFormControl(null, [...(validators['changedTo'] ?? []), ...[]]),
			'changedBy': new UntypedFormControl(null, [...(validators['changedBy'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['dateChangedFormatted', {"orderId":0,"sortColumn":"dateChanged","searchColumn":"dateChangedFormatted","includeInExcel":true,"exportColumn":null,"displayName":"Date Changed","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['field', {"orderId":1,"sortColumn":"field","searchColumn":"field","includeInExcel":true,"exportColumn":null,"displayName":"Field","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['changedFrom', {"orderId":2,"sortColumn":"changedFrom","searchColumn":"changedFrom","includeInExcel":true,"exportColumn":null,"displayName":"Changed From","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['changedTo', {"orderId":3,"sortColumn":"changedTo","searchColumn":"changedTo","includeInExcel":true,"exportColumn":null,"displayName":"Changed To","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['changedBy', {"orderId":4,"sortColumn":"changedBy","searchColumn":"changedBy","includeInExcel":true,"exportColumn":null,"displayName":"Changed By","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): AuditHistoryVmFromFg {
		return new AuditHistoryVmFromFg(fg);
	}
}

export class AuditHistoryVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get dateChanged(): Date { return this.fg.get('dateChanged').value; }
	set dateChanged(val) { this.fg.get('dateChanged').setValue(val); }

	get dateChangedFormatted(): string { return this.fg.get('dateChangedFormatted').value; }
	set dateChangedFormatted(val) { this.fg.get('dateChangedFormatted').setValue(val); }

	get field(): string { return this.fg.get('field').value; }
	set field(val) { this.fg.get('field').setValue(val); }

	get changedFrom(): string { return this.fg.get('changedFrom').value; }
	set changedFrom(val) { this.fg.get('changedFrom').setValue(val); }

	get changedTo(): string { return this.fg.get('changedTo').value; }
	set changedTo(val) { this.fg.get('changedTo').setValue(val); }

	get changedBy(): string { return this.fg.get('changedBy').value; }
	set changedBy(val) { this.fg.get('changedBy').setValue(val); }

}
