import { HttpClient } from '@angular/common/http';
import { ValidatorFn, UntypedFormGroup, ValidationErrors, UntypedFormControl, Validators, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

export class ChangePasswordForm {

	constructor(private http: HttpClient, incCurrentPassword = false) {
		this.formGroup = new UntypedFormGroup({});
		if (incCurrentPassword) {
			this.formGroup.addControl('currentPassword', new UntypedFormControl('', {
				validators: [ Validators.required ],
				asyncValidators: this.isCurrentPasswordValidator,
				updateOn: 'blur'
			}));
		}
		this.formGroup.addControl('password', new UntypedFormControl('', {
			validators: [
				Validators.required
				, Validators.minLength(10)
				, Validators.maxLength(250)
				, Validators.pattern("^((.?)\\2?(?!\\2))+$")
				, this.isSequentialChars
			],
			asyncValidators: this.commonPasswordValidator,
			updateOn: 'blur'
		}));
		this.formGroup.addControl('confirmPassword', new UntypedFormControl('', [Validators.required, this.passwordsEqualValidator]));
	}

	// Our form group
	// eslint-disable-next-line @typescript-eslint/member-ordering
	formGroup: UntypedFormGroup = null;

	// Validator to ensure new password === confirmation password
	passwordsEqualValidator: ValidatorFn =  (control: UntypedFormGroup): ValidationErrors | null => {
		if (this.formGroup && this.formGroup.value) {
			const password = this.formGroup.value.password;
			const confirmPassword = control.value;
			return (password === confirmPassword) ? null : { 'passwordsNotEqual': true };
		}
		return null;
	}
	// Validator to prevent common passwords from being used, based on a dictionary
	commonPasswordValidator: AsyncValidatorFn =  (control: UntypedFormGroup): Observable<ValidationErrors> => 
		this.http.get('api/Account/IsCommonPassword?password=' + encodeURIComponent(control.value))

	// Validator to check current password is correct
	isCurrentPasswordValidator: AsyncValidatorFn =  (control: UntypedFormGroup): Observable<ValidationErrors> => 
		this.http.get('api/Account/IsCurrentPassword?password=' + encodeURIComponent(control.value))

	isSequentialChars: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
		if (this.formGroup && this.formGroup.value && control.value != "" && control.value != null) {
			let containsSequentialNumber : boolean = false;
			const password : string = control.value;
			
			containsSequentialNumber = (password.includes('012') || password.includes('123') || password.includes('234') || password.includes('345') 
				|| password.includes('456') || password.includes('567') || password.includes('678') || password.includes('789') || password.includes('890') 
				|| password.includes('abc') || password.includes('bcd') || password.includes('cde') || password.includes('def') || password.includes('efg') 
				|| password.includes('fgh') || password.includes('ghi') || password.includes('hij') || password.includes('ijk') || password.includes('jkl') 
				|| password.includes('klm') || password.includes('lmn') || password.includes('mno') || password.includes('nop') || password.includes('opq') 
				|| password.includes('pqr') || password.includes('qrs') || password.includes('rst') || password.includes('stu') || password.includes('tuv') 
				|| password.includes('uvw') || password.includes('vwx') || password.includes('wxy') || password.includes('xyz'));

			return !containsSequentialNumber ? null : { 'sequentialChars': true };
		}
		return null;
	}
}
