import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';

import { SystemMessageService } from '../system-message/system-message-service';
import { Permission } from './generated/Permission';
import { SecurityService } from './security.service';

@Injectable()
export class AuthGuard  {

	constructor(
		private sec: SecurityService
		, private router: Router
		, private ms: SystemMessageService
	) { }

	/**
	 * Controls whether or not a user can access a component
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const security = !route.data ? undefined : (route.data['security'] as string | ((sec: Permission) => boolean));
		if (this.checkSecurity(state.url, security)) { return true; } 
		return this.router.createUrlTree(['/dashboard']);		
	}

	/**
	 * Controls whether or not a module will be lazy loaded
	 */
	canLoad(route: Route, urlSegment: UrlSegment[]) {
		const security = !route.data ? undefined : (route.data['security'] as string | ((sec: Permission) => boolean));
		const redirectUrl = '/' + window.location.href.replace(document.getElementsByTagName('base')[0].href, '');
		if (this.checkSecurity(redirectUrl, security)) { return true; }
		return this.router.createUrlTree(['/dashboard']);
	}

	/**
	 * Right now it just calls checkSecurity in the security service
	 * to ensure they are logged in and saves the redirect URL.
	 *
	 * We may want to eventually use this to lock people out
	 * of entire modules of the website in one place, based on
	 * a single security setting.
	 */
	checkSecurity(url: string, security: string | ((sec: Permission) => boolean)) {
		const isValid = this.sec.checkSecurity(security);

		if (!isValid)  {
			// If they are not logged on, save the URL they tried to go to
			if (!this.sec.isLoggedOn()) { this.router.navigateByUrl(`/login?redirectUrl=${encodeURIComponent(url)}`);}
			// If they are logged in but dont have access.
			let user = this.sec.getUser();
			if (user) { this.ms.setSystemMessage('Access denied.', 'error'); }
		}

		return isValid;
	}
}
