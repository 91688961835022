// tslint:disable: quotemark
export enum PermissionProfile {
	User = 0,
	Manager = 1,
	Admin = 2
}

export namespace PermissionProfile {
	export function toDescription(val: PermissionProfile) {
		switch (val) {
			case 0:
				return "User";
			case 1:
				return "Manager";
			case 2:
				return "Admin";
		}
		return null;
	}
}
