import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { LoginVm } from 'app/shared/generated/Models/LoginVm';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { CachePrimerService } from 'app/services/service-workers/cache-primer.service';

@Component({
	selector: 'pcg-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	providers: [
		{
		  provide: NG_VALUE_ACCESSOR,
		  multi:true,
		  useExisting: LoginComponent
		}
	]
})
export class LoginComponent implements OnDestroy, OnInit, AfterViewInit {

	@ViewChild('myForm', { static: true }) myForm: ElementRef;

	loginForm = LoginVm.Form;
	subscriptions: Subscription = new Subscription();
	sm: SystemMessage;

	isLogin = false;
	isError = false;
	errorString: string;

	constructor(
		private route: ActivatedRoute
		, private ms: SystemMessageService
		, private sec: SecurityService
		, private router: Router
		, title: Title
		, private cachePrimerService: CachePrimerService
	) { title.setTitle('Login'); }

	async ngOnInit(): Promise<void> {		
		const user = this.sec.getUser();
		if (user) { this.loginRedirect(); }		
	}

	ngAfterViewInit() { }

	async onSubmit(): Promise<void> {
		if (this.loginForm.valid) {
			this.sec.setLocalStorage('lastAccess', new Date());
			this.subscriptions.add(this.ms.getHttpObservable(this, 'api/Account/Login', this.loginForm)
				.subscribe(async msg => { 
					this.sm = msg;
					const val = msg.value;
					if (msg.isSuccessful && val) {						
						 this.doLogin();
					}
				})
			);
		} else { validateForm(this.loginForm); }
	}

	continueClick() { this.doLogin(); }

	doLogin() {
		const val = this.sm.value;
		if (!val) { return; }
		this.loginForm.reset();
		this.sec.setSecurity(val.token, val.user, val.security);
		this.loginRedirect();
	}	

	loginRedirect() {
		this.cachePrimerService.primeCache();
		let redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl') || sessionStorage.getItem('redirectUrl');
		if (redirectUrl) {
			const baseUrl = document.getElementsByTagName('base')[0].href;
			redirectUrl = redirectUrl.replace(/^\//, '');
			if (redirectUrl.startsWith('uploads/') || redirectUrl.startsWith('quartz')) {
				window.location.href = baseUrl + redirectUrl;
			} else {
				this.router.navigateByUrl(redirectUrl);
				sessionStorage.removeItem('redirectUrl');
			}
		} else { this.router.navigate(['/workflow/1']); }
	}

	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
