import { Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { ChangePasswordForm } from 'app/core/pages/change-password/change-password.form';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';

@Component({
	selector: 'pcg-user-change-password-modal',
	templateUrl: './user-change-password-modal.component.html',
	styleUrls: ['./user-change-password-modal.component.scss']
})
export class UserChangePasswordModalComponent implements OnDestroy {

	changePasswordForm: UntypedFormGroup = null;
	subscriptions: Subscription = new Subscription();

	constructor(
		public activeModal: NgbActiveModal
		, private ms: SystemMessageService
		, http: HttpClient
	) {
		const changePasswordForm = new ChangePasswordForm(http, true);
		this.changePasswordForm = changePasswordForm.formGroup;
	}

	changePassword() {
		if (this.changePasswordForm.valid) {
			this.subscriptions.add(this.ms.getHttpObservable(this, 'api/Account/ChangePassword', this.changePasswordForm)
				.subscribe((msg: SystemMessage) => { if (msg.isSuccessful) { this.activeModal.dismiss(); } }));
		} else {
			validateForm(this.changePasswordForm);
		}
	}

	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
