export interface IHelpFilesTbl {
	helpFileId: number;
	name?: string;
	description?: string;
	module?: any;
	moduleName?: string;
	fileName?: string;
	uploadPath?: string;
	file?: any;
	sourceFileName?: string;
	sourceFile?: any;
	videoId?: string;
	delete?: string;
	friendlyId?: string;
};

export class HelpFilesTbl implements IHelpFilesTbl {
	helpFileId = 0;
	name = '';
	description = '';
	module = '';
	moduleName = '';
	fileName = '';
	uploadPath = '';
	file = '';
	sourceFileName = '';
	sourceFile = '';
	videoId = '';
	delete = '';
	friendlyId = '';
};
