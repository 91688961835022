import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { EditUserComponent } from './edit-user.component';
import { UserLoginHistoryComponent } from './user-login-history/user-login-history.component';
import { UserChangePasswordModalComponent } from './user-change-password-modal/user-change-password-modal.component';
import { UserPermissionsModalComponent } from './user-permissions-modal/user-permissions-modal.component';
import { UserSecurityComponent } from './user-security/user-security.component';

@NgModule({
	declarations: [
		EditUserComponent
		, UserChangePasswordModalComponent
		, UserLoginHistoryComponent
		, UserPermissionsModalComponent
		, UserSecurityComponent
	],
	imports: [
		RouterModule
		, CommonModule
		, SharedModule
	],
	exports: [
		EditUserComponent
		, UserLoginHistoryComponent
	]
})
export class EditUserModule {}
