<form [formGroup]="formGroup" pcgSubmitUrl="api/Customers/Customers/AddSalesRep" (messageReceive)="saveSalesRep($event)">
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Add Sales Rep to Master Customer</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <pcg-control-group class="col-md-12" label="Sales Rep">
                <pcg-select 
                    placeholder="Select a Sales Rep" 
                    [sendFormData]="false" 
                    pcgControlRef
                    dataSource="api/Select/SalesRepresentatives" 
                    formControlName="salesRepId" 
                ></pcg-select>
            </pcg-control-group>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-cancel btn-lg" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-save btn-lg">Add Sales Rep</button>
    </div>
</form>