<pcg-table-filter id="{{filterId}}" (change)="table.ajaxReload()">
	<div class="filters-ctnr">
		<div class="filter-block">
			<label for="batchSelect" class="for">Batch Number</label>
			<pcg-select 
				labelForId="batchSelect" 
				[(ngModel)]="filters.importHistoryId" 
				dataSource="api/Select/ImportHistories" 
				placeholder="Select a Batch Number"
			></pcg-select>
		</div>
		<div class="filter-block">
			<label for="importDate" class="for">Date Imported</label>
			<pcg-date-range 
				labelForId="importDate" 
				[(dateRange)]="filters.importDate"
			></pcg-date-range>
		</div>
	</div>
</pcg-table-filter>

<div id="addContent" class="hide-on-print">
	<div id="actionElements">
		<a (click)="openModal()" class="btn btn-save btn-sm inline-add-btn mt-2">Import Transactions</a>
	</div>
</div>

<pcg-table 
	#table 
	[dataSource]="dataSource"
	[ajaxData]="filters" 
	[pcgSort]="[['importedDateString', 'asc']]"
	[columnDefs]="columns" 
	[canExportTableToExcel]="true"
	filterMap="filterMap"
	filterId="{{filterId}}"
	[isRowClickable]="true"
	(clickRow)="redirectTransaction($event)"
	[redirectFunction]="redirectTransaction"
	isUsingMobileCard="true"
	uniqueColumn="transactionNumber"
	deleteUrl="api/Transactions/Transactions/DeleteTransaction"
	identificationColumn="transactionId"
	confirmMessage="Are you sure you want to delete the transaction?"
>
	
	<ng-container matColumnDef="transactionId">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('transactionId').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('transactionId').displayName">{{ row.transactionId }}</td>
	</ng-container>

	<ng-container matColumnDef="importHistoryId">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('importHistoryId').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('importHistoryId').displayName">{{ row.importHistoryId }}</td>
	</ng-container>

	<ng-container matColumnDef="fileSource">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('fileSource').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('fileSource').displayName">{{ row.fileSource }}</td>
	</ng-container>

    <ng-container matColumnDef="importedDateString">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('importedDateString').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('importedDateString').displayName">{{ row.importedDateString }}</td>
	</ng-container>

    <ng-container matColumnDef="salesRegion">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('salesRegion').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('salesRegion').displayName">{{ row.salesRegion }}</td>
	</ng-container>

    <ng-container matColumnDef="distributorName">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('distributorName').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('distributorName').displayName">{{ row.distributorName }}</td>
	</ng-container>
    
    <ng-container matColumnDef="transactionSource">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('transactionSource').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('transactionSource').displayName">{{ row.transactionSource }}</td>
	</ng-container>
    
    <ng-container matColumnDef="billToCustomer">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('billToCustomer').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('billToCustomer').displayName">{{ row.billToCustomer }}</td>
	</ng-container>
    
    <ng-container matColumnDef="shipToCustomer">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('shipToCustomer').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('shipToCustomer').displayName">{{ row.shipToCustomer }}</td>
	</ng-container>

    <ng-container matColumnDef="transactionDateString">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('transactionDateString').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('transactionDateString').displayName">{{ row.transactionDateString }}</td>
	</ng-container>

    <ng-container matColumnDef="transactionNumber">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('transactionNumber').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('transactionNumber').displayName">{{ row.transactionNumber }}</td>
	</ng-container>

    <ng-container matColumnDef="productGroup">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('productGroup').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('productGroup').displayName">{{ row.productGroup }}</td>
	</ng-container>
    
    <ng-container matColumnDef="partsNumber">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('partsNumber').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('partsNumber').displayName">{{ row.partsNumber }}</td>
	</ng-container>

    <ng-container matColumnDef="salesAmount">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('salesAmount').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('salesAmount').displayName">{{ row.salesAmount | currency }}</td>
	</ng-container>
    
    <ng-container matColumnDef="quantity">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('quantity').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('quantity').displayName">{{ row.quantity }}</td>
	</ng-container>
    
    <ng-container matColumnDef="unitSalesPrice">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('unitSalesPrice').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('unitSalesPrice').displayName">{{ row.unitSalesPrice | currency  }}</td>
	</ng-container>

    <ng-container matColumnDef="listPrice">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('listPrice').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('listPrice').displayName">{{ row.listPrice | currency  }}</td>
	</ng-container>

    <ng-container matColumnDef="percentDiscount">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('percentDiscount').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('percentDiscount').displayName">{{ row.percentDiscount | percent: '1.0-2' }}</td>
	</ng-container>

    <ng-container matColumnDef="commissionAmount">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('commissionAmount').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('commissionAmount').displayName">{{ row.commissionAmount | currency  }}</td>
	</ng-container>

    <ng-container matColumnDef="commissionBracket">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('commissionBracket').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('commissionBracket').displayName">{{ row.commissionBracket }}</td>
	</ng-container>

    <ng-container matColumnDef="commissionSummary">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('commissionSummary').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('commissionSummary').displayName">{{ row.commissionSummary }}</td>
	</ng-container>

    <ng-container matColumnDef="compSegment">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('compSegment').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('compSegment').displayName">{{ row.compSegment }}</td>
	</ng-container>

    <ng-container matColumnDef="customerTier">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('customerTier').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('customerTier').displayName">{{ row.customerTier }}</td>
	</ng-container>

    <ng-container matColumnDef="gpoAffiliation">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('gpoAffiliation').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('gpoAffiliation').displayName">{{ row.gpoAffiliation }}</td>
	</ng-container>

    <ng-container matColumnDef="gpoStatus">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('gpoStatus').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('gpoStatus').displayName">{{ row.gpoStatus }}</td>
	</ng-container>
    
	<ng-container matColumnDef="canDelete">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('canDelete').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('canDelete').displayName">
			<pcg-delete-button 
				[table]='table' 
				confirmMessage="Are you sure you want to delete the transaction?" 
				url='api/Transactions/Transactions/DeleteTransaction' 
				[id]='row.transactionId'
			></pcg-delete-button>
		</td>
	</ng-container>
</pcg-table>

<ng-template #importTransactions let-modal>
	<form 
		[formGroup]="formGroup" 
		(submit)="isImporting=true"
		pcgSubmitUrl="api/Transactions/Transactions/UploadTransaction" 
		(messageSuccess)="messageSuccess($event); this.modal.dismiss(); "
	>
		<div class="modal-header">
			<h5 class="modal-title" id="modal-basic-title">Import Transactions</h5>
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>

		<div class="modal-body">
			<div class="row">
				<pcg-control-group class="col-md-12" label="Select a File Source"> 
					<!-- will need to add make dynamic once we acquire all example files --> 
					<a *ngIf="this.formGroup.get('fileSource').value == 1" href="../../../assets/ExampleImports/Ossur.xlsx">Example Import File</a>
					<pcg-select 
						placeholder="Select a File Source" 
						[sendFormData]="false" 
						pcgControlRef
						dataSource="api/Select/FileSources" 
						formControlName="fileSource" 
					></pcg-select>
				</pcg-control-group>
			</div>
			<div class="row">
				<pcg-control-group class="col-md-12" label="Import File">
					<pcg-file-upload 
						pcgControlRef 
						formControlName="file" 
						type="file"
					></pcg-file-upload>
				</pcg-control-group>
			</div>
		</div>

		<div class="modal-footer">
			<button type="button" class="btn btn-cancel btn-lg" (click)="modal.dismiss()">Cancel</button>
			<button type="submit" class="btn btn-save btn-lg">Import File</button>
		</div>
	</form>
</ng-template>