<!-- Filters modal -->
<pcg-table-filter id="userListFilters">
	<div class="filters-ctnr">
		<div class="filter-block">
			<label class="for" for="isActive">Is Active?</label>
			<mat-button-toggle-group [(ngModel)]="filters.isActive" [value]="filters.isActive" (change)="reloadTable()" >
				<mat-button-toggle [value]="true">Yes</mat-button-toggle>
				<mat-button-toggle [value]="false">No</mat-button-toggle>
				<mat-button-toggle [value]="null" [ngClass]="{'mat-button-toggle-checked': filters.isActive === null}">All</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
	</div>
</pcg-table-filter>

<div id="addContent" class="hide-on-print">
	<div id="actionElements">
		<a routerLink="/users/edit-user/0" class="btn btn-save btn-sm inline-add-btn mt-2">Add User</a>
		<button class='btn btn-save btn-sm inline-add-btn' (click)="operatePushNotification()">{{pushNotificationOperation}}</button>
	</div>
</div>

<ng-template #fullNameTooltip>
	<div class="text-left">
		Users with the following icon are locked out. <fa-icon [icon]="faIconName.faUserLock" style="color: red; cursor: none"></fa-icon>
	</div>
</ng-template>

<pcg-table 
	#table 
	[dataSource]="dataSource"
	[pcgSort]="[['fullName', 'asc']]"
	[columnDefs]="columns" 
	[ajaxData]="filters"
	[filterMap]="filterMap"
	filterId="userListFilters"
	[canResetFilters]="true" 
	[isRowClickable]="true"
	(clickRow)="redirectUser($event)"
	isUsingMobileCard="true"
	uniqueColumn="fullName"
	[redirectFunction]="redirectUser"
	(tableReceive)="updateWhileOffline()"
>
	
	<ng-container matColumnDef="fullName">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>
			<span 
				ngbTooltip="fullNameTooltip" 
				[ngbTooltip]="fullNameTooltip" 
				ng-click="fullNameTooltip" 
				placement="top" 
				class="toolTipHelp" 
				container="body"
			>{{ columns.get('fullName').displayName }}</span>
		</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('fullName').displayName">
			<span 
				ngbTooltip="fullNameTooltip" 
				[ngbTooltip]="fullNameTooltip" 
				triggers="pointerup" 
				placement="top-left" 
				class="toolTipResponsive"
			>
				<pcg-edit-link [url]="'/users/edit-user/' + row.userId">
					{{ row.fullName }}
					<fa-icon 
						*ngIf="row.isLockedOut === 'Yes'" 
						[icon]="faIconName.faUserLock" 
						class="unlockIcon"
					></fa-icon>
				</pcg-edit-link>
			</span>
		</td>
	</ng-container>

	<ng-container matColumnDef="email">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('email').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('email').displayName">{{ row.email }}</td>
	</ng-container>
	
	<ng-container matColumnDef="phoneNumber">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('phoneNumber').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('phoneNumber').displayName">{{ row.phoneNumber }}</td>
	</ng-container>
	
	<ng-container matColumnDef="isActiveStr">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('isActiveStr').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('isActiveStr').displayName">{{ row.isActive }}</td>
	</ng-container>

</pcg-table>
