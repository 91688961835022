<mat-stepper #stepper [linear]="exceptionCount != 0 || customerListApproved.get('customerListApproved').value != true" (selectionChange)="selectionChange($event)" style="margin-top: -75px;">
    <mat-step [stepControl]="customerListApproved">
        <ng-template matStepLabel>Master Customers</ng-template>
        <pcg-customer-list (customerListApproved)="onCustomererListApproval();"></pcg-customer-list>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Import Transactions</ng-template>
        <pcg-transaction-list (transactionsImported)="transactionsImported()"></pcg-transaction-list>
    </mat-step>
    <mat-step [stepControl]="exceptionsResolved" errorMessage="All exceptions must be resolved.">
        <ng-template matStepLabel>Exceptions (<span style="color: red;">{{exceptionCount | number}}</span>)</ng-template>
        <pcg-exceptions #exceptionsTable (exceptionResolved)="onExceptionResolved()"></pcg-exceptions>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Reports</ng-template>
    </mat-step>
</mat-stepper>
