import Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { pcgSettings }  from './app/shared/generated/pcg-settings';

const rollbarConfig = {
	accessToken: pcgSettings.rollbarPostClientToken,
	captureUncaught: true,
	captureUnhandledRejections: true,
	environment: pcgSettings.mode,
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
	constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

	handleError(err: any) {
		if (pcgSettings.mode === 'Development') {
			console.error(err.originalError || err);
		}
		this.rollbar.error(err.originalError || err);
	}
}

export function rollbarFactory() {
	return new Rollbar(rollbarConfig);
}
