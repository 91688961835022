export interface IEditUserTbl {
	userId: number;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	isActive: boolean;
	isActiveStr?: string;
	userName?: string;
	permissionId?: number;
	permission?: string;
	isLockedOut: boolean;
	isLockedOutStr?: string;
	inactivityTimeout: boolean;
	isUpdatedOffline: boolean;
	canBeDeleted: boolean;
	friendlyId?: string;
	isNewUser: boolean;
};

export class EditUserTbl implements IEditUserTbl {
	userId = 0;
	fullName = '';
	email = '';
	phoneNumber = '';
	isActive = false;
	isActiveStr = '';
	userName = '';
	permissionId = 0;
	permission = '';
	isLockedOut = false;
	isLockedOutStr = '';
	inactivityTimeout = false;
	isUpdatedOffline = false;
	canBeDeleted = false;
	friendlyId = '';
	isNewUser = false;
};
