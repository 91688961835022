import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { param } from '../http-params';
import { PcgTableInput, PcgTableInputColumn, PcgTableResult } from './table.component';

@Injectable({
    providedIn: 'root'
})
export class TableService {

    subscriptions: Subscription = new Subscription();
    result: PcgTableResult;

    constructor(private httpClient: HttpClient) { }

    getData(url: string, obj: PcgTableInput, userAjaxDataString: string, excelPassword: string, columns: PcgTableInputColumn[]) : Observable<PcgTableResult> {
        return this.httpClient.get<PcgTableResult>(
            `${url}?` +
            `${param(obj, true)}&${userAjaxDataString}&excelPassword=${encodeURIComponent(excelPassword)}` +
            `&columnJson=${encodeURIComponent(
                JSON.stringify(
                    columns.map(o => {
                        const newObj: any = {};
                        if (o.searchText !== null && o.searchText !== '') { newObj.searchText = o.searchText; }
                        if (o.sortColumnNum !== null) { newObj.sortColumnNum = o.sortColumnNum; }
                        if (o.sortDirection !== null) { newObj.sortDirection = o.sortDirection; }
                        return newObj;
                    })
                )
            )}`
        );
    }
}
