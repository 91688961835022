import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'pcg-ellipsis',
	templateUrl: './ellipsis.component.html',
	styleUrls: ['./ellipsis.component.scss']
})
export class EllipsisComponent implements OnInit {

	@Input() size = 50;
	@Input() data: any;
	@Input() expandOnClick = true;

	lessData: string;
	hasClicked = false;

	constructor() {}

	ngOnInit() {
		if (this.data) { this.lessData = this.data.substr(0, this.size); }
		this.hasClicked = !this.data || this.lessData.length === this.data.length;
	}

	onClick() { if (this.expandOnClick) { this.hasClicked = true; } }
}
