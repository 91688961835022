import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const getAdminNav: () => NavRoute = () => ({
	name: 'Admin',
	security: sec => sec.manageUsers,
	children: [
		{
			name: 'Audit History'
			, security: sec => sec.adminFeatures
			, exact: true
			, path: 'administration/global-audit-history'
		},
		{
			name: 'Release Notes'
			, security: sec => sec.adminFeatures
			, exact: true
			, path: 'administration/release-notes/release-notes-list'
		},
		{
			name: 'System Settings'
			, security: sec => sec.adminFeatures
			, exact: true
			,  path: 'administration/system-settings'
		},
		{ 
			name: 'Quartz'
			, exact: true
			, security: sec => sec.viewQuartzJobs
			, path: 'quartz'
			, openNewTab: true 
		}
	]
});
		
