import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { TransactionListVm } from '../../shared/generated/Transactions/Models/TransactionListVm'
import { TransactionListFiltersVm } from '../../shared/generated/Transactions/Models/TransactionListFiltersVm'
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadFileVm } from 'app/shared/generated/Transactions/Models/Upload/UploadFileVm';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';
import { DateRange } from 'app/shared/models/date-range';
import { TableComponent } from 'app/shared/table/table.component';
import { HttpClient } from '@angular/common/http';
import { SystemMessage } from 'app/core/system-message/system-message-service';


@Component({
  selector: 'pcg-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrl: './transaction-list.component.scss'
})
export class TransactionListComponent {
  @Output() transactionsImported = new EventEmitter();
  
  @ViewChild('importTransactions', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild(TableComponent, { static: true }) table: TableComponent<TransactionListVm>;
  formGroup = UploadFileVm.Form;
  columns = TransactionListVm.ColumnDefs;
	dataSource = "api/Transactions/Transactions/GetTransactionList/";

  filters: TransactionListFiltersVm =  {
    importDate: new DateRange(null, null),
    importHistoryId: null
  };
  
  initFilters = Object.assign({}, this.filters);
	filterMap = { 
    "importHistoryId": "Batch Number",
    "importDate": "Import Date"
  };
  filterId: string = 'transactionFilters';

  constructor(title: Title, public modalService: NgbModal, public route: ActivatedRoute, public nav: NavigationService
    , public btnFilterService: ButtonFilterService, public router: Router, private httpClient: HttpClient) {
    title.setTitle('Transaction List');
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
      let importId = paramMap.get('id');
      this.dataSource += (importId ?? '');
      if(importId != null) {
        this.filters.importHistoryId = +importId;
        this.initFilters.importHistoryId = +importId;
        this.filterId = '';
        this.filterMap = null;
        this.nav.setOpenSecondaryMenu(['Transactions', 'Transactions']);
      }
    });

    this.btnFilterService.resetFilterData.subscribe(() => { this.resetFilters() });
  }

  openModal() {
    this.formGroup.get('fileSource').setValue(null);
    this.modalService.open(this.modalContent, { });
  }

  resetFilters() {
		Object.assign(this.filters, this.initFilters);
		this.reloadTable();
	}

  reloadTable() { this.table.ajaxReload(); }

  redirectTransaction(row) {
    this.router.navigate(['transactions/transaction-edit', row.transactionId])
  }

  messageSuccess(message: SystemMessage) {
    this.transactionsImported.emit();
    this.table.ajaxReload(); 
  }
}
