import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { ExceptionListVm } from '../../shared/generated/Transactions/Models/Exceptions/ExceptionListVm'
import { ExceptionListFiltersVm } from '../../shared/generated/Transactions/Models/Exceptions/ExceptionListFiltersVm'
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';
import { DateRange } from 'app/shared/models/date-range';
import { TableComponent } from 'app/shared/table/table.component';
import { HttpClient } from '@angular/common/http';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import { SelectItem } from '../../shared/models/select-item';
import { SystemMessage, SystemMessageService } from '../../core/system-message/system-message-service';
import { MatDialog } from '@angular/material/dialog';
import { CustomerEditVm } from '../../shared/generated/Customers/Models/CustomerEditVm';

@Component({
  selector: 'pcg-exceptions',
  templateUrl: './exceptions.component.html',
  styleUrl: './exceptions.component.scss'
})
export class ExceptionsComponent {
  @Output() exceptionResolved: EventEmitter<void> = new EventEmitter();
  @ViewChild(TableComponent, { static: true }) table: TableComponent<ExceptionListVm>;
  @ViewChild('createCustomer', { static: true }) modalContent: TemplateRef<any>;

  columns = ExceptionListVm.ColumnDefs;
  dataSource: string = "api/Transactions/Transactions/GetExceptionList/"; 

  filters: ExceptionListFiltersVm =  {
    importDate: new DateRange(null, null),
    importHistoryId: null
  };
  
  initFilters = Object.assign({}, this.filters);
	filterMap = { 
    "importHistoryId": "Batch Number",
    "importDate": "Import Date"
  };
  filterId: string = 'exceptionFilters';

  customers: Array<SelectItem> = [];
  faIconName = { faFloppyDisk };

  customerFormGroup = CustomerEditVm.Form;
  customerModel = CustomerEditVm.GetModel(this.customerFormGroup);

  selectedRow: ExceptionListVm | undefined;
  constructor(title: Title, public modalService: NgbModal, public route: ActivatedRoute, public nav: NavigationService
    , public btnFilterService: ButtonFilterService, public router: Router, private httpClient: HttpClient, private systemMessageService: SystemMessageService
    , public dialog: MatDialog) {
    title.setTitle('Exceptions');
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
      let importId = paramMap.get('id');
      this.dataSource += (importId ?? '');
      if(importId != null) {
        this.filters.importHistoryId = +importId;
        this.initFilters.importHistoryId = +importId;
        this.filterId = '';
        this.filterMap = null;
        this.nav.setOpenSecondaryMenu(['Transactions', 'Exceptions']);
      }
    });

    this.btnFilterService.resetFilterData.subscribe(() => { this.resetFilters() });
    this.getCustomersList();
  }

  resetFilters() {
		Object.assign(this.filters, this.initFilters);
		this.reloadTable();
	}

  public reloadTable() { this.table.ajaxReload(); }

  saveRow(row: ExceptionListVm) {
    this.httpClient.post<SystemMessage>('api/Transactions/Transactions/FixException', JSON.stringify(row)).subscribe((sm: SystemMessage) => {
      if(sm.isSuccessful) {
        this.table.ajaxReload();
        this.exceptionResolved.emit()
      }

      this.systemMessageService.setSystemMessage(sm.message as string, sm.messageClass);
    })
  }

  setCustomerId(row: ExceptionListVm, event: any) {
    row.customerId = event.value;
  }

  openCustomerDetails = (customer) => {
    this.modalService.open(this.modalContent, { });
    this.customerFormGroup.get('name')?.setValue(customer);
  }

  saveCustomer(message: SystemMessage) {
    if(message.isSuccessful) {
      this.getCustomersList();
      this.selectedRow.customerId = message.model.customerId;
    }
  }

  getCustomersList() {
    this.httpClient.get<SelectItem[]>('api/Select/Customers').subscribe((items: SelectItem[]) => {
      this.customers = items; 
    });
  }

  autofillFromTransaction() {
    this.customerFormGroup.get('name')?.setValue(this.selectedRow?.name);
    this.customerFormGroup.get('shippingStreetAddress')?.setValue(this.selectedRow?.streetAddress);
    this.customerFormGroup.get('shippingCity')?.setValue(this.selectedRow?.city);
    this.customerFormGroup.get('shippingState')?.setValue(this.selectedRow?.state);
    this.customerFormGroup.get('shippingZipCode')?.setValue(this.selectedRow?.zipCode);
  }

  makeMasterCustomer(row: ExceptionListVm) {
    let customer: CustomerEditVm = {
      customerId: 0,
      name: row.name,
      shippingStreetAddress: row.streetAddress,
      shippingCity: row.city,
      shippingState: row.state,
      shippingZipCode: row.zipCode,
      billingIsShipping: false,
      hasAliases: false,
    };

    this.httpClient.post<SystemMessage>('api/Customers/Customers/SaveCustomer', customer).subscribe((message: SystemMessage) => {
      if(message.isSuccessful) {
        row.makeMaster = true;
        row.customerId = message.model.customerId;
        this.saveRow(row);
        this.getCustomersList();
      }
    })
  }
}
