import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { TableComponent } from 'app/shared/table/table.component';
import { SalesRepListVm } from 'app/shared/generated/Sales/Models/SalesRepListVm';
import { Title } from '@angular/platform-browser';
import { SharedModule } from 'app/shared/shared.module';
import { Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddSalesRepModalComponent } from '../../Customers/add-sales-rep-modal/add-sales-rep-modal.component';

@Component({
  selector: 'app-sales-representatives',
  standalone: true,
  imports: [SharedModule, RouterModule],
  templateUrl: './sales-representatives.component.html',
  styleUrls: ['./sales-representatives.component.css']
})
export class SalesRepresentativesComponent {
  @Input() customerId: number | null = null;
  @ViewChild(TableComponent, { static: false }) table: TableComponent<SalesRepListVm>;
  @ViewChild('addToCustomer', { static: true }) modalContent: TemplateRef<any>;

  columns = SalesRepListVm.ColumnDefs;
  dataSource = `api/Sales/SalesRepresentative/GetSalesRepList`;
  deleteUrl = `api/Sales/SalesRepresentative/DeleteSalesRep`

  constructor(public title: Title, public router: Router, public modalService: NgbModal) {
  }

  ngOnInit() {
    if(this.customerId != null){
      this.dataSource += `/${this.customerId}`;
      this.deleteUrl = `api/Customers/Customers/DeleteSalesRepFromCustomer`;
      return;
    }

    this.title.setTitle('Sales Representatives');
  }

  async reloadTable() {
    this.table.ajaxData;
    this.table.ajaxReload();
  }

  redirectSalesRep(row) {
    this.router.navigateByUrl(`sales-representatives/edit-sales-representative/${row.salesRepId}`);
  }

  openModal() {
    const modalRef = this.modalService.open(AddSalesRepModalComponent, { size: 'lg' });
    modalRef.componentInstance.customerId = this.customerId;
    modalRef.result.then((isSuccessful: boolean) => {
      if(isSuccessful) {
        this.table.ajaxReload();
      }
    });
  }
}
