<form 
    [formGroup]="formGroup" 
    pcgSubmitUrl="api/Transactions/Transactions/SaveTransaction" 
>
    <div class="row">
        <pcg-control-group class="col-md-4" label="Batch Number"> 
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="importHistoryId" 
            />
        </pcg-control-group> 
        <pcg-control-group class="col-md-4" label="File Source">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="fileSource" 
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Imported Date">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="importedDate" 
                [ngModel]="formGroup.controls.importedDate.value | date:'MM-dd-yyyy'" 
            />
        </pcg-control-group>
    </div>

    <div class="row">
        <pcg-control-group class="col-md-4" label="Sales Region">
            <pcg-select 
                placeholder="Select a Sales Region" 
                [sendFormData]="false" 
                pcgControlRef
                dataSource="api/Select/Regions" 
                formControlName="salesRegionId" 
                [disabled]="true"
            ></pcg-select>
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Distributor Name">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="distributorName" 
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Transaction Source">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="transactionSource" 
            />
        </pcg-control-group>
    </div>

    <div class="row">
        <pcg-control-group class="col-md-4" label="Bill To Customer">
            <pcg-select 
                placeholder="Select a Customer" 
                [sendFormData]="false" 
                pcgControlRef
                dataSource="api/Select/Customers" 
                formControlName="billToCustomerId" 
                [disabled]="true"
            ></pcg-select>
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Ship To Customer">
            <pcg-select 
                placeholder="Select a Customer" 
                [sendFormData]="false" 
                pcgControlRef
                dataSource="api/Select/Customers" 
                formControlName="shipToCustomerId" 
                [disabled]="true"
            ></pcg-select>
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Transaction Date">
            <input
                pcgControlRef 
                class="form-control" 
                type="date" 
                formControlName="transactionDate" 
                [ngModel]="formGroup.controls.transactionDate.value | date:'yyyy-MM-dd'" 
            />
        </pcg-control-group>
    </div>

    <div class="row">
        <pcg-control-group class="col-md-4" label="Transaction Number">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="transactionNumber" 
            />
        </pcg-control-group>
        
        <pcg-control-group class="col-md-4" label="Product Group">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="productGroup" 
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Parts Number">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="partsNumber" 
            />
        </pcg-control-group>
    </div>

    <div class="row">
        <pcg-control-group class="col-md-4" label="Sales Amount">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="salesAmount" 
                pcgNumberDecimal 
                pcgCurrency
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Quantity">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="quantity" 
                pcgNumberDecimal 
                pcgCurrency
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Unit Sales Price">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="unitSalesPrice" 
                pcgNumberDecimal 
                pcgCurrency
            />
        </pcg-control-group>
    </div>

    <div class="row">
        <pcg-control-group class="col-md-4" label="List Price">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="listPrice" 
                pcgNumberDecimal 
                pcgCurrency
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Percent Discount">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="percentDiscount" 
                pcgNumberDecimal 
                pcgPercentage
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Commission Amount">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="commissionAmount" 
                pcgNumberDecimal 
                pcgCurrency
            />
        </pcg-control-group>
    </div>

    <div class="row">
        <pcg-control-group class="col-md-4" label="Commission Bracket">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="commissionBracket" 
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Commission Summary">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="commissionSummary" 
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Comp Segment">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="compSegment" 
            />
        </pcg-control-group>
    </div>

    <div class="row">
        <pcg-control-group class="col-md-4" label="Customer Tier">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="customerTier" 
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Gpo Affiliation">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="gpoAffiliation" 
            />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Gpo Status">
            <input
                pcgControlRef 
                class="form-control" 
                type="text" 
                formControlName="gpoStatus" 
            />
        </pcg-control-group>
    </div>

    <pcg-fixed-buttons>
		<a class='btn btn-cancel btn-lg' routerLink='/workflow/2'>Back</a>
	</pcg-fixed-buttons>
</form>
