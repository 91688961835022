import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EditSalesRepVm } from '../../../shared/generated/Sales/Models/EditSalesRepVm'
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { firstValueFrom } from 'rxjs';
import { SharedModule } from 'app/shared/shared.module';
import { SystemMessage } from 'app/core/system-message/system-message-service';
import { CustomerModule } from '../../../Customers/customers-module'

@Component({
  selector: 'app-edit-sales-representative',
  templateUrl: './edit-sales-representative.component.html',
  imports: [SharedModule, CustomerModule],
  standalone: true,
  styleUrls: ['./edit-sales-representative.component.css']
})
export class EditSalesRepresentativeComponent {
  formGroup = EditSalesRepVm.Form;
  model = EditSalesRepVm.GetModel(this.formGroup);
  id: number;

  constructor(title: Title, public httpClient: HttpClient, public route: ActivatedRoute, public nav: NavigationService,
    public router: Router) {
    title.setTitle('Edit Sales Representative')
  }

  async ngOnInit(): Promise<void> {
    this.nav.setOpenSecondaryMenu(['Sales Representatives', 'Details'])
    this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
      this.id = Number(paramMap.get('id'));
      const model = await firstValueFrom(this.httpClient.get<EditSalesRepVm>(`api/Sales/SalesRepresentative/GetViewModel/${this.id}`));
      this.formGroup.patchValue(model);
      if (this.id === 0) {
        this.formGroup.get("region").setValue(null);
        this.formGroup.get("territory").setValue(null);
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
      }
    });
  }

  saveSalesRep(msg: SystemMessage) {
    if (!msg.isSuccessful) { return; }
    const model = this.formGroup.value;
    this.id = model.userId;
    if (msg.isSuccessful) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { this.router.navigate([`sales-representatives`]); });
    }
  }

}
