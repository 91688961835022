import { Component, OnInit } from '@angular/core';
import { HubConnectionBuilder, LogLevel, HubConnection } from '@microsoft/signalr';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { SystemMessage } from 'app/core/system-message/system-message-service';
import { CheckNetworkService } from 'local-db/services/check-network.service';

@Component({
	selector: 'pcg-push-notification',
	templateUrl: './push-notification.component.html',
	styleUrls: ['./push-notification.component.scss'],
})
export class PushNotificationComponent implements OnInit {

	subscriptions = new Subscription();
	showNotification = false;
	showPwaNotification = false;
	PwaNotificationText = "";
	isOnline: boolean;

	private hubConnection: HubConnection | undefined;

	constructor(
		private httpClient: HttpClient
		,public router: Router
	) { }

	/** Reload the page */
	hardReload() {
		window.location.reload();
		return false;
	}

	ngOnInit() {
		// WebSockets
		this.isOnline = CheckNetworkService.checkNetworkStatus();
		this.hubConnection = new HubConnectionBuilder().configureLogging(LogLevel.Debug).withUrl('/install-notify').build();
		this.hubConnection.start().catch(err => console.error(err.toString()));

		this.hubConnection.on('install', () => { this.showNotification = true; });

		const setTimerEvent = () => {
			if (this.showNotification === false) {
				// Time to wait before attempting reconnect. Randomized to prevent denial of servicing ourselves.
				const timeToWait = (Math.trunc(Math.random() * 10000) % 6) + 1;
				// Continuously try to reconnect to server on failure
				setTimeout(() => { this.hubConnection.start().catch(setTimerEvent); }, timeToWait);
			}
		};

		this.hubConnection.onclose(setTimerEvent);
		this.pwaNotification();
	}

	pwaNotification() {
		if (this.isOnline) {
			this.subscriptions.add(this.httpClient.get(`api/Home/GetPwaNotification`)
			.subscribe((msg: SystemMessage) => {
				if(msg.isSuccessful) {
					this.showPwaNotification = true;
					this.PwaNotificationText = msg.message;
				}}));
		}		
	}

	navToPwaInfo() {
		this.subscriptions.add(this.httpClient.get("api/HelpFiles/HelpFiles/GetPwaPath")
			.subscribe((url: any) => {
				window.open(url.message, '_blank');
				history.back();
			}));
	}

	dismissPwa() {
		this.showPwaNotification = false;
		this.subscriptions.add(this.httpClient.get(`api/Home/DismissPwaNotification`).subscribe((msg: SystemMessage) => { }));
	}
}
