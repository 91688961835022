import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { ActivationEnum } from 'app/core/enums/generated/ActivationEnum';
import { ActivationVm } from '../generated/Models/ActivationVm';

@Injectable({
    providedIn: 'root'
})
export class ActivationService {

    subscriptions: Subscription = new Subscription();

    constructor(private ms: SystemMessageService) { }

    activateDeactivate(id: any, activationEnum: ActivationEnum, isActive: boolean) {
        const model = new ActivationVm();
        model.id = id;
        model.activationEnum = activationEnum;
        model.isActive = isActive;
        const activationForm : UntypedFormGroup = ActivationVm.Form;
        activationForm.setValue(model);
        this.subscriptions.add(this.ms.getHttpObservable(this, 'api/Activation/ActivateDeactivate', activationForm).subscribe());
    }
}
