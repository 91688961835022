import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class ExceptionListVm {
	batchNumber: number;
	fileSource?: string;
	fileSourceId: number;
	importDate: Date;
	importedCustomer?: string;
	name?: string;
	streetAddress?: string;
	city?: string;
	state?: string;
	zipCode?: string;
	customerId?: number;
	makeMaster: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'batchNumber': new UntypedFormControl(0, [...(validators['batchNumber'] ?? []), ...[Validators.required]]),
			'fileSource': new UntypedFormControl(null, [...(validators['fileSource'] ?? []), ...[]]),
			'fileSourceId': new UntypedFormControl(0, [...(validators['fileSourceId'] ?? []), ...[Validators.required]]),
			'importDate': new UntypedFormControl('', [...(validators['importDate'] ?? []), ...[Validators.required]]),
			'importedCustomer': new UntypedFormControl(null, [...(validators['importedCustomer'] ?? []), ...[]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[]]),
			'streetAddress': new UntypedFormControl(null, [...(validators['streetAddress'] ?? []), ...[]]),
			'city': new UntypedFormControl(null, [...(validators['city'] ?? []), ...[]]),
			'state': new UntypedFormControl(null, [...(validators['state'] ?? []), ...[]]),
			'zipCode': new UntypedFormControl(null, [...(validators['zipCode'] ?? []), ...[]]),
			'customerId': new UntypedFormControl(null, [...(validators['customerId'] ?? []), ...[]]),
			'makeMaster': new UntypedFormControl(false, [...(validators['makeMaster'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['batchNumber', {"orderId":0,"sortColumn":"batchNumber","searchColumn":"batchNumber","includeInExcel":true,"exportColumn":null,"displayName":"Batch Number","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['fileSource', {"orderId":1,"sortColumn":"fileSource","searchColumn":"fileSource","includeInExcel":true,"exportColumn":null,"displayName":"File Source","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['importDate', {"orderId":2,"sortColumn":"importDate","searchColumn":"importDate","includeInExcel":true,"exportColumn":null,"displayName":"Import Date","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"shortDate"}],
			['importedCustomer', {"orderId":3,"sortColumn":"importedCustomer","searchColumn":"importedCustomer","includeInExcel":true,"exportColumn":null,"displayName":"Transaction Customer","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['makeMaster', {"orderId":4,"sortColumn":"makeMaster","searchColumn":"makeMaster","includeInExcel":false,"exportColumn":null,"displayName":"Make Master Customer","isVisible":true,"canSearch":false,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['customerId', {"orderId":5,"sortColumn":"customerId","searchColumn":"customerId","includeInExcel":false,"exportColumn":null,"displayName":"Customer from Master","isVisible":true,"canSearch":false,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['save', {"orderId":6,"sortColumn":"save","searchColumn":"save","includeInExcel":false,"exportColumn":null,"displayName":"Save","isVisible":true,"canSearch":false,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): ExceptionListVmFromFg {
		return new ExceptionListVmFromFg(fg);
	}
}

export class ExceptionListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get batchNumber(): number { return this.fg.get('batchNumber').value; }
	set batchNumber(val) { this.fg.get('batchNumber').setValue(val); }

	get fileSource(): string { return this.fg.get('fileSource').value; }
	set fileSource(val) { this.fg.get('fileSource').setValue(val); }

	get fileSourceId(): number { return this.fg.get('fileSourceId').value; }
	set fileSourceId(val) { this.fg.get('fileSourceId').setValue(val); }

	get importDate(): Date { return this.fg.get('importDate').value; }
	set importDate(val) { this.fg.get('importDate').setValue(val); }

	get importedCustomer(): string { return this.fg.get('importedCustomer').value; }
	set importedCustomer(val) { this.fg.get('importedCustomer').setValue(val); }

	get name(): string { return this.fg.get('name').value; }
	set name(val) { this.fg.get('name').setValue(val); }

	get streetAddress(): string { return this.fg.get('streetAddress').value; }
	set streetAddress(val) { this.fg.get('streetAddress').setValue(val); }

	get city(): string { return this.fg.get('city').value; }
	set city(val) { this.fg.get('city').setValue(val); }

	get state(): string { return this.fg.get('state').value; }
	set state(val) { this.fg.get('state').setValue(val); }

	get zipCode(): string { return this.fg.get('zipCode').value; }
	set zipCode(val) { this.fg.get('zipCode').setValue(val); }

	get customerId(): number { return this.fg.get('customerId').value; }
	set customerId(val) { this.fg.get('customerId').setValue(val); }

	get makeMaster(): boolean { return this.fg.get('makeMaster').value; }
	set makeMaster(val) { this.fg.get('makeMaster').setValue(val); }

}
