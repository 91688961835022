import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'pcg-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

	constructor(public title: Title) { title.setTitle('Dashboard'); }
}
