import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { addMinutes, addSeconds } from 'date-fns';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';

import { SecurityService } from '../security/security.service';
import { InactivityTimeoutComponent } from './inactivity-timeout.component';

@Injectable({
	providedIn: 'root'
})
export class InactivityTimeoutService {

	subsriptions = new Subscription();
	modalOpen = false;

	constructor(
		private modal: NgbModal
		, private sec: SecurityService
		, private router: Router
	) { this.startTimer(); }

	startTimer() {
		const source = timer(0, 1000);
		this.subsriptions.add(
			source.subscribe(() => {
				const user = this.sec.getUser();

				// Handle user timeout
				if (!this.sec.isLoggedOn() && this.isUrlNotBehindAuth()) {
					// Nullify token, security, and user info
					this.sec.setSecurity(null, null, null);

					// Redirect to login
					const redirectUrl = '/' + window.location.href.replace(document.getElementsByTagName('base')[0].href, '');
					this.router.navigateByUrl(`/login?redirectUrl=${encodeURIComponent(redirectUrl)}`);
				}

				// Handle inactivity timeout
				if (user && user.inactivityTimeout) {
					if (localStorage.getItem('lastAccess')) {
						const lastAccessedDate = new Date(JSON.parse(localStorage.getItem('lastAccess')));
						const endDate = addSeconds(addMinutes(lastAccessedDate, 4), 30);
						if (new Date() >= endDate) {
							if (!this.modalOpen) {
								this.modalOpen = true;
								this.modal.dismissAll();
								this.modal.open(InactivityTimeoutComponent, {
									beforeDismiss: () => {
										this.modalOpen = false;
										return true;
								}});
							}
						}
					} else { this.sec.setSecurity(null, null, null); }
				}
			})
		);
	}

	isUrlNotBehindAuth() {
		return (
			window.location.href.indexOf('login') === -1 &&
			window.location.href.indexOf('forgot-password') === -1 &&
			window.location.href.indexOf('change-password') === -1 &&
			window.location.href.indexOf('site-daily-count') === -1 //public page accessible via guid and email
		);
	}

	stopTimer() { this.subsriptions.unsubscribe(); }
}
