import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { SecurityService } from 'app/core/security/security.service';

@Component({
	selector: 'pcg-home',
	templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
	
	constructor(
		private sec: SecurityService
		, private router: Router
		, public title: Title
	) { title.setTitle('Innova Surgical'); }

	ngOnInit() {
		if (this.sec.isLoggedOn()) { this.router.navigate(['/workflow/1']); } 
		else { this.router.navigate(['/login']); }
	}
}
