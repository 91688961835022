import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { ChangePasswordForm } from 'app/core/pages/change-password/change-password.form';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';

@Component({
	selector: 'pcg-get-password',
	templateUrl: './get-password.component.html',
	styleUrls: ['./get-password.component.scss']
})
export class GetPasswordComponent {

	message = 'In order to download this file, it must be password protected.';
	title = 'Choose a Password';
	password: string;

	promptForUsersPassword = false;
	changePasswordForm: UntypedFormGroup = null;	
	func: (password: string) => any;

	constructor(public activeModal: NgbActiveModal, http: HttpClient) {
		const changePasswordForm = new ChangePasswordForm(http, false);
		this.changePasswordForm = changePasswordForm.formGroup;
	}

	setPassword() {
		if (this.promptForUsersPassword) {
			this.activeModal.dismiss();
			return true;
		}
		if (this.changePasswordForm.valid) { this.activeModal.dismiss(); }
		else { validateForm(this.changePasswordForm); }
		return this.changePasswordForm.valid;
	}
}
