import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { NavigationService, NavDividerCharacter } from '../navigation.service';
import { NavRoute } from '../nav.route.interface';

@Component({
	selector: 'pcg-search-nav',
	templateUrl: './search-nav.component.html',
	styleUrls: ['./search-nav.component.scss']
})
export class SearchNavComponent implements OnInit, OnDestroy {

	subscriptions: Subscription = new Subscription();
	search = ''; // The user's search text
	allPages: NavRoute[] = []; // All pages across the website
	matchingPages: NavRoute[] = []; // List of pages resulting from user search
	currentlySelected = 0; // Index of selected page in matchingPages
	srMessageDelayed$ = new Subject<string>();
	shiftDown = false; // Whether or not user currently holding shift down

	constructor(
		public activeModal: NgbActiveModal
		, private navService: NavigationService
		, private router: Router
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.navService.navRoutes$.subscribe(navRoutes => {
				this.allPages = this.navService.getFlatMenu(navRoutes).filter(o => !o.children);
			})
		);

		this.subscriptions.add(this.srMessageDelayed$.pipe(debounceTime(500)).subscribe(message => { this.srSpeak(message); }));
	}

	/** Close the search on press of escape */
	@HostListener('document:keyup.escape', ['$event'])
	onEscape(event: KeyboardEvent) { this.activeModal.close(); }

	/**
	 * Used to convert NavRoute id to a human readable location.
	 * For example: Style Guide > Templates > Detail Template
	 * @param id The NavRoute id to convert to a readable location.
	 */
	getReadableLocation(id: string, replaceStr = ' > ') {
		const navDividerRegExp = new RegExp(NavDividerCharacter, 'g');
		const rootRegExp = new RegExp(`^root${NavDividerCharacter}`);
		return id.replace(rootRegExp, '').replace(navDividerRegExp, replaceStr);
	}

	/** Move the selected item in list up one. Wraps to the end if
	 *	currently on the first item */
	moveUp() {
		if (this.currentlySelected === 0) { this.currentlySelected = this.matchingPages.length - 1; } 
		else { --this.currentlySelected; }
	}

	/** Move the selected item in list down one. Wraps to the beginning if
	 *	currently on the last item */
	moveDown() {
		if (this.currentlySelected === this.matchingPages.length - 1) { this.currentlySelected = 0; } 
		else { ++this.currentlySelected; }
	}

	/** Called on keyup or keydown of shift. Sets variable used to determine
	 *	if shift is being held down. */
	onShift(isDown: boolean) { this.shiftDown = isDown; }

	// Adding this because I noticed that using ctrl-shift-tab to go to previous tab
	// will cause the page to continue thinking those keys are being held down
	@HostListener('document:blur', ['$event'])
	onBlur() { this.onShift(false); }

	/** Update matchingPages list based on search term.
	 * Resets currently selected to beginning of list. */
	searchPages() {
		// Get a list of matches for search string by route name
		const nameMatches = this.allPages.filter(o => o.name.toLowerCase().includes(this.search.toLowerCase()));
		// Get a list of breadcrumb location matches for search string that are NOT matches by name
		const locationMatches = this.allPages.filter(
			o =>
				!o.name.toLowerCase().includes(this.search.toLowerCase()) 
				&& this.getReadableLocation(o.id).toLowerCase().includes(this.search.toLowerCase())
		);
		// Combine the two, with name matches on top
		this.matchingPages = nameMatches.concat(locationMatches);
		// Always reset currently selected to 0 on search
		this.currentlySelected = 0;
	}

	/** This handles keydown events on search textbox */
	onKeyDown(event: KeyboardEvent) {
		if (event.key === 'Shift') { this.onShift(true); }
		// Move the currently selected item up on up arrow or shift + tab
		if (event.key === 'ArrowUp' || event.key === 'Up' || (this.shiftDown && event.key === 'Tab')) {
			event.preventDefault();
			this.moveUp();
			const matchingPage = this.matchingPages[this.currentlySelected];
			this.srSpeak(`${matchingPage.name} link. Located at ${this.getReadableLocation(matchingPage.id)}`);
		}
		// Move the currently selected item down on down arrow or tab
		else if (event.key === 'ArrowDown' || event.key === 'Down' || event.key === 'Tab') {
			event.preventDefault();
			this.moveDown();
			const matchingPage = this.matchingPages[this.currentlySelected];
			this.srSpeak(`${matchingPage.name} link. Located at ${this.getReadableLocation(matchingPage.id)}`);
		}
	}

	/** This handles keyup events on search textbox */
	onKeyUp(event: KeyboardEvent) {
		// Move the currently selected item up on up arrow or shift + tab
		if (event.key === 'ArrowUp' || event.key === 'Up' || (this.shiftDown && event.key === 'Tab')) { return; }
		// Move the currently selected item down on down arrow or tab
		else if (event.key === 'ArrowDown' || event.key === 'Down' || event.key === 'Tab') {
			return; // Handled in onKeyDown
		}
		// Set shiftDown variable to false on shift keyup
		else if (event.key === 'Shift') { this.onShift(false); }
		// Got the the page if they hit enter and we have a match
		else if (event.key === 'Enter' && this.matchingPages.length) {
			const route = this.matchingPages[this.currentlySelected];
			this.router.navigate([route.path], { queryParams: route.queryParams });
			this.activeModal.close();
		} else if (this.search !== '') {
			this.searchPages();
			const matchingPage = this.matchingPages[this.currentlySelected];
			this.srMessageDelayed$.next(
				!matchingPage
					? undefined
					: `${matchingPage.name} link. Located at ${this.getReadableLocation(matchingPage.id)}`
			);
		} else {
			// Clear the matches if the search is empty
			this.matchingPages = [];
		}
	}

	/** Speak provided message on screen readers
	 *
	 * @param text The message to be vocalised
	 * @param priority Priority (non mandatory): "polite" (by default) or "assertive"
	 */
	srSpeak(text) {
		// Do nothing if no message sent in
		if (!text) { return; }

		var el = document.createElement('div');
		var id = 'speak-' + Date.now();
		el.setAttribute('id', id);
		el.setAttribute('aria-live', 'polite');
		el.classList.add('sr-only');
		document.body.appendChild(el);

		window.setTimeout(function () { document.getElementById(id).innerHTML = text; }, 100);
		window.setTimeout(function () { document.body.removeChild(document.getElementById(id)); }, 500);
	}

	/** Unsubscribe from subscriptions */
	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
