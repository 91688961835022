import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class CustomerSalesRepVm {
	customerId: number;
	salesRepId: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'customerId': new UntypedFormControl(0, [...(validators['customerId'] ?? []), ...[Validators.required]]),
			'salesRepId': new UntypedFormControl(0, [...(validators['salesRepId'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): CustomerSalesRepVmFromFg {
		return new CustomerSalesRepVmFromFg(fg);
	}
}

export class CustomerSalesRepVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get customerId(): number { return this.fg.get('customerId').value; }
	set customerId(val) { this.fg.get('customerId').setValue(val); }

	get salesRepId(): number { return this.fg.get('salesRepId').value; }
	set salesRepId(val) { this.fg.get('salesRepId').setValue(val); }

}
