<pcg-table 
	[canGlobalSearch]="false" 
	[multipleSearch]="false" 
	[pcgSort]="[['goLiveDateString', 'desc']]"
	dataSource="api/ReleaseNotes/GetReleaseNotesList" 
	[columnDefs]="columns"
>

	<ng-container matColumnDef="versionNumber">
		<th width="15%" mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('versionNumber').displayName }}</th>
		<td mat-cell *matCellDef="let row" data-label="Version Number">{{ row.versionNumber }}</td>
	</ng-container>

	<ng-container matColumnDef="goLiveDateString">
		<th width="15%" mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('goLiveDateString').displayName }}</th>
		<td mat-cell *matCellDef="let row" data-label="Go Live Date">{{ row.goLiveDateString }}</td>
	</ng-container>

	<ng-container matColumnDef="releaseNotesDescription">
		<th width="70%" mat-header-cell *matHeaderCellDef>{{ columns.get('releaseNotesDescription').displayName }}</th>
		<td mat-cell *matCellDef="let row" [innerHTML]="row.releaseNotesDescription" data-label="Description of Change"></td>
	</ng-container>

</pcg-table>
