import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class CustomerEditVm {
	customerId: number;
	name?: string;
	shippingStreetAddress?: string;
	shippingCity?: string;
	shippingState?: string;
	shippingZipCode?: string;
	billingStreetAddress?: string;
	billingCity?: string;
	billingState?: string;
	billingZipCode?: string;
	email?: string;
	purchaseOrder?: string;
	invoiceNumber?: string;
	region?: number;
	billingIsShipping: boolean;
	hasAliases: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'customerId': new UntypedFormControl(0, [...(validators['customerId'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[Validators.required, Validators.minLength(2), Validators.maxLength(100)]]),
			'shippingStreetAddress': new UntypedFormControl(null, [...(validators['shippingStreetAddress'] ?? []), ...[Validators.required]]),
			'shippingCity': new UntypedFormControl(null, [...(validators['shippingCity'] ?? []), ...[Validators.required]]),
			'shippingState': new UntypedFormControl(null, [...(validators['shippingState'] ?? []), ...[Validators.required]]),
			'shippingZipCode': new UntypedFormControl(null, [...(validators['shippingZipCode'] ?? []), ...[Validators.required]]),
			'billingStreetAddress': new UntypedFormControl(null, [...(validators['billingStreetAddress'] ?? []), ...[]]),
			'billingCity': new UntypedFormControl(null, [...(validators['billingCity'] ?? []), ...[]]),
			'billingState': new UntypedFormControl(null, [...(validators['billingState'] ?? []), ...[]]),
			'billingZipCode': new UntypedFormControl(null, [...(validators['billingZipCode'] ?? []), ...[]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[Validators.email]]),
			'purchaseOrder': new UntypedFormControl(null, [...(validators['purchaseOrder'] ?? []), ...[]]),
			'invoiceNumber': new UntypedFormControl(null, [...(validators['invoiceNumber'] ?? []), ...[]]),
			'region': new UntypedFormControl(null, [...(validators['region'] ?? []), ...[]]),
			'billingIsShipping': new UntypedFormControl(false, [...(validators['billingIsShipping'] ?? []), ...[]]),
			'hasAliases': new UntypedFormControl(false, [...(validators['hasAliases'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): CustomerEditVmFromFg {
		return new CustomerEditVmFromFg(fg);
	}
}

export class CustomerEditVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get customerId(): number { return this.fg.get('customerId').value; }
	set customerId(val) { this.fg.get('customerId').setValue(val); }

	get name(): string { return this.fg.get('name').value; }
	set name(val) { this.fg.get('name').setValue(val); }

	get shippingStreetAddress(): string { return this.fg.get('shippingStreetAddress').value; }
	set shippingStreetAddress(val) { this.fg.get('shippingStreetAddress').setValue(val); }

	get shippingCity(): string { return this.fg.get('shippingCity').value; }
	set shippingCity(val) { this.fg.get('shippingCity').setValue(val); }

	get shippingState(): string { return this.fg.get('shippingState').value; }
	set shippingState(val) { this.fg.get('shippingState').setValue(val); }

	get shippingZipCode(): string { return this.fg.get('shippingZipCode').value; }
	set shippingZipCode(val) { this.fg.get('shippingZipCode').setValue(val); }

	get billingStreetAddress(): string { return this.fg.get('billingStreetAddress').value; }
	set billingStreetAddress(val) { this.fg.get('billingStreetAddress').setValue(val); }

	get billingCity(): string { return this.fg.get('billingCity').value; }
	set billingCity(val) { this.fg.get('billingCity').setValue(val); }

	get billingState(): string { return this.fg.get('billingState').value; }
	set billingState(val) { this.fg.get('billingState').setValue(val); }

	get billingZipCode(): string { return this.fg.get('billingZipCode').value; }
	set billingZipCode(val) { this.fg.get('billingZipCode').setValue(val); }

	get email(): string { return this.fg.get('email').value; }
	set email(val) { this.fg.get('email').setValue(val); }

	get purchaseOrder(): string { return this.fg.get('purchaseOrder').value; }
	set purchaseOrder(val) { this.fg.get('purchaseOrder').setValue(val); }

	get invoiceNumber(): string { return this.fg.get('invoiceNumber').value; }
	set invoiceNumber(val) { this.fg.get('invoiceNumber').setValue(val); }

	get region(): number { return this.fg.get('region').value; }
	set region(val) { this.fg.get('region').setValue(val); }

	get billingIsShipping(): boolean { return this.fg.get('billingIsShipping').value; }
	set billingIsShipping(val) { this.fg.get('billingIsShipping').setValue(val); }

	get hasAliases(): boolean { return this.fg.get('hasAliases').value; }
	set hasAliases(val) { this.fg.get('hasAliases').setValue(val); }

}
