export interface IReleaseNotesTbl {
	versionNumber?: string;
	goLiveDateString?: string;
	goLiveDate: Date;
	releaseNotesDescription?: string;
};

export class ReleaseNotesTbl implements IReleaseNotesTbl {
	versionNumber = '';
	goLiveDateString = '';
	goLiveDate = new Date();
	releaseNotesDescription = '';
};
