import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    constructor(private http: HttpClient) { }

    static setIsMobile(getScreenWidth: any) {
        if (getScreenWidth < 993) { return true; } //previously was 1025px
        else { return false; }
    }
}
