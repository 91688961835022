export interface ITransactionListTbl {
	transactionId: number;
	importHistoryId: number;
	fileSource?: string;
	importedDate: Date;
	importedDateString?: string;
	salesRegion?: string;
	distributorName?: string;
	transactionSource?: string;
	billToCustomer?: string;
	shipToCustomer?: string;
	transactionDate: Date;
	transactionDateString?: string;
	transactionNumber?: string;
	productGroup?: string;
	partsNumber?: string;
	salesAmount: number;
	quantity: number;
	unitSalesPrice: number;
	listPrice: number;
	percentDiscount: number;
	commissionAmount: number;
	commissionBracket?: string;
	commissionSummary?: string;
	compSegment?: string;
	customerTier?: string;
	gpoAffiliation?: string;
	gpoStatus?: string;
	canDelete: boolean;
};

export class TransactionListTbl implements ITransactionListTbl {
	transactionId = 0;
	importHistoryId = 0;
	fileSource = '';
	importedDate = new Date();
	importedDateString = '';
	salesRegion = '';
	distributorName = '';
	transactionSource = '';
	billToCustomer = '';
	shipToCustomer = '';
	transactionDate = new Date();
	transactionDateString = '';
	transactionNumber = '';
	productGroup = '';
	partsNumber = '';
	salesAmount = 0;
	quantity = 0;
	unitSalesPrice = 0;
	listPrice = 0;
	percentDiscount = 0;
	commissionAmount = 0;
	commissionBracket = '';
	commissionSummary = '';
	compSegment = '';
	customerTier = '';
	gpoAffiliation = '';
	gpoStatus = '';
	canDelete = false;
};
