import { Component, ViewChild } from '@angular/core';
import {MatStepper, MatStepperModule} from '@angular/material/stepper';
import { CustomerModule } from '../Customers/customers-module';
import { TransactionsModule } from '../transactions/transactions.module';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ExceptionsComponent } from 'app/transactions/exceptions/exceptions.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'pcg-workflow',
  standalone: true,
  imports: [MatStepperModule, CustomerModule, TransactionsModule, DecimalPipe, CommonModule],
  templateUrl: './workflow.component.html',
  styleUrl: './workflow.component.scss'
})
export class WorkflowComponent {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('exceptionsTable') exceptionsTable: ExceptionsComponent;
  selectedStep: number = 0;
  exceptionCount: number = 0;
  exceptionsResolved = this.formBuilder.group({
    exceptionsResolved: [false, Validators.requiredTrue],
  });

  customerListApproved = this.formBuilder.group({
    customerListApproved: [false, Validators.requiredTrue],
  });

  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, private route: ActivatedRoute, private router: Router) {
    this.getExceptionCount();
  }

  ngAfterViewInit() {
    this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
      let step = paramMap.get('step');
      this.stepper.linear = false;
      this.stepper.selectedIndex = +step -1;
      this.stepper.linear = true;
    });
  }

  public selectionChange(stepper: StepperSelectionEvent) {
    this.selectedStep = stepper.selectedIndex + 1;
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      let step = paramMap.get('step');
      this.router.navigateByUrl(this.router.url.replace(step, this.selectedStep.toString()));
    })
  }

  transactionsImported() {
    debugger;
    this.getExceptionCount();
    this.exceptionsTable.reloadTable();
  }

  getExceptionCount() {
    this.httpClient.get<number>('api/Transactions/Transactions/GetExceptionCount').subscribe((count: number) => {
      this.exceptionCount = count;
      this.exceptionsResolved.get('exceptionsResolved')?.setValue(this.exceptionCount == 0);
    })
  }

  onCustomererListApproval() {
    this.customerListApproved.get('customerListApproved')?.setValue(true);
  }

  onExceptionResolved() {
    this.exceptionCount -= 1;
    this.exceptionsResolved.get('exceptionsResolved')?.setValue(this.exceptionCount == 0);
  }
}
