<div id="addContent" class="hide-on-print">
	<div id="actionElements">
		<a 
			*ngIf="customerId == null"
		 	routerLink="/sales-representatives/edit-sales-representative/0"
		  	class="btn btn-save btn-sm inline-add-btn mt-2"
		>
			Add Sales Rep.
		</a>
		<a 
			*ngIf="customerId != null"
			(click)="openModal();"
		  	class="btn btn-save btn-sm inline-add-btn mt-2"
		>
			Add Sales Rep to Master Customer
		</a>
	</div>
</div>
<pcg-table #table [dataSource]="dataSource" [pcgSort]="[['fullName', 'asc']]" [columnDefs]="columns"
	[isRowClickable]="true" isUsingMobileCard="true" uniqueColumn="fullName" [canExportTableToExcel]="true"
	(clickRow)="redirectSalesRep($event)" [redirectFunction]="redirectSalesRep"
	deleteUrl="deleteUrl" identificationColumn="salesRepId"
	confirmMessage="Are you sure you want to delete this sales representative?">

	<ng-container matColumnDef="fullName">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>
			{{ columns.get('fullName').displayName }}
		</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('fullName').displayName">
			<pcg-edit-link [url]="'/sales-representatives/edit-sales-representative/' + row.salesRepId">
				{{ row.fullName }}
			</pcg-edit-link>
		</td>
	</ng-container>

	<ng-container matColumnDef="email">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('email').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('email').displayName">{{ row.email }}</td>
	</ng-container>

	<ng-container matColumnDef="phoneNumber">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('phoneNumber').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('phoneNumber').displayName">{{ row.phoneNumber
			}}</td>
	</ng-container>
	<ng-container matColumnDef="team">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('team').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('team').displayName">{{ row.team }}</td>
	</ng-container>
	<ng-container matColumnDef="region">
		<th mat-header-cell *matHeaderCellDef>{{ columns.get('region').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('region').displayName">{{ row.region }}</td>
	</ng-container>
	<ng-container matColumnDef="territory">
		<th mat-header-cell *matHeaderCellDef>{{ columns.get('territory').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('territory').displayName">{{ row.territory }}
		</td>
	</ng-container>
	<ng-container matColumnDef="commission">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('commission').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('commission').displayName">{{ row.commission |
			percent: '1.0-2'
			}}
		</td>
	</ng-container>
	<ng-container matColumnDef="discount">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('discount').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('discount').displayName">{{ row.discount |
			percent: '1.0-2' }}
		</td>
	</ng-container>
	<ng-container matColumnDef="payrollId">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('payrollId').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('payrollId').displayName">{{ row.payrollId }}
		</td>
	</ng-container>
	<ng-container matColumnDef="canDelete">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('canDelete').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('canDelete').displayName">
			<pcg-delete-button [table]='table'
				[confirmMessage]='"Are you sure you want to delete this sales representative?"'
				[url]='deleteUrl' [id]='customerId == null ? row.salesRepId : { salesRepId: row.salesRepId, customerId: this.customerId }'>
			</pcg-delete-button>
		</td>
	</ng-container>

</pcg-table>