<pcg-table 
	#table 
	[dataSource]="dataSource"
	[pcgSort]="[['importDate', 'asc']]"
	[columnDefs]="columns" 
	[canExportTableToExcel]="true"
	isUsingMobileCard="true"
	uniqueColumn="importHistoryId"
	[isRowClickable]="true"
	(clickRow)="redirectImportHistory($event)"
	[redirectFunction]="redirectImportHistory"
	deleteUrl="api/Transactions/Transactions/DeleteImportHistory"
	identificationColumn="importHistoryId"
	confirmMessage="Are you sure you want to delete the batch and all related transactions?"
>
    <ng-container matColumnDef="importHistoryId">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('importHistoryId').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('importHistoryId').displayName">{{ row.importHistoryId }}</td>
    </ng-container>

    <ng-container matColumnDef="fileName">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('fileName').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('fileName').displayName">{{ row.fileName }}</td>
    </ng-container>

	<ng-container matColumnDef="importDate">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('importDate').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('importDate').displayName">{{ row.importDate | date: 'shortDate' }}</td>
	</ng-container>
	
	<ng-container matColumnDef="fileSource">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('fileSource').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('fileSource').displayName">{{ row.fileSource }}</td>
	</ng-container>
	
	<ng-container matColumnDef="importedBy">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('importedBy').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('importedBy').displayName">{{ row.importedBy }}</td>
	</ng-container>

    <ng-container matColumnDef="recordsImported">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('recordsImported').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('recordsImported').displayName">
            <pcg-edit-link [url]='"/transactions/import-history/transactions/" + row.importHistoryId'>{{ row.recordsImported | number }}</pcg-edit-link>
        </td>
	</ng-container>

    <ng-container matColumnDef="exceptionCount">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('exceptionCount').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('exceptionCount').displayName">
            <pcg-edit-link [url]='"/transactions/import-history/exceptions/" + row.importHistoryId'>{{ row.exceptionCount | number }}</pcg-edit-link>
        </td>
	</ng-container>

	<ng-container matColumnDef="canDelete">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef class='text-center'>Delete</th>
		<td mat-cell *matCellDef='let row' class='text-center' [attr.data-label]="Delete">
			<pcg-delete-button 
				[table]='table' 
				confirmMessage="Are you sure you want to delete {{row.recordsImported | number}} transactions?" 
				url='api/Transactions/Transactions/DeleteImportHistory' 
				[id]='row.importHistoryId'
			></pcg-delete-button>
		</td>
	</ng-container>
</pcg-table>