export interface IDowntimeNotificationTbl {
	downtimeNotificationId: number;
	title?: string;
	message?: string;
	startDate?: string;
	endDate?: string;
};

export class DowntimeNotificationTbl implements IDowntimeNotificationTbl {
	downtimeNotificationId = 0;
	title = '';
	message = '';
	startDate = '';
	endDate = '';
};
