import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class CustomerFileUploadVm {
	file?: any;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'file': new UntypedFormControl(null, [...(validators['file'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): CustomerFileUploadVmFromFg {
		return new CustomerFileUploadVmFromFg(fg);
	}
}

export class CustomerFileUploadVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get file(): any { return this.fg.get('file').value; }
	set file(val) { this.fg.get('file').setValue(val); }

}
