<div class="modal-header">
	<h5 class="modal-title" id="modal-basic-title">Permissions</h5>
	<button class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="d-flex flex-column px-5 m-auto">
	<div class="row p-2">
		<span class="col-md-4"></span>
		<div *ngFor="let permission of permissions" class="col-md-2 text-center">
			{{ permission?.permissionRoleString }}
		</div>
	</div>

	<div class="row p-2">
		<div class="col-md-4">
			<span ngbTooltip="" triggers="pointerup hover">Core Features</span>
		</div>
		<div *ngFor="let permission of permissions" class="col-md-2 text-center">
			<fa-icon 
				*ngIf="permission.coreFeatures" 
				[icon]="faCheck"
			></fa-icon>
		</div>
	</div>

	<div class="row p-2">
		<div class="col-md-4">
			<span ngbTooltip="" triggers="pointerup hover">Manage Users</span>
		</div>
		<div *ngFor="let permission of permissions" class="col-md-2 text-center">
			<fa-icon 
				*ngIf="permission.manageUsers" 
				[icon]="faCheck"
			></fa-icon>
		</div>
	</div>

	<div class="row p-2">
		<div class="col-md-4">
			<span ngbTooltip="" triggers="pointerup hover">Application Settings </span>
		</div>
		<div *ngFor="let permission of permissions" class="col-md-2 text-center">
			<fa-icon 
				*ngIf="permission.applicationSettings" 
				[icon]="faCheck"
			></fa-icon>
		</div>
	</div>

	<div class="row p-2">		
		<div class="col-md-4">
			<span ngbTooltip="" triggers="pointerup hover">Can Change Permissions </span>
		</div>
		<div *ngFor="let permission of permissions" class="col-md-2 text-center">
			<fa-icon 
				*ngIf="permission.canChangePermissions" 
				[icon]="faCheck"
			></fa-icon>
		</div>
	</div>

	<div class="row p-2" *ngIf="isAdmin">
		<div class="col-md-4">
			<span ngbTooltip="View automated jobs." triggers="pointerup hover">View Quartz Jobs </span>
		</div>
		<div *ngFor="let permission of permissions" class="col-md-2 text-center">
			<fa-icon 
				*ngIf="permission.viewQuartzJobs" 
				[icon]="faCheck"
			></fa-icon>
		</div>
	</div>

	<div class="row p-2" *ngIf="isAdmin">
		<div class="col-md-4">
			<span ngbTooltip="" triggers="pointerup hover">Admin Features </span>
		</div>
		<div *ngFor="let permission of permissions" class="col-md-2 text-center">
			<fa-icon 
				*ngIf="permission.adminFeatures" 
				[icon]="faCheck"
			></fa-icon>
		</div>
	</div>

	<div class="mx-auto p-3">
		<button 
			type="button" 
			class="btn btn-cancel btn-lg" 
			(click)="activeModal.dismiss()"
		>Cancel</button>
	</div>
</div>
