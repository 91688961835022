export interface ISalesRepListTbl {
	salesRepId: number;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	team?: string;
	territory?: string;
	region?: string;
	commission: number;
	discount: number;
	canDelete: boolean;
	payrollId?: number;
};

export class SalesRepListTbl implements ISalesRepListTbl {
	salesRepId = 0;
	fullName = '';
	email = '';
	phoneNumber = '';
	team = '';
	territory = '';
	region = '';
	commission = 0;
	discount = 0;
	canDelete = false;
	payrollId = 0;
};
