import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ImportHistoryListVm } from 'app/shared/generated/Transactions/Models/ImportHistoryListVm'

@Component({
  selector: 'pcg-import-history',
  templateUrl: './import-history.component.html',
  styleUrl: './import-history.component.scss'
})
export class ImportHistoryComponent {
  columns = ImportHistoryListVm.ColumnDefs;
	dataSource = "api/Transactions/Transactions/GetImportHistoryList";

  constructor(private title: Title, private router: Router) {
    title.setTitle("Batches")
  }

  redirectImportHistory(row) { this.router.navigateByUrl(`/transactions/import-history/transactions/${row.importHistoryId}`); }
}
