import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditSalesRepVm {
	salesRepId: number;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	team?: string;
	territory?: number[];
	region?: number[];
	commission?: number;
	discount?: number;
	isManager: boolean;
	payrollId?: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'salesRepId': new UntypedFormControl(0, [...(validators['salesRepId'] ?? []), ...[Validators.required]]),
			'fullName': new UntypedFormControl(null, [...(validators['fullName'] ?? []), ...[Validators.required, Validators.minLength(2), Validators.maxLength(100), Validators.pattern("^[a-zA-Z-]+( [a-zA-Z-0]+)*$")]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[Validators.email]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[]]),
			'team': new UntypedFormControl(null, [...(validators['team'] ?? []), ...[]]),
			'territory': new UntypedFormControl([], [...(validators['territory'] ?? []), ...[]]),
			'region': new UntypedFormControl([], [...(validators['region'] ?? []), ...[]]),
			'commission': new UntypedFormControl(null, [...(validators['commission'] ?? []), ...[]]),
			'discount': new UntypedFormControl(null, [...(validators['discount'] ?? []), ...[]]),
			'isManager': new UntypedFormControl(false, [...(validators['isManager'] ?? []), ...[]]),
			'payrollId': new UntypedFormControl(null, [...(validators['payrollId'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): EditSalesRepVmFromFg {
		return new EditSalesRepVmFromFg(fg);
	}
}

export class EditSalesRepVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get salesRepId(): number { return this.fg.get('salesRepId').value; }
	set salesRepId(val) { this.fg.get('salesRepId').setValue(val); }

	get fullName(): string { return this.fg.get('fullName').value; }
	set fullName(val) { this.fg.get('fullName').setValue(val); }

	get email(): string { return this.fg.get('email').value; }
	set email(val) { this.fg.get('email').setValue(val); }

	get phoneNumber(): string { return this.fg.get('phoneNumber').value; }
	set phoneNumber(val) { this.fg.get('phoneNumber').setValue(val); }

	get team(): string { return this.fg.get('team').value; }
	set team(val) { this.fg.get('team').setValue(val); }

	get commission(): number { return this.fg.get('commission').value; }
	set commission(val) { this.fg.get('commission').setValue(val); }

	get discount(): number { return this.fg.get('discount').value; }
	set discount(val) { this.fg.get('discount').setValue(val); }

	get isManager(): boolean { return this.fg.get('isManager').value; }
	set isManager(val) { this.fg.get('isManager').setValue(val); }

	get payrollId(): number { return this.fg.get('payrollId').value; }
	set payrollId(val) { this.fg.get('payrollId').setValue(val); }

}
