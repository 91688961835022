/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, OnDestroy, OnInit, HostListener, ElementRef, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/User/JavaScript/UserJsVm';
import { NavigationService, NavDividerCharacter } from 'app/shared/navigation/navigation.service';
import { TopClickNavComponent } from 'app/shared/navigation/top-click-nav/top-click-nav.component';
import { SearchNavComponent } from 'app/shared/navigation/search-nav/search-nav.component';
import { pcgSettings } from 'app/shared/generated/pcg-settings';
import { getMainNavRoutes } from '../../main-nav-routes';
import { GlobalService } from 'app/shared/services/global.service';
import { GlobalVariablesService } from 'app/services/global-variables.service';
import { de } from 'date-fns/locale';

@Component({
	selector: 'pcg-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class TopHeaderComponent implements OnDestroy, OnInit {
	
	@ViewChild(TopClickNavComponent, { static: true }) nav: TopClickNavComponent;
	@ViewChild('userNav') userNav: ElementRef;
	@ViewChild('mainHamburger', { static: true }) mainHamburger: ElementRef;
	@ViewChild('btnSearch') btnSearch: ElementRef;

	@Input() tabPanel: any;

	@Output() hamburgerClick = new EventEmitter<boolean>();

	user: UserJsVm;
	subscriptions: Subscription = new Subscription();
	formGroup = UserJsVm.Form;

	navRoutes: NavRoute[];
	currSelectedNavMenu: NavRoute;

	currForceSelect: string;
	currMainNavMenu: string;
	mode = pcgSettings.mode;	
	navDividerCharacter = NavDividerCharacter;

	shouldOpenSearch = false;	
	userNavOpen = false;
	adminAccess = false;
	isMobile: boolean;	

	navigationOffset = 0;

	faIconName = { faBars };

	constructor(
		private sec: SecurityService
		, private router: Router
		, private navService: NavigationService
		, private modalService: NgbModal
		, private globalVariablesService: GlobalVariablesService
	) {}

	@HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

	// Close the main navigation when they click outside of it
	// and are not clicking the mobile hamburger button
	@HostListener('document:click', ['$event'])
	clickDoc(event: { target: any; }) {
		if (
			!this.mainHamburger?.nativeElement.contains(event.target) 
			&& !this.nav?.elRef?.nativeElement.contains(event.target) 
			&& !this.userNav?.nativeElement.contains(event.target)
		) {
			this.navService.setCurrOpenNavMenu('');
			this.userNavOpen = false;
		}
	}

	// Open search when they hit the '/' (or '`') key
	@HostListener('document:keydown', ['$event'])
	onKeydown(event: KeyboardEvent) {
		if ((event.key === '/' || event.key === '`') && !this.isFormField(event.target)) {
			event.preventDefault();
			if (!this.modalService.hasOpenModals()) { this.modalService.open(SearchNavComponent); }
		}
	}

	ngOnInit() {
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		this.subscriptions.add(this.sec.user$.subscribe(user => { this.user = user; }));
		this.subscriptions.add(
			this.navService.navRoutes$.subscribe(navRoutes => {
				this.navRoutes = this.navService.getFlatMenu(navRoutes).filter(o => !o.parentNav);
			})
		);

		this.subscriptions.add(
			this.navService.currOpenPrimaryNavMenu$.subscribe(currMainNavMenu => { this.currMainNavMenu = currMainNavMenu; })
		);
		this.subscriptions.add(
			this.navService.currSelectedMainNavMenu$.subscribe(currSelectedNavMenu => {
				this.currSelectedNavMenu = currSelectedNavMenu;
				if (!currSelectedNavMenu || currSelectedNavMenu.id === 'root') { this.navService.setOpenSecondaryMenu([]); }
			})
		);
		this.subscriptions.add(
			this.navService.currOpenSecondaryNavMenu$.subscribe(currSecondaryNavMenu => { 
				this.currSelectedNavMenu = currSecondaryNavMenu; 
				if (this.currSelectedNavMenu != null) {
					if (this.currSelectedNavMenu.name == "Edit User") {
						this.globalVariablesService.userName.subscribe((data) => {this.currSelectedNavMenu.name = data })
					}
				}
			})
		);
		this.subscriptions.add(this.navService.currForceSelect$.subscribe(currForceSelect => { this.currForceSelect = currForceSelect; }));

		// Subscribe to route changes and update the navs when they happen
		this.subscriptions.add(this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => { this.navService.navRoutes.next(this.sec.getSecureNavItems(getMainNavRoutes())); }));

		setTimeout(() => {
			let secondaryNav = document.getElementById("secondaryNav");
			if (secondaryNav != null) { secondaryNav.scrollLeft = Number(localStorage?.getItem("subNavPosition")); }
		}, 100)
	}

	setSubNavPosition() {
		localStorage.setItem("subNavPosition", document.getElementById("secondaryNav").scrollLeft.toString());
	}

	// Logout removes all of our security variables and redirects user to root
	logout() {
		this.sec.setSecurity(null, null, null);
		this.deleteAllCookies();
		this.router.navigate(['/']);
	}

	// Checks if user can view Admin Dashboard
	checkAccess(setUserNav = false) {
		const flatMenu = this.navService.getFlatMenu(this.sec.getSecureNavItems(getMainNavRoutes()));
		this.adminAccess = !!flatMenu.find((o: NavRoute) => o.name === 'Admin');
		if (setUserNav === true) { this.userNavOpen = true; }
	}

	/** Handle clicking any link in the user navigation **/
	clickUserLink() { this.userNavOpen = false; }

	// This is used above to delete all cookies on logout
	deleteAllCookies() {
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}
	}

	// Toggle whether or not the main navigation is open
	toggleNav() { this.hamburgerClick.emit(true); }
	// Open the global navigation search
	openSearch() { this.modalService.open(SearchNavComponent); }	

	// Used in the keyup function belew to prevent bringing up the search
	// if they are focused in a form field
	isFormField(e) {
		if (!(e instanceof HTMLElement)) { return false; }
		const t = e.nodeName.toLowerCase();
		const n = (e.getAttribute('type') || '').toLowerCase();
		return ('select' === t || 'textarea' === t || ('input' === t && 'submit' !== n && 'reset' !== n) || e.isContentEditable);
	}	

	setDynamicStyle(name: string, styles: string) {
		let dynamicStyle = document.getElementById(name);
		if (dynamicStyle) { document.head.removeChild(dynamicStyle); }
		dynamicStyle = document.createElement('style');
		dynamicStyle.id = name;
		dynamicStyle.innerHTML = styles;
		document.head.appendChild(dynamicStyle);
	}

	// It's good practice to always unsubscribe from subscriptions
	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
