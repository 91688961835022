import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const getTransactionNav: () => NavRoute = () => ({
	name: 'Transactions',
	children: getTransactionArea()
});

function getTransactionArea(): NavRoute[] {
	var id: string = "";
	var url = window.location.href;

	if(url.indexOf('transactions/transaction-edit/') > -1) {
		id = url.substring(url.lastIndexOf('/') + 1);
	}

	if(id === "") {
		return [
			{
				name: 'Transactions'
				, exact: true
				, path: 'transactions'
			},
			{
				name: 'Batches'
				, exact: true
				, path: 'transactions/import-history'
			},
			{
				name: 'Exceptions'
				, exact: true
				, path: `transactions/exceptions`
			},
		]
	}

	else {
		return [
			{
				name: 'Back'
				, exact: true
				, path: 'workflow/2'
			},
			{
				name: 'Details'
				, exact: true
				, path: `transactions/transaction-edit/${id}`
			},
		]
	}
}
