import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class TransactionEditVm {
	transactionId: number;
	importHistoryId?: number;
	fileSource?: string;
	importedDate: Date;
	importedDateString?: string;
	salesRegionId?: number;
	distributorName?: string;
	transactionSource?: string;
	billToCustomerId?: number;
	shipToCustomerId?: number;
	transactionDate: Date;
	transactionDateString?: string;
	transactionNumber?: string;
	productGroup?: string;
	partsNumber?: string;
	salesAmount?: number;
	quantity?: number;
	unitSalesPrice?: number;
	listPrice?: number;
	percentDiscount?: number;
	commissionAmount?: number;
	commissionBracket?: string;
	commissionSummary?: string;
	compSegment?: string;
	customerTier?: string;
	gpoAffiliation?: string;
	gpoStatus?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'transactionId': new UntypedFormControl(0, [...(validators['transactionId'] ?? []), ...[Validators.required]]),
			'importHistoryId': new UntypedFormControl(null, [...(validators['importHistoryId'] ?? []), ...[]]),
			'fileSource': new UntypedFormControl(null, [...(validators['fileSource'] ?? []), ...[]]),
			'importedDate': new UntypedFormControl('', [...(validators['importedDate'] ?? []), ...[Validators.required]]),
			'importedDateString': new UntypedFormControl(null, [...(validators['importedDateString'] ?? []), ...[]]),
			'salesRegionId': new UntypedFormControl(null, [...(validators['salesRegionId'] ?? []), ...[]]),
			'distributorName': new UntypedFormControl(null, [...(validators['distributorName'] ?? []), ...[]]),
			'transactionSource': new UntypedFormControl(null, [...(validators['transactionSource'] ?? []), ...[]]),
			'billToCustomerId': new UntypedFormControl(null, [...(validators['billToCustomerId'] ?? []), ...[]]),
			'shipToCustomerId': new UntypedFormControl(null, [...(validators['shipToCustomerId'] ?? []), ...[]]),
			'transactionDate': new UntypedFormControl('', [...(validators['transactionDate'] ?? []), ...[Validators.required]]),
			'transactionDateString': new UntypedFormControl(null, [...(validators['transactionDateString'] ?? []), ...[]]),
			'transactionNumber': new UntypedFormControl(null, [...(validators['transactionNumber'] ?? []), ...[]]),
			'productGroup': new UntypedFormControl(null, [...(validators['productGroup'] ?? []), ...[]]),
			'partsNumber': new UntypedFormControl(null, [...(validators['partsNumber'] ?? []), ...[]]),
			'salesAmount': new UntypedFormControl(null, [...(validators['salesAmount'] ?? []), ...[]]),
			'quantity': new UntypedFormControl(null, [...(validators['quantity'] ?? []), ...[]]),
			'unitSalesPrice': new UntypedFormControl(null, [...(validators['unitSalesPrice'] ?? []), ...[]]),
			'listPrice': new UntypedFormControl(null, [...(validators['listPrice'] ?? []), ...[]]),
			'percentDiscount': new UntypedFormControl(null, [...(validators['percentDiscount'] ?? []), ...[]]),
			'commissionAmount': new UntypedFormControl(null, [...(validators['commissionAmount'] ?? []), ...[]]),
			'commissionBracket': new UntypedFormControl(null, [...(validators['commissionBracket'] ?? []), ...[]]),
			'commissionSummary': new UntypedFormControl(null, [...(validators['commissionSummary'] ?? []), ...[]]),
			'compSegment': new UntypedFormControl(null, [...(validators['compSegment'] ?? []), ...[]]),
			'customerTier': new UntypedFormControl(null, [...(validators['customerTier'] ?? []), ...[]]),
			'gpoAffiliation': new UntypedFormControl(null, [...(validators['gpoAffiliation'] ?? []), ...[]]),
			'gpoStatus': new UntypedFormControl(null, [...(validators['gpoStatus'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): TransactionEditVmFromFg {
		return new TransactionEditVmFromFg(fg);
	}
}

export class TransactionEditVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get transactionId(): number { return this.fg.get('transactionId').value; }
	set transactionId(val) { this.fg.get('transactionId').setValue(val); }

	get importHistoryId(): number { return this.fg.get('importHistoryId').value; }
	set importHistoryId(val) { this.fg.get('importHistoryId').setValue(val); }

	get fileSource(): string { return this.fg.get('fileSource').value; }
	set fileSource(val) { this.fg.get('fileSource').setValue(val); }

	get importedDate(): Date { return this.fg.get('importedDate').value; }
	set importedDate(val) { this.fg.get('importedDate').setValue(val); }

	get importedDateString(): string { return this.fg.get('importedDateString').value; }
	set importedDateString(val) { this.fg.get('importedDateString').setValue(val); }

	get salesRegionId(): number { return this.fg.get('salesRegionId').value; }
	set salesRegionId(val) { this.fg.get('salesRegionId').setValue(val); }

	get distributorName(): string { return this.fg.get('distributorName').value; }
	set distributorName(val) { this.fg.get('distributorName').setValue(val); }

	get transactionSource(): string { return this.fg.get('transactionSource').value; }
	set transactionSource(val) { this.fg.get('transactionSource').setValue(val); }

	get billToCustomerId(): number { return this.fg.get('billToCustomerId').value; }
	set billToCustomerId(val) { this.fg.get('billToCustomerId').setValue(val); }

	get shipToCustomerId(): number { return this.fg.get('shipToCustomerId').value; }
	set shipToCustomerId(val) { this.fg.get('shipToCustomerId').setValue(val); }

	get transactionDate(): Date { return this.fg.get('transactionDate').value; }
	set transactionDate(val) { this.fg.get('transactionDate').setValue(val); }

	get transactionDateString(): string { return this.fg.get('transactionDateString').value; }
	set transactionDateString(val) { this.fg.get('transactionDateString').setValue(val); }

	get transactionNumber(): string { return this.fg.get('transactionNumber').value; }
	set transactionNumber(val) { this.fg.get('transactionNumber').setValue(val); }

	get productGroup(): string { return this.fg.get('productGroup').value; }
	set productGroup(val) { this.fg.get('productGroup').setValue(val); }

	get partsNumber(): string { return this.fg.get('partsNumber').value; }
	set partsNumber(val) { this.fg.get('partsNumber').setValue(val); }

	get salesAmount(): number { return this.fg.get('salesAmount').value; }
	set salesAmount(val) { this.fg.get('salesAmount').setValue(val); }

	get quantity(): number { return this.fg.get('quantity').value; }
	set quantity(val) { this.fg.get('quantity').setValue(val); }

	get unitSalesPrice(): number { return this.fg.get('unitSalesPrice').value; }
	set unitSalesPrice(val) { this.fg.get('unitSalesPrice').setValue(val); }

	get listPrice(): number { return this.fg.get('listPrice').value; }
	set listPrice(val) { this.fg.get('listPrice').setValue(val); }

	get percentDiscount(): number { return this.fg.get('percentDiscount').value; }
	set percentDiscount(val) { this.fg.get('percentDiscount').setValue(val); }

	get commissionAmount(): number { return this.fg.get('commissionAmount').value; }
	set commissionAmount(val) { this.fg.get('commissionAmount').setValue(val); }

	get commissionBracket(): string { return this.fg.get('commissionBracket').value; }
	set commissionBracket(val) { this.fg.get('commissionBracket').setValue(val); }

	get commissionSummary(): string { return this.fg.get('commissionSummary').value; }
	set commissionSummary(val) { this.fg.get('commissionSummary').setValue(val); }

	get compSegment(): string { return this.fg.get('compSegment').value; }
	set compSegment(val) { this.fg.get('compSegment').setValue(val); }

	get customerTier(): string { return this.fg.get('customerTier').value; }
	set customerTier(val) { this.fg.get('customerTier').setValue(val); }

	get gpoAffiliation(): string { return this.fg.get('gpoAffiliation').value; }
	set gpoAffiliation(val) { this.fg.get('gpoAffiliation').setValue(val); }

	get gpoStatus(): string { return this.fg.get('gpoStatus').value; }
	set gpoStatus(val) { this.fg.get('gpoStatus').setValue(val); }

}
