export interface IEditSalesTerritoryTbl {
	salesTerritoryId: number;
	name?: string;
	region?: number[];
	manager?: string;
	salesRep1?: string;
	salesRep2?: string;
};

export class EditSalesTerritoryTbl implements IEditSalesTerritoryTbl {
	salesTerritoryId = 0;
	name = '';
	region = [];
	manager = '';
	salesRep1 = '';
	salesRep2 = '';
};
