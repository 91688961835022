import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { EditSalesTerritoryVm } from '../../../shared/generated/Sales/Models/EditSalesTerritoryVm'
import { Subscription, firstValueFrom } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';

@Component({
  selector: 'app-edit-territory',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './edit-territory.component.html',
  styleUrls: ['./edit-territory.component.css']
})
export class EditTerritoryComponent {
  formGroup = EditSalesTerritoryVm.Form;
  model = EditSalesTerritoryVm.GetModel(this.formGroup);
  id: number;
  subscriptions = new Subscription();
  constructor(title: Title, public httpClient: HttpClient, public route: ActivatedRoute, public nav: NavigationService, 
    private sec: SecurityService, public router: Router, private ms: SystemMessageService) {
    title.setTitle('Edit Sales Territory')
   }

  async ngOnInit(): Promise<void> {
    this.nav.setOpenSecondaryMenu(['Territories', 'Details'])
    this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
      this.id = Number(paramMap.get('id'));      
      const model = await firstValueFrom(this.httpClient.get<EditSalesTerritoryVm>(`api/Sales/SalesTerritory/GetViewModel/${this.id}`));
      this.formGroup.patchValue(model);
      this.formGroup.get("manager").disable();
        this.formGroup.get("salesRep1").disable();
        this.formGroup.get("salesRep2").disable();
    });
  }

  saveTerritory(msg: SystemMessage){
    if (!msg.isSuccessful) { return; }
		const model = this.formGroup.value;
		this.id = model.userId;
    if (msg.isSuccessful) {
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { this.router.navigate([`territories`]); });		
		}
  }

}
