<pcg-table-top 
	*ngIf="showTableTop"
	[table]="this"
	[canGlobalSearch]="canGlobalSearch"
	[pageSizeSource]="pageSizeSource"
	[filterSource]="filterSource"
	[pageLengths]="pageLengths"
	[exactMatchSource]="exactMatchSource"
	[columnDefs]="columnDefs"
	[filterId]="filterId"
	[filters]="(filterId ? ajaxData : null)"
	[ajaxData]="ajaxData"
	[canResetFilters]="canResetFilters"
	[filterMap]="filterMap"
	[canExportTableToExcel]="canExportTableToExcel"
	[canPrintTable]="canPrintTable"
	[hasTooltips]="hasTooltips"
	[tooltipContent]="tooltipContent"
></pcg-table-top>

<tr 
	class="search-row" 
	*matHeaderRowDef="getColHeaderDefs()" 
	mat-header-row
></tr>
<tr 
	class="footer-row" 
	*matFooterRowDef="getVisibleColDefs()" 
	mat-footer-row
></tr>

<!-- Mobile cards -->
<div *ngIf="isMobile && isUsingMobileCard">
	<div 
		*ngFor="let data of tableData?.data" 
		class="list-card" 
		(click)="redirectFunction(data)"
	>
		<div 
			class="unique-field" 
			[ngClass]="{'discrepancy-color' : data?.isDanger}"
		>
			<div class="alert-field">
				<h8 
					class="alert-bubble" 
					*ngIf="data?.isDanger"
				>DANGER</h8>
			</div>
			<div class="unique-item">
				<p>{{ columnDefs.get(uniqueColumn).displayName  }}</p>
				<h6>{{ data[uniqueColumn] }}</h6>
			</div>
			<div class="delete-field">
				<pcg-delete-button
					class="delete-btn"
					*ngIf="getVisibleColDefs().includes('canDelete') && (data.canDelete || isAdmin)"
					[table]="this"
					[confirmMessage]="confirmMessage"
					[url]="deleteUrl"
					[id]="data[identificationColumn]"
				></pcg-delete-button>
			</div>
		</div>
		<div class="list-field">
			<div 
				*ngFor="let row of getMobileColDefs()" 
				class="card-list-item"
			>
				<p>{{ columnDefs.get(row).displayName }}</p>
				<h6 *ngIf="!columnDefs.get(row).format">{{ data[row] }}</h6>
				<h6 *ngIf="columnDefs.get(row).format == 'shortDate'">{{ data[row] | date: columnDefs.get(row).format}}</h6>
				<h6 *ngIf="columnDefs.get(row).format == 'currency'">{{ data[row] | currency}}</h6>
				<h6 *ngIf="columnDefs.get(row).format == 'percent'">{{ data[row] | percent: '1.0-2'}}</h6>
				<h6 *ngIf="columnDefs.get(row).format == 'number'">{{ data[row] | number}}</h6>
			</div>
		</div>
	</div>
	<div 
		*ngIf="tableData?.data?.length === 0" 
		class="no-records"
	>No records to display</div>
</div>

<div
	[ngClass]="{'display-none' : isMobile && isUsingMobileCard}"
	role="region"
	aria-label="Table"
	#tableContainer
	[class.pcg-table-responsive]="isResponsive"
	[class.pcg-table-fixed-footer]="fixedFooter"
	[class.pcg-table-fixed-header]="fixedHeader"
	class="pcg-table-container mb-1"
>
	<!-- The table -->
	<table 
		[dataSource]="dataOnPageSource" 
		mat-table 
		class="data-grid" 
		[class]="'pcg-table-' + tableNum"
	>
		<!-- Displayed if no records are in the table -->
		<ng-container matColumnDef="noRecords">
			<td 
				colspan="999" 
				mat-cell 
				*matCellDef="let row"
			>
				<div *ngIf="hasCompletedServerRequest || !serverSide">No records to display</div>
				<div *ngIf="!hasCompletedServerRequest && serverSide">Loading...</div>
			</td>
		</ng-container>

		<!-- Optional per column search -->
		<ng-container 
			*ngFor="let col of getColDefs(); let i = index" 
			[matColumnDef]="col + '_search'"
		>
			<th [class]="getColSearchClasses(col)" mat-header-cell *matHeaderCellDef="let row">
				<input 
					*ngIf="canSearch(col) && isNdc(col)"
					[value]="perColumnSearchSource.value[i]"
					(keyup)="updatePerColumnSearch(i, $event.target.value)"
					[attr.aria-label]="'Search ' + columnDefs.get(col)?.displayName"
					placeholder="Search..."
					class="form-control form-control-sm hide-on-print"
					mask="00000-0000-00"
					type="search" 
				/>
				<input 
					*ngIf="canSearch(col) && !isNdc(col)"
					[value]="perColumnSearchSource.value[i]"
					(keyup)="updatePerColumnSearch(i, $event.target.value)"				
					[attr.aria-label]="'Search ' + columnDefs.get(col)?.displayName"
					placeholder="Search..."
					class="form-control form-control-sm hide-on-print"
					type="search" 
				/>
			</th>
		</ng-container>

		<!-- Header and Row Declarations -->
		<tr 
			mat-header-row 
			*matHeaderRowDef="getVisibleColDefs()"
		></tr>
		<tr 
			mat-row 
			*matRowDef="let row; when: hasData; columns: getVisibleColDefs()" 
			(click)="emitClick(row)" 
			[class]="isRowClickable === true ? 'clickable-row' : ''"
		></tr>
		<tr 
			mat-row 
			*matRowDef="let row; when: hasNoData; columns: ['noRecords']"
		></tr>
	</table>
</div>

<!-- Pagination footer -->
<div class="pagination-footer">
	<!-- Show rows dropdown -->
	<div class="show-dropdown-ctnr">
		<span class="hidden-sm mr-2">Show</span>
		<select 
			(change)="pageSizeSource.next($event.target.value)" 
			class="form-control form-control-sm mx-sm-1 show-dropdown"
		>
			<option
				aria-label="Table page length"
				[selected]="pageSizeSource.value === length"
				*ngFor="let length of pageLengths"
			>
				{{ length }}
			</option>
		</select>
	</div>
	<!-- Row count -->
	<div 
		*ngIf="showPagination" 
		class="w-100 mb-2 clearfix"
	>
		<div 
			role="status" 
			aria-live="polite" 
			class="filter-count-ctnr"
		>
			Showing
			{{ 
				filteredDataCount === 0 
					? 0 
					: ((currentPageSource.value - 1) * pageSizeSource.value + 1 | number) 
			}}
			to
			{{
				(filteredDataCount < currentPageSource.value * pageSizeSource.value
					? filteredDataCount
					: currentPageSource.value * pageSizeSource.value
				) | number
			}}
			of
			{{ filteredDataCount | number }}
			entries
			<span *ngIf="filteredDataCount !== totalDataCount">
				(filtered from {{ totalDataCount | number }} total entries)
			</span>
		</div>
		<!-- Page switcher -->
		<div class="page-switcher">
			<ngb-pagination 
				role="navigation"
				aria-label="Table paging"
				[collectionSize]="filteredDataCount"
				[pageSize]="pageSizeSource.value"
				[page]="currentPageSource.value"
				(pageChange)="currentPageSource.next($event)"
				[maxSize]="5"
				[rotate]="true"
			></ngb-pagination>
		</div>
	</div>
</div>
