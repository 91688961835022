import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../core/security/auth.guard'
import { PermissionConst } from '../core/security/generated/Permission';
import { SharedModule } from '../shared/shared.module';
import { CustomerListComponent } from './customer-list/customer-list.component'
import { CustomerEditComponent } from './customer-edit/customer-edit.component'
import { CustomerAliasListComponent } from './customer-alias-list/customer-alias-list.component';
import { SalesRepresentativesComponent } from '../sales/sales-representatives/sales-representatives.component';
import { AddSalesRepModalComponent } from './add-sales-rep-modal/add-sales-rep-modal.component';
import { CustomerImportExceptionsComponent } from './customer-import-exceptions/customer-import-exceptions.component';


/**
 * This is the list of routes in the release notes module.
 * Both this and the navigation need to be updated as
 * pages are added.
 */
const routes: Routes = [
	{
		path: 'master-customer-list'
		, component: CustomerListComponent
		, pathMatch: 'full'
		, canActivate:  [AuthGuard ]
		, canLoad: [ AuthGuard ]
		, data: { security: PermissionConst.adminFeatures }
	},
	{
		path: 'edit-master-customer/:id'
		, component: CustomerEditComponent
		, canActivate: [AuthGuard]
		, data: { security: PermissionConst.adminFeatures }
	},
	{
		path: 'master-customer-alias/:id'
		, component: CustomerAliasListComponent
		, canActivate: [AuthGuard]
		, data: { security: PermissionConst.adminFeatures }
	},
	{
		path: 'master-customer-import-exceptions'
		, component: CustomerImportExceptionsComponent
		, canActivate: [AuthGuard]
		, data: { security: PermissionConst.adminFeatures }
	},
];

@NgModule({
	declarations: [
		CustomerListComponent
		, CustomerEditComponent
		, CustomerAliasListComponent
		, CustomerImportExceptionsComponent
		, AddSalesRepModalComponent
	],
	imports: [ 
		RouterModule.forChild(routes)
		, CommonModule
		, SharedModule 
		, SalesRepresentativesComponent
	],
	exports: [ RouterModule, CustomerListComponent ]
})
export class CustomerModule { }
