import { Component, ViewChild } from '@angular/core';
import { CustomerImportExceptionVm } from '../../shared/generated/Customers/Models/CustomerImportExceptionVm'
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { SystemMessage, SystemMessageService } from '../../core/system-message/system-message-service';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
  selector: 'pcg-customer-import-exceptions',
  templateUrl: './customer-import-exceptions.component.html',
  styleUrl: './customer-import-exceptions.component.scss'
})
export class CustomerImportExceptionsComponent {
  @ViewChild(TableComponent, { static: true }) table: TableComponent<CustomerImportExceptionVm>;
  dataSource: string = "api/Customers/Customers/GetCustomerExceptionList";
  columns = CustomerImportExceptionVm.ColumnDefs;

  constructor(private title: Title, private httpClient: HttpClient, private systemMessageService: SystemMessageService) {
    title.setTitle("Customer Import Exceptions");
  }

  updateCustomer(row: CustomerImportExceptionVm) {
    this.httpClient.post<SystemMessage>('api/Customers/Customers/UpdateMasterCustomer', JSON.stringify(row)).subscribe((message: SystemMessage) => {
      this.systemMessageService.setSystemMessage(message.message ?? "", message.messageClass);
      this.table.ajaxReload();
    })
  }

  ignore(row: CustomerImportExceptionVm) {
    this.httpClient.post<SystemMessage>('api/Customers/Customers/IgnoreException', JSON.stringify(row)).subscribe((message: SystemMessage) => {
      this.systemMessageService.setSystemMessage(message.message ?? "", message.messageClass);
      this.table.ajaxReload();
    })
  }
}
