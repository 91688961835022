/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbDateParserFormatter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { CoreRouting } from './core.routing';
import { SharedModule } from '../shared/shared.module';
import { SystemMessageModule } from './system-message/system-message.module';
import { SystemMessageService } from './system-message/system-message-service';
import { LayoutModule } from './layout/layout.module';
import { HttpClientInterceptor } from './interceptors/http-client-interceptor';
import { SpinnerService } from './layout/spinner/spinner.service';
import { SecurityService } from './security/security.service';
import { NavigationService } from '../shared/navigation/navigation.service';
import { NgbDateNativeParserFormatter } from '../shared/form-elements/components/date-picker-parser/ngb-date-native-parser-formatter';
import { InactivityTimeoutComponent } from './inactivity-timeout/inactivity-timeout.component';
import { InactivityTimeoutService } from './inactivity-timeout/inactivity-timeout.service';
import { GetPasswordComponent } from './security/get-password/get-password.component';
import { FilterSaveService } from '../shared/table/filter-save.service';
import { PushNotificationModule } from './push-notification/push-notification.module';
import { ReleaseNotesComponent } from './pages/release-notes/release-notes.component';
import { CollapsibleTableService } from './collapsible-table/collapsible-table.service';

export function getToken() { return localStorage.getItem("jwt"); }

/***
 * Contains application-wide, single use components and services.
 * Imported once (in the AppModule) when the app starts and never imported anywhere else.
 */
@NgModule({
	declarations: [
		...CoreRouting.declarations
		, InactivityTimeoutComponent
		, GetPasswordComponent
		, ReleaseNotesComponent
	],
	imports: [
		...CoreRouting.imports
		, HttpClientModule
		, BrowserModule
		, SystemMessageModule
		, PushNotificationModule
		, LayoutModule
		, SharedModule
		, JwtModule.forRoot({
			config: {
				tokenGetter: getToken
				, allowedDomains: [window.location.host]
			}
		})
	],
	exports: [
		...CoreRouting.exports
		, SharedModule
		, LayoutModule
		, HttpClientModule
		, RouterModule
		, SystemMessageModule
		, PushNotificationModule
		, BrowserModule
	],
	providers: [
		...CoreRouting.providers
		, SystemMessageService
		, SpinnerService
		, SecurityService
		, InactivityTimeoutService
		, CollapsibleTableService
		, NavigationService
		, FilterSaveService
		, { 
			provide: NgbDateParserFormatter
			, useClass: NgbDateNativeParserFormatter 
		}
		, NgbDateNativeAdapter
		, { 
			provide: HTTP_INTERCEPTORS
			, useClass: HttpClientInterceptor
			, multi: true 
		}
	]
})
export class CoreModule {}
