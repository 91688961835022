export interface ICustomerListTbl {
	customerId: number;
	name?: string;
	shippingStreetAddress?: string;
	shippingCity?: string;
	shippingState?: string;
	shippingZipCode?: string;
	billingStreetAddress?: string;
	billingCity?: string;
	billingState?: string;
	billingZipCode?: string;
	email?: string;
	purchaseOrder?: string;
	invoiceNumber?: string;
	region?: string;
	canDelete: boolean;
};

export class CustomerListTbl implements ICustomerListTbl {
	customerId = 0;
	name = '';
	shippingStreetAddress = '';
	shippingCity = '';
	shippingState = '';
	shippingZipCode = '';
	billingStreetAddress = '';
	billingCity = '';
	billingState = '';
	billingZipCode = '';
	email = '';
	purchaseOrder = '';
	invoiceNumber = '';
	region = '';
	canDelete = false;
};
