import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatTableModule } from "@angular/material/table";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list'; 
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbDatepickerModule, NgbTooltipModule, NgbModalModule, NgbTypeaheadModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatExpansionModule } from '@angular/material/expansion';

import { AuthGuard } from 'app/core/security/auth.guard';
import { PermissionConst } from 'app/core/security/generated/Permission';
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { FormElementsModule } from './form-elements/form-elements.module';
import { NavigationModule } from './navigation/navigation.module';
import { SafePipe } from './pipes/safe.pipe';
import { StepperComponent } from './stepper/stepper.component';
import { TableModule } from './table/table.module';
import { DowntimeNotificationComponent } from './downtime-notification/downtime-notification.component';

const routes = [{
	path: 'audit-history/:id',
	component: AuditHistoryComponent,
	canActivate: [AuthGuard],
	canLoad: [AuthGuard],
	data: { security: PermissionConst.coreFeatures }
}];

/**
 * SharedModule exists to make commonly used components, directives,
 * and pipes available for use in the templates of components throughout
 * the application.
 */
@NgModule({
	declarations: [
		StepperComponent
		, SafePipe
		, AuditHistoryComponent
		, DowntimeNotificationComponent
	],
	imports: [
		CommonModule
		, FormElementsModule
		, NgSelectModule
		, MatTableModule
		, MatButtonToggleModule
		, MatSidenavModule
		, MatListModule
		, CdkStepperModule
		, NgbTooltipModule
		, NgbDatepickerModule
		, NgbModalModule
		, NgbTypeaheadModule
		, NgbNavModule
		, FormsModule
		, NavigationModule
		, TableModule
		, ReactiveFormsModule
		, AngularEditorModule
		, FontAwesomeModule
		, MatExpansionModule
		, RouterModule.forChild(routes)
	],
	exports: [
		CommonModule
		, FormElementsModule
		, NgSelectModule
		, MatTableModule
		, MatButtonToggleModule
		, MatSidenavModule
		, MatListModule
		, NgbTooltipModule
		, NgbDatepickerModule
		, NgbModalModule
		, NgbTypeaheadModule
		, NgbNavModule
		, FormsModule
		, NavigationModule
		, TableModule
		, ReactiveFormsModule
		, AngularEditorModule
		, CdkStepperModule
		, StepperComponent
		, SafePipe
		, AuditHistoryComponent
		, FontAwesomeModule
		, MatExpansionModule
		, DowntimeNotificationComponent
	]
})
export class SharedModule {}
