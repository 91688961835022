import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[pcgAutofocus]'
})
export class AutofocusDirective  implements AfterViewInit {

    constructor(private ref: ElementRef) {}

    ngAfterViewInit() {
        //need to set timeout because of ngb nav tabs
        setTimeout(() => { this.ref.nativeElement.focus(); }, 500)
    }
}
