import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const getSalesRepNav: () => NavRoute = () => ({
    name: 'Sales Representatives',
    children: getSalesRepAreas()
});

function getSalesRepAreas(): NavRoute[] {
    var id: string = "";
    var url = window.location.href;

    if (url.indexOf('sales-representatives/edit-sales-representative/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

    //Sales Rep List
    if (id === "") {
        return [
            {
                name: 'Sales Representatives',
                exact: true,
                path: 'sales-representatives'
            },
        ]
    }

    // Creating a new Sales Rep or details
    else {
        return [
            {
                name: 'Back'
                , exact: true
                , path: 'sales-representatives'
            },
            {
                name: 'Details'
                , exact: true
                , path: `sales-representatives/edit-sales-representative/${id}`
            }
        ];
    }
}