import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditUserVm {
	userId: number;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	isActive: boolean;
	isActiveStr?: string;
	userName?: string;
	permissionId?: number;
	permission?: string;
	isLockedOut: boolean;
	isLockedOutStr?: string;
	inactivityTimeout: boolean;
	isUpdatedOffline: boolean;
	canBeDeleted: boolean;
	friendlyId?: string;
	isNewUser: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userId': new UntypedFormControl(0, [...(validators['userId'] ?? []), ...[Validators.required]]),
			'fullName': new UntypedFormControl(null, [...(validators['fullName'] ?? []), ...[Validators.required, Validators.minLength(2), Validators.maxLength(100), Validators.pattern("^[a-zA-Z-]+( [a-zA-Z-0]+)*$")]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[Validators.required, Validators.email]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'isActiveStr': new UntypedFormControl(null, [...(validators['isActiveStr'] ?? []), ...[]]),
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[Validators.required, Validators.minLength(5), Validators.maxLength(256), Validators.pattern("^[A-Za-z0-9.@-_']+$")]]),
			'permissionId': new UntypedFormControl(null, [...(validators['permissionId'] ?? []), ...[Validators.required]]),
			'permission': new UntypedFormControl(null, [...(validators['permission'] ?? []), ...[]]),
			'isLockedOut': new UntypedFormControl(false, [...(validators['isLockedOut'] ?? []), ...[]]),
			'isLockedOutStr': new UntypedFormControl(null, [...(validators['isLockedOutStr'] ?? []), ...[]]),
			'inactivityTimeout': new UntypedFormControl(false, [...(validators['inactivityTimeout'] ?? []), ...[]]),
			'isUpdatedOffline': new UntypedFormControl(false, [...(validators['isUpdatedOffline'] ?? []), ...[]]),
			'canBeDeleted': new UntypedFormControl(false, [...(validators['canBeDeleted'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'isNewUser': new UntypedFormControl(false, [...(validators['isNewUser'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['fullName', {"orderId":0,"sortColumn":"fullName","searchColumn":"fullName","includeInExcel":true,"exportColumn":null,"displayName":"Full Name","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['email', {"orderId":1,"sortColumn":"email","searchColumn":"email","includeInExcel":true,"exportColumn":null,"displayName":"Email","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['phoneNumber', {"orderId":2,"sortColumn":"phoneNumber","searchColumn":"phoneNumber","includeInExcel":true,"exportColumn":null,"displayName":"Phone Number","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['isActiveStr', {"orderId":3,"sortColumn":"isActiveStr","searchColumn":"isActiveStr","includeInExcel":true,"exportColumn":null,"displayName":"Is Active?","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): EditUserVmFromFg {
		return new EditUserVmFromFg(fg);
	}
}

export class EditUserVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get userId(): number { return this.fg.get('userId').value; }
	set userId(val) { this.fg.get('userId').setValue(val); }

	get fullName(): string { return this.fg.get('fullName').value; }
	set fullName(val) { this.fg.get('fullName').setValue(val); }

	get email(): string { return this.fg.get('email').value; }
	set email(val) { this.fg.get('email').setValue(val); }

	get phoneNumber(): string { return this.fg.get('phoneNumber').value; }
	set phoneNumber(val) { this.fg.get('phoneNumber').setValue(val); }

	get isActive(): boolean { return this.fg.get('isActive').value; }
	set isActive(val) { this.fg.get('isActive').setValue(val); }

	get isActiveStr(): string { return this.fg.get('isActiveStr').value; }
	set isActiveStr(val) { this.fg.get('isActiveStr').setValue(val); }

	get userName(): string { return this.fg.get('userName').value; }
	set userName(val) { this.fg.get('userName').setValue(val); }

	get permissionId(): number { return this.fg.get('permissionId').value; }
	set permissionId(val) { this.fg.get('permissionId').setValue(val); }

	get permission(): string { return this.fg.get('permission').value; }
	set permission(val) { this.fg.get('permission').setValue(val); }

	get isLockedOut(): boolean { return this.fg.get('isLockedOut').value; }
	set isLockedOut(val) { this.fg.get('isLockedOut').setValue(val); }

	get isLockedOutStr(): string { return this.fg.get('isLockedOutStr').value; }
	set isLockedOutStr(val) { this.fg.get('isLockedOutStr').setValue(val); }

	get inactivityTimeout(): boolean { return this.fg.get('inactivityTimeout').value; }
	set inactivityTimeout(val) { this.fg.get('inactivityTimeout').setValue(val); }

	get isUpdatedOffline(): boolean { return this.fg.get('isUpdatedOffline').value; }
	set isUpdatedOffline(val) { this.fg.get('isUpdatedOffline').setValue(val); }

	get canBeDeleted(): boolean { return this.fg.get('canBeDeleted').value; }
	set canBeDeleted(val) { this.fg.get('canBeDeleted').setValue(val); }

	get friendlyId(): string { return this.fg.get('friendlyId').value; }
	set friendlyId(val) { this.fg.get('friendlyId').setValue(val); }

	get isNewUser(): boolean { return this.fg.get('isNewUser').value; }
	set isNewUser(val) { this.fg.get('isNewUser').setValue(val); }

}
