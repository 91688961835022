import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EditUserService {

    constructor(private httpClient: HttpClient) {}

	getPermissions(method) { return this.httpClient.get('api/Administration/Permission/GetPermissionVms').subscribe(method); }
}
