export interface IReleaseNoteTbl {
	releaseNoteId: number;
	goLiveDateString?: string;
	goLiveDate: Date;
	versionNumber?: string;
	releaseNotesDescription?: string;
	modifiedById?: number;
	modifiedByName?: string;
	modifiedDateString?: string;
	modifiedDate?: Date;
	canDelete: boolean;
};

export class ReleaseNoteTbl implements IReleaseNoteTbl {
	releaseNoteId = 0;
	goLiveDateString = '';
	goLiveDate = new Date();
	versionNumber = '';
	releaseNotesDescription = '';
	modifiedById = 0;
	modifiedByName = '';
	modifiedDateString = '';
	modifiedDate = new Date();
	canDelete = false;
};
