import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const getTerritoriesNav: () => NavRoute = () => ({
    name: 'Territories',
    children: getTerritoriesAreas()
});

function getTerritoriesAreas(): NavRoute[] {

    var id: string = "";
    var url = window.location.href;
    if (url.indexOf('territories/edit-territory/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

    //Territories List
    if (id === "") {
        return [
            {
                name: 'Territories',
                exact: true,
                path: 'territories'
            },
        ]
    }

    //Creating a Territory or details
    else {
        return [
            {
                name: 'Back'
                , exact: true
                , path: 'territories'
            },
            {
                name: 'Details'
                , exact: true
                , path: `territories/edit-territory/${id}`
            }
        ];
    }

}
