/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit, ElementRef, OnDestroy, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { NavRoute } from '../nav.route.interface';
import { NavigationService, NavDividerCharacter } from '../navigation.service';
import { SecurityService } from 'app/core/security/security.service';
import { SearchNavComponent } from '../search-nav/search-nav.component';
import { UserJsVm } from 'app/shared/generated/Administration/Models/User/JavaScript/UserJsVm';

/**
 * This component is intended to be used in the top header bar to create a nested navigation. Dropdowns are opened by click.
 */
@Component({
	selector: 'pcg-top-click-nav',
	templateUrl: './top-click-nav.component.html',
	styleUrls: ['./top-click-nav.component.scss']
})
export class TopClickNavComponent implements OnInit, OnDestroy, OnChanges {

	@Input() navRoutes: NavRoute[] = [];
	@Input() menuItemId = 'root';
	@Input() isRoot = true;

	public getScreenWidth: any;
  	public getScreenHeight: any;

	subscriptions: Subscription = new Subscription();
	user: UserJsVm;
	
	isOpen: boolean;
	currNavMenuItem: string;
	currSelectedNavMenuItem: string;
	currOpenSecondaryNavMenu: string;
	currForceSelect: string;
	ulClasses: string[];
	loopNavRoutes: NavRoute[] = [];
	userNavOpen = false;

	constructor(
		public navService: NavigationService
		, public elRef: ElementRef
		, public sec: SecurityService
		, private router: Router
		, private modalService: NgbModal
	) {}

	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.getScreenWidth = window.innerWidth;
		this.getScreenHeight = window.innerHeight;
	}

	ngOnInit() {
		this.getScreenWidth = window.innerWidth;
      	this.getScreenHeight = window.innerHeight;

		// Get the current user information
		this.subscriptions.add(this.sec.user$.subscribe(user => { this.user = user; }));
		// Triggred on update of currently OPEN menu item
		this.navService.currOpenPrimaryNavMenu$.subscribe(currNavMenuItem => {
			this.currNavMenuItem = currNavMenuItem;
			this.isOpen = currNavMenuItem !== '' && currNavMenuItem.startsWith(this.menuItemId);
			this.userNavOpen = currNavMenuItem === ['root', 'user'].join(NavDividerCharacter);
			// The first ul should have classes indicating it is the root.
			// Nested ul elements, those at higher depths, are dropdown menus
			// and need classes indicating that.
			this.ulClasses = this.isRoot
				? ['nav', 'navbar-nav', 'top-nav-bg', this.isOpen ? 'nav-open' : '']
				: ['dropdown-menu', 'top-nav-bg', this.isOpen ? 'nav-open' : ''];
		});
		// Triggered on update of currently SELECTED menu item
		this.navService.currSelectedMainNavMenu$.subscribe(currSelectedNavMenu => {
			this.currSelectedNavMenuItem = currSelectedNavMenu ? currSelectedNavMenu.id : 'root';
		});
		this.navService.currOpenSecondaryNavMenu$.subscribe(currSelectedNavMenu => {
			this.currOpenSecondaryNavMenu = currSelectedNavMenu ? currSelectedNavMenu.id : 'root';
		});
		this.navService.currForceSelect$.subscribe(currForceSelect => {
			this.currForceSelect = currForceSelect;
			this.loopNavRoutes = this.getNavRoutes();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		const chng = changes['navRoutes'];
		const cur = chng.currentValue;
		const prev = chng.previousValue;
		if (cur !== prev) { this.loopNavRoutes = this.getNavRoutes(); }
	}

	/**
	 * Creates an HTML friendly id from a route identifier
	 *
	 * @param routeId Unique identifier for the route
	 */
	getHtmlId(routeId: string) { return routeId.replace(/^[^a-z]+|[^\w:.-]+/gi, ''); }

	// Show or hide submenu based on dropdown click
	clickDropdown(childMenuId: string) {
		if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
		// If the submenu is open, close it
		if (this.currNavMenuItem.startsWith(childMenuId)) { this.navService.setCurrOpenNavMenu(this.menuItemId); }
		else { this.navService.setCurrOpenNavMenu(childMenuId); }
		return false;
	}

	openDropdown(childMenuId: string) {
		if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
		this.navService.setCurrOpenNavMenu(childMenuId);
		return false;
	}

	pointerClickDropdown(childMenuId: string, routeHref: string = "") {
		if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
		this.navService.setCurrOpenNavMenu(childMenuId);
		if (routeHref !== '' && this.getScreenWidth > 992) { window.location.href = routeHref; }
		return false;
	}

	closeDropdown(childMenuId: string) {
		if (this.getScreenWidth > 992) { 
			if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
			this.navService.setCurrOpenNavMenu(this.menuItemId); 		
		}		
		return false;
	}

	clickLink() { this.navService.setCurrOpenNavMenu(''); }

	// Open the global navigation search
	openSearch() {
		this.navService.setCurrOpenNavMenu('');
		this.modalService.open(SearchNavComponent);
		return false;
	}

	// Logout removes all of our security variables and redirects user to root
	logout() {
		this.sec.setSecurity(null, null, null);
		this.deleteAllCookies();
		this.router.navigate(['/']);
	}

	// This is used above to delete all cookies on logout
	deleteAllCookies() {
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}
	}

	// It's good practice to always unsubscribe from subscriptions
	ngOnDestroy() {	this.subscriptions.unsubscribe(); }

	getNavRoutes() {
		const routeIndex = this.navService.getCurrRouteIndex(this.navRoutes);
		return (
			this.navRoutes
				?.map((o, i) => ({ ...o, index: i }))
				.filter(route => !route?.shouldDisplay || route?.shouldDisplay(routeIndex)) ?? []
		);
	}
}
