export interface IExceptionListTbl {
	batchNumber: number;
	fileSource?: string;
	fileSourceId: number;
	importDate: Date;
	importedCustomer?: string;
	name?: string;
	streetAddress?: string;
	city?: string;
	state?: string;
	zipCode?: string;
	customerId?: number;
	makeMaster: boolean;
};

export class ExceptionListTbl implements IExceptionListTbl {
	batchNumber = 0;
	fileSource = '';
	fileSourceId = 0;
	importDate = new Date();
	importedCustomer = '';
	name = '';
	streetAddress = '';
	city = '';
	state = '';
	zipCode = '';
	customerId = 0;
	makeMaster = false;
};
