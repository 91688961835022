<div id="addContent" class="hide-on-print">
	<div id="actionElements">
		<ng-container *ngIf="salesRepId == null">
			<a (click)="openImportModal()" class="btn btn-save btn-sm inline-add-btn mt-2">Master Customer Import</a>
			<a routerLink="/master-customers/edit-master-customer/0" class="btn btn-save btn-sm inline-add-btn mt-2">Add Master Customer</a>
			<a *ngIf="!cusomerListApprovalModel?.isCustomerListApproved" (click)="approveCusomerList()" class="btn btn-save btn-sm inline-add-btn mt-2">Approve Master Customer List</a>
			<span *ngIf="cusomerListApprovalModel?.isCustomerListApproved">
				<b>
					Approved By: {{cusomerListApprovalModel?.approvedByUser}} on {{cusomerListApprovalModel?.approvalDate | date:'shortDate'}}
				</b>
			</span>
		</ng-container>
		<a *ngIf="salesRepId != null" (click)="openAddCustomerModal()" class="btn btn-save btn-sm inline-add-btn mt-2">Add Master Customer to Sales Rep</a>
	</div>
</div>
<pcg-table #table [dataSource]="dataSource" [pcgSort]="[['name', 'asc']]" [columnDefs]="columns"
	[canResetFilters]="true" [isRowClickable]="true" (clickRow)="redirectCustomer($event)" isUsingMobileCard="isUsingMobileCards"
	uniqueColumn="name" [redirectFunction]="redirectCustomer" [canExportTableToExcel]="true"
	[deleteUrl]='deleteUrl' identificationColumn='customerId'
	confirmMessage="Are you sure you want to delete this customer?">

	<ng-container matColumnDef="name">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>
			{{ columns.get('name').displayName }}
		</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('name').displayName" style="white-space: nowrap;">
			<pcg-edit-link [url]="'/master-customers/edit-master-customer/' + row.customerId">
				{{ row.name }}
			</pcg-edit-link>
		</td>
	</ng-container>

	<ng-container matColumnDef="shippingStreetAddress">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('shippingStreetAddress').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('shippingStreetAddress').displayName" style="white-space: nowrap;">
			{{ row.shippingStreetAddress }}</td>
	</ng-container>

	<ng-container matColumnDef="shippingCity">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('shippingCity').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('shippingCity').displayName">{{ row.shippingCity }}</td>
	</ng-container>

	<ng-container matColumnDef="shippingState">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('shippingState').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('shippingState').displayName">{{ row.shippingState }}</td>
	</ng-container>

	<ng-container matColumnDef="shippingZipCode">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('shippingZipCode').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('shippingZipCode').displayName">{{ row.shippingZipCode }}</td>
	</ng-container>	

	<ng-container matColumnDef="email">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('email').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('email').displayName">{{ row.email }}</td>
	</ng-container>

	<ng-container matColumnDef="billingStreetAddress">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('billingStreetAddress').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('billingStreetAddress').displayName">
			{{ row.billingStreetAddress }}</td>
	</ng-container>

	<ng-container matColumnDef="billingCity">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('billingCity').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('billingCity').displayName">{{ row.billingCity }}</td>
	</ng-container>

	<ng-container matColumnDef="billingState">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('billingState').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('billingState').displayName">{{ row.billingState }}</td>
	</ng-container>

	<ng-container matColumnDef="billingZipCode">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('billingZipCode').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('billingZipCode').displayName">{{ row.billingZipCode }}</td>
	</ng-container>
	
	<ng-container matColumnDef="purchaseOrder">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('purchaseOrder').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('purchaseOrder').displayName">{{
			row.purchaseOrder }}</td>
	</ng-container>

	<ng-container matColumnDef="invoiceNumber">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('invoiceNumber').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('invoiceNumber').displayName">{{
			row.invoiceNumber }}</td>
	</ng-container>

	<ng-container matColumnDef="region">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('region').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('region').displayName">{{ row.region }}</td>
	</ng-container>

	<ng-container matColumnDef="canDelete">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('canDelete').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('canDelete').displayName">
			<pcg-delete-button [table]='table' [confirmMessage]='"Are you sure you want to delete this customer?"'
				[url]='deleteUrl'[id]='salesRepId == null ? row.customerId : { salesRepId: this.salesRepId, customerId: row.customerId }'>
			</pcg-delete-button>
		</td>
	</ng-container>
</pcg-table>

<ng-template #importCustomers let-modal>
	<form 
		[formGroup]="formGroup" 
		(submit)="isImporting=true"
		pcgSubmitUrl="api/Customers/Customers/UploadCustomers" 
		(messageSuccess)="modal.dismiss(); this.table.ajaxReload(); cusomerListApprovalModel.isCustomerListApproved = false;"
	>
		<div class="modal-header">
			<h5 class="modal-title" id="modal-basic-title">Master Customer Import</h5>
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>

		<div class="modal-body">
			<div class="row">
				<pcg-control-group class="col-md-12" label="Import File">
					<pcg-file-upload 
						pcgControlRef 
						formControlName="file" 
						type="file"
					></pcg-file-upload>
				</pcg-control-group>
			</div>
			<a href="../../../assets/ExampleImports/Customer.xlsx">Example Import File</a>
		</div>

		<div class="modal-footer">
			<button type="button" class="btn btn-cancel btn-lg" (click)="modal.dismiss()">Cancel</button>
			<button type="submit" class="btn btn-save btn-lg">Import File</button>
		</div>
	</form>
</ng-template>