import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CustomerEditVm } from '../../shared/generated/Customers/Models/CustomerEditVm'
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { SystemMessage } from 'app/core/system-message/system-message-service';
import { CustomerAliasListVm } from '../../shared/generated/Customers/Models/CustomerAliasListVm'
import { TableComponent } from 'app/shared/table/table.component';


@Component({
  selector: 'pcg-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrl: './customer-edit.component.scss',
  
})
export class CustomerEditComponent {
  panelOpenState: boolean;
  columns = CustomerAliasListVm.ColumnDefs;
  aliasDataSource: string = "api/Customers/Customers/GetCustomerAliasList/";
  formGroup = CustomerEditVm.Form;
  model = CustomerEditVm.GetModel(this.formGroup);
  id: number;
  constructor(title: Title, public httpClient: HttpClient, public route: ActivatedRoute, public nav: NavigationService, public router: Router,) {
    title.setTitle('Edit Customer')
  }

  async ngOnInit(): Promise<void> {        
    this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
      this.id = Number(paramMap.get('id'));
      this.aliasDataSource += (this.id ?? '');
      this.formGroup.patchValue(await firstValueFrom(this.httpClient.get<CustomerEditVm>(`api/Customers/Customers/GetViewModel/${this.id}`)));
      this.panelOpenState = this.formGroup.get('hasAliases').value
      if (this.id === 0) {
        this.formGroup.get("region").setValue(null);
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
      }
    });
  }
  saveCustomer(msg: SystemMessage) {
    if (!msg.isSuccessful) { return; }
    const model = this.formGroup.value;
    this.id = model.userId;
    if (msg.isSuccessful) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { this.router.navigate([`master-customers/master-customer-list`]); });
    }
  }
}
