import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class ImportHistoryListVm {
	importHistoryId: number;
	fileName?: string;
	importDate: Date;
	fileSource?: string;
	importedBy?: string;
	recordsImported: number;
	exceptionCount: number;
	canDelete: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'importHistoryId': new UntypedFormControl(0, [...(validators['importHistoryId'] ?? []), ...[Validators.required]]),
			'fileName': new UntypedFormControl(null, [...(validators['fileName'] ?? []), ...[]]),
			'importDate': new UntypedFormControl('', [...(validators['importDate'] ?? []), ...[Validators.required]]),
			'fileSource': new UntypedFormControl(null, [...(validators['fileSource'] ?? []), ...[]]),
			'importedBy': new UntypedFormControl(null, [...(validators['importedBy'] ?? []), ...[]]),
			'recordsImported': new UntypedFormControl(0, [...(validators['recordsImported'] ?? []), ...[Validators.required]]),
			'exceptionCount': new UntypedFormControl(0, [...(validators['exceptionCount'] ?? []), ...[Validators.required]]),
			'canDelete': new UntypedFormControl(false, [...(validators['canDelete'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['importHistoryId', {"orderId":0,"sortColumn":"importHistoryId","searchColumn":"importHistoryId","includeInExcel":true,"exportColumn":null,"displayName":"Batch Number","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['fileName', {"orderId":1,"sortColumn":"fileName","searchColumn":"fileName","includeInExcel":true,"exportColumn":null,"displayName":"File Name","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['importDate', {"orderId":2,"sortColumn":"importDate","searchColumn":"importDate","includeInExcel":true,"exportColumn":null,"displayName":"Import Date","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"shortDate"}],
			['fileSource', {"orderId":3,"sortColumn":"fileSource","searchColumn":"fileSource","includeInExcel":true,"exportColumn":null,"displayName":"File Source","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['importedBy', {"orderId":4,"sortColumn":"importedBy","searchColumn":"importedBy","includeInExcel":true,"exportColumn":null,"displayName":"Imported By","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['recordsImported', {"orderId":5,"sortColumn":"recordsImported","searchColumn":"recordsImported","includeInExcel":true,"exportColumn":null,"displayName":"Records Imported","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"number"}],
			['exceptionCount', {"orderId":6,"sortColumn":"exceptionCount","searchColumn":"exceptionCount","includeInExcel":true,"exportColumn":null,"displayName":"Exceptions","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"number"}],
			['canDelete', {"orderId":7,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":false,"exportColumn":null,"displayName":"Delete","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): ImportHistoryListVmFromFg {
		return new ImportHistoryListVmFromFg(fg);
	}
}

export class ImportHistoryListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get importHistoryId(): number { return this.fg.get('importHistoryId').value; }
	set importHistoryId(val) { this.fg.get('importHistoryId').setValue(val); }

	get fileName(): string { return this.fg.get('fileName').value; }
	set fileName(val) { this.fg.get('fileName').setValue(val); }

	get importDate(): Date { return this.fg.get('importDate').value; }
	set importDate(val) { this.fg.get('importDate').setValue(val); }

	get fileSource(): string { return this.fg.get('fileSource').value; }
	set fileSource(val) { this.fg.get('fileSource').setValue(val); }

	get importedBy(): string { return this.fg.get('importedBy').value; }
	set importedBy(val) { this.fg.get('importedBy').setValue(val); }

	get recordsImported(): number { return this.fg.get('recordsImported').value; }
	set recordsImported(val) { this.fg.get('recordsImported').setValue(val); }

	get exceptionCount(): number { return this.fg.get('exceptionCount').value; }
	set exceptionCount(val) { this.fg.get('exceptionCount').setValue(val); }

	get canDelete(): boolean { return this.fg.get('canDelete').value; }
	set canDelete(val) { this.fg.get('canDelete').setValue(val); }

}
