<span
	[ngbTooltip]="expandOnClick ? 'Click the &quot;...&quot; to expand the field.' : undefined"
	class="pointer"
	*ngIf="!hasClicked"
	(click)="onClick()"
>
	{{ lessData }}...
</span>
<span *ngIf="hasClicked">
	{{ data }}
</span>
