/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { faAngleDown, faUser, faGear, faQuestionCircle, faUsers, faPeopleRoof, faMoneyBill, faPeopleLine, faMap, faArrowProgress } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, filter } from 'rxjs';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import Rollbar, { Payload } from 'rollbar';
import { RollbarService } from '../rollbar_config';

import { GlobalService } from 'app/shared/services/global.service';
import { InactivityTimeoutService } from './core/inactivity-timeout/inactivity-timeout.service';
import { SecurityService } from './core/security/security.service';
import { UserJsVm } from './shared/generated/Administration/Models/User/JavaScript/UserJsVm';
import { pcgSettings } from './shared/generated/pcg-settings';
import { NavigationService } from './shared/navigation/navigation.service';
import { AppDatabase } from 'local-db/services/init.ldb.service';
import { HttpClient } from '@angular/common/http';
import { SetLocaldbTablesService } from '../app/services/service-workers/set-localdb-tables.service'
import { GlobalVariablesService } from './services/global-variables.service'
import { CheckNetworkService } from 'local-db/services/check-network.service';

/*
 * This is the application's root component.
*/
@Component({
	selector: 'pcg-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [ GlobalVariablesService ]
})
export class AppComponent implements OnInit {

	@ViewChild('tabPanel') tabPanel: any;

	updateCheckText = '';
	errorCache: any = {};

	// The following are error codes thrown as a result of system security
	// and should not be logged to rollbar.
	rollbarIgnoredCodes: number[] = [
		0, 		// Network Error / Aborted Request
		401, 	// Login redirect
		403, 	// Access denied
		467,	// 2fa not enabled
		499,	// First time Inventory Setup custom redirect
		503, 	// Service Unavailable
		504 	// Gateway timeout
	];

	isMobile: boolean;
	@HostListener('window:resize')
	onResize() { 
		this.isMobile = GlobalService.setIsMobile(window.innerWidth); 
		if (this.isMobile) {
			localStorage.setItem("isNavFixed", "false"); 
			this.isNavFixed = false;
			this.navOpened = false;
		}
	}

	@HostListener("window:online", ["$event"])
	onlineEvent(event) { this.uploadUsersFromLocalDb(event); }

	// Nav
	navOpened: boolean;
	isNavFixed: boolean;
	isLoggedOn: boolean;
	mode = pcgSettings.mode; //for responsive BETA text

	// My Profile
	user: UserJsVm;
	subscriptions: Subscription = new Subscription();

	// Fa icons
	faIconName = { faAngleDown, faUser, faGear, faQuestionCircle, faUsers, faPeopleRoof, faMoneyBill, faPeopleLine, faMap, faArrowProgress };

	// For nav selected items
	workflowSelected: boolean;
	usersSelected: boolean;
	adminSelected: boolean;
	helpSelected: boolean;
	userProfileSelected: boolean;
	salesRepSelected: boolean;
	territoriesSelected: boolean;

	precacheCached = false;
	isOnline: boolean;

	// Inactivity timeout needs to be injected here to have a constant timeout going in the background
	constructor(
		private swUpdate: SwUpdate
		, inactiveTimeout: InactivityTimeoutService
		, public router: Router
		, private sec: SecurityService
		, private modalConfig: NgbModalConfig
		, private elRef: ElementRef
		, private modalService: NgbModal
		, private navService: NavigationService
		, private localDb: AppDatabase
		, private http: HttpClient
		, private localDbService: SetLocaldbTablesService
		, @Inject(RollbarService) private rollbar: Rollbar
	) {
		this.updateCheck();
		this.configureRollbar(); // Set global rollbar settings
		this.configureModal(); // Set default modal settings
		this.setIsLoggedInHtmlClass(); // Handle is-logged-in HTML class
		// Dirty AngularJS style global router used in index.html
		window['$router'] = router;
	}

	ngOnInit() {
		
		this.isOnline = CheckNetworkService.checkNetworkStatus();
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		this.isNavFixed = this.setIsNavFixed();
		this.navOpened = this.isNavFixed;
		this.subscriptions.add(this.sec.user$.subscribe(user => { this.user = user; }));	// For 'My Profile' nav functionality
		this.subscriptions.add(this.router.events.subscribe(() => { this.parseUrl(this.router.url); })); 
		if (!this.isOnline) {
			// get data from localDb if offline and there is something in the localDb
			this.localDb.table("EditUserTbl").toArray()
				.then((response) => { 
					if (response.length > 0) {
						let filteredResponse = response.filter(o => o.userId === this.user.id && this.user.id !== 0);
						if (filteredResponse.length > 0) { 
							this.user.fullName = filteredResponse[filteredResponse.length - 1].fullName;
					}
				}});
		}
	}
	ngDoCheck() { 
		this.isLoggedOn = this.sec.isLoggedOn(); 
	}

	updateCheck(): void {
		this.swUpdate.versionUpdates
        	.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        	.subscribe(evt => document.location.reload())				
	}

	/** Set default ng-bootstrap modal options */
	configureModal() {
		// Disable ng bootstrap modal animations by default
		this.modalConfig.animation = false;
		this.modalConfig.ariaLabelledBy = 'modal-basic-title';
	}

	/** Configure rollbar throughout the website */
	configureRollbar() {
		this.rollbar.configure({
			// Set initial user on load (updated in subscription below)
			payload: this.getRollbarPayload(this.sec.getUser()),
			checkIgnore: (uncaught, args, item) => {
				// Prevent duplicate message/URL combinations for the session
				// Don't want to flood rollbar with errors if Angular starts looping
				// an error message.
				const theItem = item as any;
				const errorKey =
					(theItem?.body?.message?.body ?? '') +
					(theItem?.body?.message?.extra?.url?.split('?')[0] ?? '') +
					window.location.href;
				// Do not report these errors as they are results of intented system behavior.
				if (this.errorCache[errorKey] || this.rollbarIgnoredCodes.includes(theItem?.body?.message?.extra?.status)) { return true; }

				this.errorCache[errorKey] = true;

				// Prevent errors in local development
				if (pcgSettings.mode === 'Development') { return true; }

				return false;
			},
		});

		// Update user if it changes
		this.sec.user$.subscribe(newUser => { this.rollbar.configure({ payload: this.getRollbarPayload(newUser) }); });
	}

	/**
	 * Creates a rollbar payload with user added
	 * 
	 * @param user The user to add
	 */
	getRollbarPayload(user: UserJsVm): Payload {
		return {
			environment: pcgSettings.mode,
			person: !user
				? undefined
				: {
						id: user.id.toString(),
						username: user.userName,
						email: user.email,
				  },
			client: {
				javascript: {
					source_map_enabled: true,
					code_version: '730c06b',
					guess_uncaught_frames: true
				}
			}			
		};
	}

	/** Sets the is-logged-in HTML class, which is used to hide the release notes links */
	setIsLoggedInHtmlClass() {
		// Add / remove is-logged-in class
		this.sec.user$.subscribe(user => {
			const isLoggedIn = user ? true : false;
			if (isLoggedIn) { this.elRef.nativeElement.classList.add('is-logged-in'); } 
			else { this.elRef.nativeElement.classList.remove('is-logged-in'); }
		});
	}

	setNavOpened(event: boolean) { 
		this.navOpened = event; 
		this.navService.navOpened.next(this.navOpened); 
	}

	onNavOpenedChange(val: boolean) { 
		this.navOpened = val;
		this.navService.navOpened.next(val); 
	}

	uploadUsersFromLocalDb(event) { // ran when user comes back online
		if (event.type == "online") {
			this.localDb.table("EditUserTbl").toArray()
				.then((response) => { 
					let filteredResponse = response.filter(o => o.isUpdatedOffline);
					if (filteredResponse.length > 0) { // means there are entries that have been updated
						for (let x = 0; x < filteredResponse.length; x++) {
							this.http.post("api/Administration/Users/SaveUser", filteredResponse[x]).subscribe((response: any) => { 
								this.localDbService.setLocalDbTable("EditUserTbl", response.model, false)
							})
						}
					}
				});
		}
	}

	setNavFixed(event: boolean) {
		this.isNavFixed = event;
	}

	// This function uses the URL to indicate the current nav item selected
	parseUrl(url: string) {
		this.workflowSelected = false;
		this.usersSelected = false;
		this.adminSelected = false;
		this.helpSelected = false;
		this.userProfileSelected = false;
		this.salesRepSelected = false;
		this.territoriesSelected = false;

		if ((url.includes("workflow") 
			|| url.includes("customers")) || url.includes("edit-customer")
			|| url.includes("transactions")) { this.workflowSelected = true; }
		if ((url.includes("territories"))) { this.territoriesSelected = true; }
		if ((url.includes("sales-representative"))) { this.salesRepSelected = true; }
		if ((url.includes("users") || url.includes("edit-user"))) { this.usersSelected = true; }
		if (url.includes("administration")) { this.adminSelected = true; }
		if (url.includes("help") || (url.includes("release-notes") && !url.includes('administration'))) { this.helpSelected = true; }
		if (url.includes(String(this.user?.id))) {
			this.userProfileSelected = true; 
			this.usersSelected = false;
		}
	}

	setIsNavFixed() : boolean {
		return !this.isMobile 
			&& ((localStorage.getItem("isNavFixed") ?? 'false') == 'true') 
			&& !window.location.href.includes("code=fromEmail");
	}
}
