import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

/**
 * This function shows the error messages under form controls
 * and focuses the first invalid control. Works by marking all
 * form controls as touched.
 * @param fg The form group to validate.
 */
export function validateForm(fg: UntypedFormGroup, hasError = false, prefix = '') {
	const keys = Object.keys(fg.controls);
	keys.forEach(key => {
		if (fg.controls[key] instanceof UntypedFormArray) {
			if ((<UntypedFormArray>fg.controls[key]).value) {
				for (let i = 0; i < (<UntypedFormArray>fg.controls[key]).value.length; ++i) {
					hasError =
						validateForm(
							fg.controls[key].get(i.toString()) as UntypedFormGroup,
							hasError,
							`${prefix}[formarrayname=${key}] [ng-reflect-name="${i}"] `
						) || hasError;
				}
			}
		}
		if (fg.controls[key] instanceof UntypedFormGroup) {
			hasError =
				validateForm(fg.controls[key] as UntypedFormGroup, hasError, `${prefix}[formgroupname=${key}] `) || hasError;
		}
		if (fg.controls[key].invalid) {
			// Set aria-invalid tag after validate function called
			const el = document.querySelector(`${prefix}[formcontrolname=${key}]`);
			if (el) {
				(<any>(
					document.querySelector(
						`${prefix}[formcontrolname=${key}]${el.tagName === 'PCG-SELECT' ? ' input' : ''}`
					)
				)).setAttribute('aria-invalid', 'true');
			}
		}
		if (!hasError && fg.controls[key].invalid && !(fg.controls[key] instanceof UntypedFormGroup)) {
			hasError = true;
			// Focus the first control with an error
			if (!focusElement(`${prefix}[formcontrolname=${key}]`) && key.startsWith('CustomField')) {
				focusElement(`#${key}`);
			}
		}
		fg.controls[key].markAsDirty();
		fg.controls[key].markAsTouched();
	});
	return prefix === '' ? !hasError : hasError;
}

function focusElement(selector) {
	const el = document.querySelector(selector);
	if (el) {
		// Focus the element if one was found. Get the child input textbox if it is a pcg-select.
		(<any>(
			document.querySelector(
				`${selector}${el.tagName === 'PCG-SELECT' ? ` ng-select:not(.d-none) input, ${selector} select` : ''}`
			)
		)).focus();

		// Account for the fixed bottom buttons in scroll position
		// so the input element isn't under the fixed buttons
		const bottomButtons: any = document.querySelector('.fixed-bottom-buttons');
		if (bottomButtons) {
			if (el.getBoundingClientRect().bottom > bottomButtons.getBoundingClientRect().top) {
				const scrollOffset = el.getBoundingClientRect().bottom - bottomButtons.getBoundingClientRect().top;
				window.scrollTo(0, window.pageYOffset + scrollOffset);
			}
		}
		// Account for the fixed header in scroll position
		// so the input element isn't hidden under the nav
		const headEl: any = document.getElementById('pcgMainNavigation');
		if (headEl && getComputedStyle(headEl, null).position === 'sticky') {
			if (el.getBoundingClientRect().top <= headEl.offsetHeight) {
				window.scrollTo(0, window.pageYOffset - headEl.offsetHeight);
			}
		}
		return true;
	}
	return false;
}
