import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgHistoryComponent } from './svg-history/svg-history.component';
import { SvgRemoveComponent } from './svg.remove/svg-remove.component';
import { SvgXlsxComponent } from './svg-xlsx/svg-xlsx.component';
import { SvgEnvelopeComponent } from './svg-envelope/svg-envelope.component';
import { SvgMessageComponent } from './svg-message/svg-message.component';

@NgModule({
	declarations: [
		SvgHistoryComponent
		, SvgRemoveComponent
		, SvgXlsxComponent
		, SvgEnvelopeComponent
		, SvgMessageComponent
	],
	imports: [ CommonModule ],
	exports: [ 
		SvgHistoryComponent
		, SvgRemoveComponent
		, SvgEnvelopeComponent
		, SvgMessageComponent
	]
})
export class SvgModule { }
