<!-- #region (Un)Dock Nav -->
<button 
    class="fixed-toggle-btn"
    (click)="toggleNavFixed()" 
    *ngIf="!isMobile"
>
    <div class="toggle-tooltip">
        <span *ngIf="navFixedInput">Undock nav</span>
        <span *ngIf="!navFixedInput">Dock nav</span>
    </div>
    <fa-icon 
        *ngIf="navFixedInput"
        [icon]="faIconName.faArrowLeft" 
        class="fa-lg"
    ></fa-icon>
    <fa-icon 
        *ngIf="!navFixedInput"
        [icon]="faIconName.faArrowRight" 
        class="fa-lg"
    ></fa-icon>
</button>
<!-- #endregion -->

<div (click)="redirect('/workflow/1')" class="nav-header">
	<div class="nav-image">
		<img src="../assets/images/logos/logo_small.png" height="35px" />
	</div>
	<div *ngIf="mode !== 'Production'" class="beta-text"></div>
</div>

<mat-nav-list>
	<!-- #region Workflow -->
	<mat-list-item class="parent" [ngClass]="{'active-nav-parent-item' : workflowSelected}" [routerLink]="'workflow/1'">
		<fa-icon [icon]="faIconName.faArrowProgress" class="parent-nav-icon"></fa-icon>
		<span>Workflow</span>
	</mat-list-item>
	<!-- #endregion -->

	<!-- #region Sales Representatives-->
	<mat-list-item class="parent" [ngClass]="{'active-nav-parent-item' : salesRepSelected}" [routerLink]="'sales-representatives'">
		<fa-icon [icon]="faIconName.faPeopleLine" class="parent-nav-icon"></fa-icon>
		<span>Sales Representatives</span>
	</mat-list-item>

	<!-- #region Territories-->
	<mat-list-item class="parent" [ngClass]="{'active-nav-parent-item' : territoriesSelected}" [routerLink]="'territories'">
		<fa-icon [icon]="faIconName.faMap" class="parent-nav-icon"></fa-icon>
		<span>Territories</span>
	</mat-list-item>

	<!-- #region Users-->
	<mat-list-item (click)="toggleUsersSubMenu()" class="parent" [ngClass]="{'active-nav-parent-item' : usersSelected}">
		<fa-icon [icon]="faIconName.faUsers" class="parent-nav-icon"></fa-icon>
		<span>Users</span>
		<fa-icon [icon]="faIconName.faAngleDown" [ngClass]="{'rotate' : showUsersSubMenu}" class="dropdown-icon"></fa-icon>	
	</mat-list-item>

	<div class="submenu" [ngClass]="{'expanded' : showUsersSubMenu}" *ngIf="showUsersSubMenu">
		<mat-list-item [routerLink]="'users'">
			<span>Listing</span>
		</mat-list-item>
		<mat-list-item [routerLink]="'users/login-history'">
			<span>Global Login History</span>
		</mat-list-item>
	</div>
	<!-- #endregion -->

	<!-- #region Admin -->
	<mat-list-item 
		(click)="toggleAdminSubMenu()" 
		class="parent" 
		[ngClass]="{'active-nav-parent-item' : adminSelected}"
	>
		<fa-icon 
			[icon]="faIconName.faGear" 
			class="parent-nav-icon"
		></fa-icon>
		<span>Admin</span>
		<fa-icon 
			[icon]="faIconName.faAngleDown" 
			[ngClass]="{'rotate' : showAdminSubMenu}" 
			class="dropdown-icon"
		></fa-icon>	
	</mat-list-item>

	<div class="submenu" [ngClass]="{'expanded' : showAdminSubMenu}" *ngIf="showAdminSubMenu">
		<mat-list-item 
			(click)="redirect('/administration/global-audit-history')" 
		>
			<span>Global Audit History</span>
		</mat-list-item>

		<mat-list-item 
			class="parent" 
			(click)="redirect('/administration/release-notes/release-notes-list')" 
		>
			<span>Release Notes</span>
		</mat-list-item>

		<mat-list-item 
			class="parent" 
			(click)="redirect('/administration/system-settings')" 
		>
			<span>System Settings</span>
		</mat-list-item>

		<mat-list-item 
			class="parent" 
			(click)="openInNewTab('/quartz')"
		>
			<span>Quartz</span>
		</mat-list-item>
	</div>
	<!-- #endregion -->
	
	<hr />

	<!-- #region Help -->
	<mat-list-item 
		class="parent" 
		(click)="toggleHelpSubMenu()" 
		[ngClass]="{'active-nav-parent-item' : helpSelected}"
	>
		<fa-icon 
			[icon]="faIconName.faQuestionCircle" 
			class="parent-nav-icon"
		></fa-icon>
		<span>Help</span>
		<fa-icon 
		[icon]="faIconName.faAngleDown" 
		[ngClass]="{'rotate' : showHelpSubMenu}" 
		class="dropdown-icon"
	></fa-icon>	
	</mat-list-item>

	<div class="submenu" [ngClass]="{'expanded' : showHelpSubMenu}" *ngIf="showHelpSubMenu">
		<mat-list-item 
		class="parent" 
		(click)="redirect('/help')" 
		>
			<span>Help Documents</span>
		</mat-list-item>

		<mat-list-item (click)="redirect('/release-notes')">
			<span>Release Notes</span>
		</mat-list-item>
	</div>
	<!-- #endregion -->

	<!-- #region Profile -->
	<mat-list-item 
		(click)="toggleProfileSubMenu()" 
		class="parent " 
		[ngClass]="{'active-nav-parent-item' : userProfileSelected}"
	>
		<fa-icon 
			[icon]="faIconName.faUser" 
			class="parent-nav-icon"
		></fa-icon>
		<span class="long-nav-item">{{ user?.fullName }}</span>
		<fa-icon 
			[icon]="faIconName.faAngleDown" 
			[ngClass]="{'rotate' : showProfileSubMenu}" 
			class="dropdown-icon"
		></fa-icon>	
	</mat-list-item>

	<div class="submenu" [ngClass]="{'expanded' : showProfileSubMenu}" *ngIf="showProfileSubMenu">
		<mat-list-item 
			(click)="adminSelected = false; userProfileSelected = true; navOpened = false; closeNav.emit(false); " 
			[routerLink]="'users/edit-user/' + user?.id" 
		><span>My Profile</span></mat-list-item>
		<mat-list-item (click)="logout()"><span>Logout</span></mat-list-item>
	</div>
	<!-- #endregion -->
</mat-nav-list>
