export interface ISalesTerritoryListTbl {
	territoryId: number;
	name?: string;
	region?: string;
	canDelete: boolean;
};

export class SalesTerritoryListTbl implements ISalesTerritoryListTbl {
	territoryId = 0;
	name = '';
	region = '';
	canDelete = false;
};
