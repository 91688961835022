import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const getHelpFilesNav: () => NavRoute = () => ({
	name: 'Help',
	children: [
		{
			name: 'Help Documents'
			, exact: true
			, path: 'help'
		},
		{
			name: 'Release Notes'
			, exact: true
			,  path: 'release-notes'
		},
	]
});
