import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HelpFilesVm } from 'app/shared/generated/HelpFiles/Models/HelpFilesVm';

@Injectable({
    providedIn: 'root'
})
export class HelpFilesService {

    constructor(private httpClient: HttpClient) { }

    GetHelpFileDetails(id: number) : Observable<HelpFilesVm> { return this.httpClient.get<HelpFilesVm>(`api/HelpFiles/HelpFiles/GetDetails/${id}`);  }
}
