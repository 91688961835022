<pcg-downtime-notification></pcg-downtime-notification>

<br />
<img alt="Innova" class="system-logo" src="assets/images/logos/logo_transparent.png" />
<br />
<h4 class="system-name"><b>Sales Tracking System</b></h4>
<br />
<div *ngIf="!isLogin" class="login-container clearfix divider-content">
	<form 
		#myForm 
		(submit)="onSubmit()" 
		[formGroup]="loginForm"
	>
		<div class="row">
			<pcg-control-group class="col-sm-12" label="Username">
				<input 
					pcgControlRef 
					formControlName="userName" 
					type="text" 
					class="form-control" 
				/>
			</pcg-control-group>
		</div>
		<pcg-show-hide-password 
			[controlName]="'password'" 
			[parentForm]="loginForm" 
			label="Password" 
			inputCssClass="form-control"
		></pcg-show-hide-password>
		<button type="submit" class="btn btn-save btn-lg">Login</button>
		<a class="float-right" routerLink="/forgot-password">Forgot your password?</a>
	</form>	
</div>

<div *ngIf="isError" class="login-container clearfix divider-content">
	<br />
	<div class="alert alert-danger text-center">
		{{ errorString }}
		<br /><br />
		Press the Continue button to continue into the site.
		<br /><br />
		<button type="button" class="btn btn-md btn-cancel" (click)="continueClick()">Continue</button>
	</div>
</div>
