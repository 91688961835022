import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../core/security/auth.guard'
import { PermissionConst } from '../core/security/generated/Permission';
import { SharedModule } from '../shared/shared.module';
import { TransactionListComponent } from './transaction-list/transaction-list.component'
import { TransactionEditComponent } from './transaction-edit/transaction-edit.component'
import { ImportHistoryComponent } from './import-history/import-history.component'
import { ExceptionsComponent } from './exceptions/exceptions.component';


/**
 * This is the list of routes in the release notes module.
 * Both this and the navigation need to be updated as
 * pages are added.
 */
const routes: Routes = [
    {
		path: ''
		, component: TransactionListComponent
		, pathMatch: 'full'
		, canActivate:  [AuthGuard ]
		, canLoad: [ AuthGuard ]
		, data: { security: PermissionConst.adminFeatures }
	},
    {
		path: 'transaction-edit/:id'
		, component: TransactionEditComponent
		, pathMatch: 'full'
		, canActivate:  [AuthGuard ]
		, canLoad: [ AuthGuard ]
		, data: { security: PermissionConst.adminFeatures }
	},
	{
		path: 'import-history'
		, component: ImportHistoryComponent
		, pathMatch: 'full'
		, canActivate:  [AuthGuard ]
		, canLoad: [ AuthGuard ]
		, data: { security: PermissionConst.adminFeatures }
	},
	{
		path: 'import-history/transactions/:id'
		, component: TransactionListComponent
		, canActivate:  [AuthGuard ]
		, canLoad: [ AuthGuard ]
		, data: { security: PermissionConst.adminFeatures }
	},
	{
		path: 'exceptions'
		, component: ExceptionsComponent
		, pathMatch: 'full'
		, canActivate:  [AuthGuard ]
		, canLoad: [ AuthGuard ]
		, data: { security: PermissionConst.adminFeatures }
	},
	{
		path: 'import-history/exceptions/:id'
		, component: ExceptionsComponent
		, canActivate:  [AuthGuard ]
		, canLoad: [ AuthGuard ]
		, data: { security: PermissionConst.adminFeatures }
	},
];

@NgModule({
	declarations: [
		TransactionListComponent
		, TransactionEditComponent
		, ImportHistoryComponent
		, ExceptionsComponent
	],
	imports: [ 
		RouterModule.forChild(routes)
		, CommonModule
		, SharedModule 
	],
	exports: [ RouterModule, TransactionListComponent, ExceptionsComponent, ]
})
export class TransactionsModule { }
