import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class UserJsVm {
	id: number;
	fullName?: string;
	email?: string;
	userName?: string;
	canChangeSecurity: boolean;
	inactivityTimeout: boolean;
	twoFactorEnabled: boolean;
	securityAccessDate?: Date;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(0, [...(validators['id'] ?? []), ...[Validators.required]]),
			'fullName': new UntypedFormControl(null, [...(validators['fullName'] ?? []), ...[]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[]]),
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[]]),
			'canChangeSecurity': new UntypedFormControl(false, [...(validators['canChangeSecurity'] ?? []), ...[]]),
			'inactivityTimeout': new UntypedFormControl(false, [...(validators['inactivityTimeout'] ?? []), ...[]]),
			'twoFactorEnabled': new UntypedFormControl(false, [...(validators['twoFactorEnabled'] ?? []), ...[]]),
			'securityAccessDate': new UntypedFormControl(null, [...(validators['securityAccessDate'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): UserJsVmFromFg {
		return new UserJsVmFromFg(fg);
	}
}

export class UserJsVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get id(): number { return this.fg.get('id').value; }
	set id(val) { this.fg.get('id').setValue(val); }

	get fullName(): string { return this.fg.get('fullName').value; }
	set fullName(val) { this.fg.get('fullName').setValue(val); }

	get email(): string { return this.fg.get('email').value; }
	set email(val) { this.fg.get('email').setValue(val); }

	get userName(): string { return this.fg.get('userName').value; }
	set userName(val) { this.fg.get('userName').setValue(val); }

	get canChangeSecurity(): boolean { return this.fg.get('canChangeSecurity').value; }
	set canChangeSecurity(val) { this.fg.get('canChangeSecurity').setValue(val); }

	get inactivityTimeout(): boolean { return this.fg.get('inactivityTimeout').value; }
	set inactivityTimeout(val) { this.fg.get('inactivityTimeout').setValue(val); }

	get twoFactorEnabled(): boolean { return this.fg.get('twoFactorEnabled').value; }
	set twoFactorEnabled(val) { this.fg.get('twoFactorEnabled').setValue(val); }

	get securityAccessDate(): Date { return this.fg.get('securityAccessDate').value; }
	set securityAccessDate(val) { this.fg.get('securityAccessDate').setValue(val); }

}
