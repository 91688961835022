import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TopHeaderComponent } from './header/header.component';
import { SharedModule } from 'app/shared/shared.module';
import { HorizontalLoginComponent } from './header/horizontal-login/horizontal-login.component';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
	declarations: [
		TopHeaderComponent
		, HorizontalLoginComponent
		, SpinnerComponent
	],
	imports: [
		CommonModule
		, RouterModule
		, SharedModule
	],
	exports: [
		TopHeaderComponent
		, SpinnerComponent
	]
})
export class LayoutModule {}
