import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { faAngleDown, faUser, faGear, faQuestionCircle, faUsers, faArrowLeft, faArrowRight, faPeopleRoof, faMoneyBill, faPeopleLine, faMap, faArrowProgress } from '@fortawesome/pro-solid-svg-icons';

import { GlobalService } from 'app/shared/services/global.service';
import { pcgSettings } from '../../../shared/generated/pcg-settings';
import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/User/JavaScript/UserJsVm';
import { NavigationService } from '../navigation.service';

@Component({
  	selector: 'pcg-mat-side-nav',
  	templateUrl: './mat-side-nav.component.html',
  	styleUrls: ['./mat-side-nav.component.scss']
})
export class MatSideNavComponent implements OnInit {
	@Input() user: UserJsVm;
	@Input() navFixedInput: boolean;
	@Output() navFixedOutput = new EventEmitter<boolean>();
	@Output() closeNav = new EventEmitter<boolean>();

  	subscriptions: Subscription = new Subscription();

  	navOpened: boolean;
	isNavFixed: boolean = false;
  	mode = pcgSettings.mode;
	showUsersSubMenu = false;
  	showAdminSubMenu = false;
	showProfileSubMenu = false;
	showHelpSubMenu = false;
	showTransactionSubMenu: boolean = false;
	showCustomersSubMenu: boolean = false;

  	// For nav selected items
	workflowSelected: boolean;
	usersSelected: boolean;
	adminSelected: boolean;
	helpSelected: boolean;
	userProfileSelected: boolean;
	salesRepSelected: boolean;
	territoriesSelected: boolean;

	// Fa icons
	faIconName = { faAngleDown, faUser, faGear, faQuestionCircle, faUsers, faArrowLeft, faArrowRight, faPeopleLine, faMoneyBill, faPeopleRoof, faMap, faArrowProgress };

	xDown = null;                                                        
	yDown = null;

	isMobile: boolean;
	@HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

  	constructor(public router: Router, private sec: SecurityService, private navService: NavigationService) {}

  	ngOnInit() { 
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);

		this.navService.navOpened.subscribe((data)=>{ this.navOpened = data; });
		this.subscriptions.add(this.router.events.subscribe(() => { this.parseUrl(this.router.url); })); 
		
		//event litseners for swiping away side nav in mobile
		document.addEventListener('touchstart', (evt) => this.handleTouchStart(evt), false);        
		document.addEventListener('touchmove', (evt) => this.handleTouchMove(evt), false);
	}

	redirect(path: string) {
		this.router.navigateByUrl(path);

		// And if nav is not fixed, collapse nav on redirect
		if (!this.navFixedInput) { 
			this.navOpened = false; 
			this.closeNav.emit(false);
		}
	}

	openInNewTab(namedRoute: string) {
		let newRelativeUrl = this.router.createUrlTree([namedRoute]);
		let baseUrl = window.location.href.replace(this.router.url, '');
		window.open(baseUrl + newRelativeUrl, '_blank');
		this.navOpened = false;
		this.closeNav.emit(false);
	}

	toggleAdminSubMenu() {
		this.showAdminSubMenu = !this.showAdminSubMenu; 
		this.showUsersSubMenu = false;
		this.showProfileSubMenu = false;
		this.showHelpSubMenu = false;
		this.showTransactionSubMenu = false;
		this.showCustomersSubMenu = false;
	}

	toggleProfileSubMenu() {
		this.showProfileSubMenu = !this.showProfileSubMenu;
		this.showUsersSubMenu = false;
		this.showAdminSubMenu = false;
		this.showHelpSubMenu = false;
		this.showTransactionSubMenu = false;
		this.showCustomersSubMenu = false;
	}

	toggleHelpSubMenu() {
		this.showHelpSubMenu = !this.showHelpSubMenu;
		this.showUsersSubMenu = false;
		this.showAdminSubMenu = false;
		this.showProfileSubMenu = false;
		this.showTransactionSubMenu = false;
		this.showCustomersSubMenu = false;
	}

	toggleUsersSubMenu() {
		this.showUsersSubMenu = !this.showUsersSubMenu;
		this.showAdminSubMenu = false;
		this.showHelpSubMenu = false;
		this.showProfileSubMenu = false;
		this.showTransactionSubMenu = false;
		this.showCustomersSubMenu = false;
	}

	toggleTransactionsSubMenu() {
		this.showTransactionSubMenu = !this.showTransactionSubMenu;
		this.showUsersSubMenu = false;
		this.showAdminSubMenu = false;
		this.showHelpSubMenu = false;
		this.showProfileSubMenu = false;
		this.showCustomersSubMenu = false;
	}

	toggleCustomersSubMenu() {
		this.showCustomersSubMenu = !this.showCustomersSubMenu ;
		this.showUsersSubMenu = false;
		this.showAdminSubMenu = false;
		this.showHelpSubMenu = false;
		this.showProfileSubMenu = false;
		this.showTransactionSubMenu = false;
	}

	// This function uses the URL to indicate the current nav item selected
	parseUrl(url: string) {
		this.workflowSelected = false;
		this.usersSelected = false;
		this.adminSelected = false;
		this.helpSelected = false;
		this.userProfileSelected = false;
		this.salesRepSelected = false;
		this.territoriesSelected = false;

		if ((url.includes("workflow") 
			|| url.includes("master-customers")) || url.includes("edit-master-customer")
			|| url.includes("transactions")) { this.workflowSelected = true; }
		if (url.includes("sales-representative")) {this.salesRepSelected = true;}
		if (url.includes("territories") || url.includes("territory")) {this.territoriesSelected = true;}
		if ((url.includes("users") || url.includes("edit-user"))) { this.usersSelected = true; }
		if (url.includes("administration")) { this.adminSelected = true; }
		if (url.includes("help") || (url.includes("release-notes") && !url.includes('administration'))) { this.helpSelected = true; }
		if (url.includes(String(this.user?.id))) {
			this.userProfileSelected = true; 
			this.usersSelected = false;
		}
	}

	// Logout removes all of our security variables and redirects user to root
	logout() {
		localStorage.setItem("isNavFixed", "false");
		this.navOpened = false;
		this.closeNav.emit(false);
		this.navFixedOutput.emit(false);
		this.sec.setSecurity(null, null, null);
		this.deleteAllCookies();
		localStorage.removeItem("precacheSet");
		this.router.navigate(['/']);
	}

	// This is used above to delete all cookies on logout
	deleteAllCookies() {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}
	}

	// swipe to close sidenav	                                               																			
	handleTouchStart(evt) {
		if (window.innerWidth < 993 && this.navOpened === true) {
			let firstTouch = evt.touches || evt.originalEvent.touches;       
			this.xDown = firstTouch[0].clientX;                                      
			this.yDown = firstTouch[0].clientY; 
		}			                                   
	};                                                
																				
	handleTouchMove(evt) {
		if (this.isMobile && this.navOpened === true) {
			if ( !this.xDown || !this.yDown ) { return; }
	
			let xUp = evt.touches[0].clientX;                                    
			let yUp = evt.touches[0].clientY;
			let xDiff = this.xDown - xUp;
			let yDiff = this.yDown - yUp;
																				
			if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
				if ( xDiff > 8 ) {
					/* right swipe */ 
					this.navOpened = false;
					this.closeNav.emit(false);
				} else {
					/* left swipe */				
				}                       
			} else {
				if ( yDiff > 8 ) {
					/* down swipe */ 
				} else { 
					/* up swipe */
				}                                                                 
			}
			/* reset values */
			this.xDown = null;
			this.yDown = null;  
		}
	}

	toggleNavFixed() {
		this.navFixedInput = !this.navFixedInput;
		localStorage.setItem("isNavFixed", this.navFixedInput.toString());
		this.navFixedOutput.emit(this.navFixedInput);
	}
}
