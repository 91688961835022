import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faUserLock, faQuestion } from '@fortawesome/pro-solid-svg-icons';

import { TableComponent } from 'app/shared/table/table.component';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';
import { AppDatabase } from 'local-db/services/init.ldb.service';
import { CheckNetworkService } from 'local-db/services/check-network.service';
import { EditUserVm } from 'app/shared/generated/Administration/Models/User/EditUser/EditUserVm';
import { SetLocaldbTablesService } from 'app/services/service-workers/set-localdb-tables.service';
import { SwPush } from '@angular/service-worker';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessageService } from 'app/core/system-message/system-message-service';

@Component({
	selector: 'pcg-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

	@ViewChild(TableComponent, { static: false }) table: TableComponent<EditUserVm>;
	_subscription: PushSubscription;
	showPushNotification: boolean;
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

	columns = EditUserVm.ColumnDefs;

	filters = { isActive: true };
	filterMap = { "isActive": "Is Active?" }
	initFilters = Object.assign({}, this.filters);
	dataSource = "api/Administration/Users/GetUserList";

	faIconName = { faUserLock, faQuestion };

	isDataLoaded = false;
	isOnline: boolean;
	pushNotificationOperation = "subscribe";

	constructor(
		title: Title
		, public btnFilterService: ButtonFilterService
		, private router: Router
		, private localDb: AppDatabase
		, private localDbService: SetLocaldbTablesService
		, private swPush: SwPush
        , private httpClient: HttpClient
		, private sec: SecurityService
		, private ms: SystemMessageService
	) { 
		title.setTitle('User List'); 
		swPush.subscription.subscribe((subscription) => {
            this._subscription = subscription;
        	this.pushNotificationOperation = (this._subscription === null) ? 'Subscribe' : 'Unsubscribe';
			console.log(this._subscription)
        });
	}

	async ngOnInit() {
		this.btnFilterService.resetFilterData.subscribe(() => { this.resetFilters() });	
		this.isOnline = CheckNetworkService.checkNetworkStatus();
		if ('Notification' in window) {
			this.showPushNotification = true;
			this.swPush.notificationClicks.subscribe((click) => {
				
			})
		  } else {
			this.showPushNotification = false;
		  }	
	}

	resetFilters() {
		this.filters = Object.assign(this.filters, this.initFilters);
		this.reloadTable();
	}

	async reloadTable() {
		this.table.ajaxData = { isActive: this.filters.isActive, };
		this.table.hideShowColumn('isActiveStr', this.filters.isActive === null);
		this.table.ajaxReload();
	}

	updateWhileOffline() {
		if (!this.isOnline) {
			this.localDb.table("EditUserTbl").toArray()
				.then((response) => { 
					if (response.length > 0) {
						let filteredResponse = response.filter(o => o.isUpdatedOffline === true);
						for (let x = 0; x < filteredResponse.length; x++) {
							this.table.data.map((o) => {
								if (o.userId === filteredResponse[x].userId) {
									o.userId = filteredResponse[x].userId;
									o.email = filteredResponse[x].email;
									o.fullName = filteredResponse[x].fullName;
									o.isActive = filteredResponse[x].isActive;
									o.isLockedOut = filteredResponse[x].isLockedOut;
									o.phoneNumber = filteredResponse[x].phoneNumber;
								}
							});
						}
					}
				});
		} else {
			this.table.data.map(o => {
				this.localDbService.setLocalDbTable("EditUserTbl", o, false)
			})
		}
				
	}

	operatePushNotification() {
        console.log(this._subscription);
        (this._subscription == null) ? this.subscribe() : this.unsubscribe(this._subscription.endpoint);
    }

    private subscribe() { 
        // Retrieve public VAPID key from the server
        this.httpClient.get('api/PushSubscription/GetPublicKey', { responseType: 'text' }).subscribe(publicKey => {
			// Request subscription with the service worker
			this.swPush.requestSubscription({
				serverPublicKey: publicKey
			})
			// Distribute subscription to the server
			.then(subscription => this.httpClient.post('api/PushSubscription/AddSubscription', subscription, this.httpOptions).subscribe(
			() => { },
			error => console.error(error)
			))
			.catch(error => {
				console.error(error);
				this.ms.setSystemMessage("Check your browser settings. You may have push notifications disabled for this site", "warning");
			});
        },
        error => console.error(error));
    };

    private unsubscribe(endpoint) {
        this.swPush.unsubscribe()
      .then(() => this.httpClient.get(`api/PushSubscription/DeleteSubscription?userId=${this.sec?.getUser().id}&endpoint=${encodeURIComponent(endpoint)}`).subscribe(() => { },
        error => console.error(error)
      ))
      .catch(error => console.error(error));
    }

	redirectUser(row) { this.router.navigateByUrl(`/users/edit-user/${row.userId}`); }
}
