export interface ILoginHistoryTbl {
	attemptSuccessful?: string;
	userName?: string;
	userID: number;
	ipAddress?: string;
	dateAttempted: Date;
	dateAttemptedFormatted?: string;
};

export class LoginHistoryTbl implements ILoginHistoryTbl {
	attemptSuccessful = '';
	userName = '';
	userID = 0;
	ipAddress = '';
	dateAttempted = new Date();
	dateAttemptedFormatted = '';
};
