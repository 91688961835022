<pcg-spinner></pcg-spinner>
<pcg-system-message></pcg-system-message>
<mat-sidenav-container class="nav-container">
	<!-- Navigation menu -->
	<mat-sidenav 
		#sidenav 
		[mode]="isNavFixed && !isMobile ? 'side' : 'over'" 
		[(opened)]="navOpened" 
		(mouseleave)="onNavOpenedChange(isNavFixed)"
	>
		<pcg-mat-side-nav 
			[user]="user" 
			[navFixedInput]="isNavFixed"
			(navFixedOutput)="setNavFixed($event)"
			(closeNav)="setNavOpened($event)"
		></pcg-mat-side-nav>
	</mat-sidenav>
	
	<!-- Rest of the site -->
	<mat-sidenav-content [ngClass]="{'darker': navOpened}">
		<!-- Nav drawer -->
		<nav *ngIf="!isMobile && isLoggedOn && !isNavFixed" class="nav-drawer" (mouseover)="onNavOpenedChange(true)">
			<div class="nav-drawer-image">
				<img src="../assets/images/logos/logo_small.png" height="35px" />
			</div>
			<fa-icon
				[icon]="faIconName.faArrowProgress"
				class="drawer-icon" 
				[class]="{'active-drawer-item' : workflowSelected}"
			></fa-icon>
			<fa-icon 
				[icon]="faIconName.faPeopleLine" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : salesRepSelected}"
			></fa-icon>
			<fa-icon 
				[icon]="faIconName.faMap" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : territoriesSelected}"
			></fa-icon>
			<fa-icon 
				[icon]="faIconName.faUsers" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : usersSelected}"
			></fa-icon>
			<fa-icon 
				[icon]="faIconName.faGear" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : adminSelected}"
			></fa-icon>
			<fa-icon 
				[icon]="faIconName.faQuestionCircle" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : helpSelected}"
			></fa-icon>
			<fa-icon 
				[icon]="faIconName.faUser" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : userProfileSelected}"
			></fa-icon>
		</nav>

		<!-- Everything else -->
		<div [ngClass]="isNavFixed ? 'left-shift-fixed' : 'left-shift'">
			<pcg-header
				id="mainTopHeader"
				(hamburgerClick)="setNavOpened($event)"
				[tabPanel]="tabPanel"
			></pcg-header>
			<div [ngClass]="isNavFixed ? 'top-shift-fixed' : 'top-shift'">
				<pcg-push-notification></pcg-push-notification>
				<div role="main" class="main-container" >
					<mat-tab-nav-panel #tabPanel class="container">
						<router-outlet></router-outlet>
					</mat-tab-nav-panel>
				</div>
			</div>
		</div>

		<!-- Footer -->
		<div *ngIf="!isMobile" class="footer-ctnr no-print">
			<div class="footer p-2">
				System by <a rel="noreferrer noopener" href="http://www.paulconsultinggroup.com" target="_blank">Paul Consulting Group</a>
				<span id="versionHistory" *ngIf="isLoggedOn">
					&nbsp;|&nbsp;
					<a onclick="$router.navigateByUrl('/release-notes'); return false;" href="release-notes">Release Notes</a>
					&nbsp;|&nbsp;
					<a onclick="$router.navigateByUrl('/help/pwa'); return false;" href="/assets/PwaHelpFile/PWA.pdf">PWA</a>
				</span>
			</div>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>
