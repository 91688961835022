<form [formGroup]="formGroup" pcgSubmitUrl="api/Sales/SalesTerritory/SaveTerritory"
  (messageReceive)="saveTerritory($event)">
  <div class="row">
    <input type="hidden" formControlName="salesTerritoryId" />
    <div class="col-md-6">
      <div class="row">
        <pcg-control-group class="col-md-8" label="Name">
          <input pcgControlRef class="form-control" type="text" formControlName="name" />
        </pcg-control-group>
      </div>

      <div class="row">
        <pcg-control-group class="col-md-8" label="Region">
          <pcg-select dataSource="api/Select/Regions" placeholder="Select a Region"
                        formControlName="region" [multiple]="true" pcgControlRef></pcg-select>
        </pcg-control-group>
      </div>

      <div class="row" *ngIf="id !== 0">
        <pcg-control-group class="col-md-8" label="Manager">
          <input pcgControlRef class="form-control" type="text" formControlName="manager" />
        </pcg-control-group>
      </div>
      <ng-container *ngIf="id == 0">
        <pcg-fixed-buttons>
          <a class="btn btn-cancel btn-lg" backButton="/territories">Back</a>
          <button type="submit" *ngIf="id === 0" class="btn btn-save btn-lg">Save</button>
        </pcg-fixed-buttons>

      </ng-container>
    </div>

    <div class="col-md-6" *ngIf="id !== 0">
      <div class="row">
        <pcg-control-group class="col-md-8" label="Sales Rep. 1">
          <input pcgControlRef class="form-control" type="text" formControlName="salesRep1" />
        </pcg-control-group>
      </div>

      <div class="row">
        <pcg-control-group class="col-md-8" label="Sales Rep. 2">
          <input pcgControlRef class="form-control" type="text" formControlName="salesRep2" />
        </pcg-control-group>
      </div>
    </div>
    <ng-container *ngIf="id !== 0">
      <pcg-fixed-buttons>
        <a class="btn btn-cancel btn-lg" backButton="/territories">Back</a>
        <button type="submit" class="btn btn-save btn-lg">Save Changes</button>
      </pcg-fixed-buttons>
    </ng-container>
  </div>
</form>