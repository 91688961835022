/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgbInputDatepicker, NgbDate, NgbDateNativeAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { subDays, subMonths, subYears, startOfMonth, endOfMonth, startOfYear, startOfDay, endOfDay } from 'date-fns';

import { DateRange } from '../../../models/date-range';

// // These are alternatives to date-fns functions which offset the timezone to
// // make .toISOstring give dates in local time zone. Dates can then be converted
// // to UTC in the backend, when needed, based on the UTC offset sent.
// const tzoffset = (new Date()).getTimezoneOffset() * 60000;
// function startOfDayOffset(d: Date) {
// 	return new Date(startOfDay(d).getTime() - tzoffset);
// }
// function endOfDayOffset(d: Date) {
// 	return new Date(endOfDay(d).getTime() - tzoffset);
// }

@Component({
	selector: 'pcg-date-range',
	templateUrl: './date-range.component.html',
	styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit, OnChanges {
	
	@Input() dateRange: DateRange = {};
	@Input() labelForId: string = undefined;
	@Input() minDate?: Date;
	@Input() maxDate?: Date = new Date();
	@Input() disabled = false;
	@Output() dateRangeChange = new EventEmitter<DateRange>();

	hoveredDate: NgbDate;

	// These are our preset ranges. They have been taken from the jQuery UI date range picker
	// we use in older MVC and Web Forms systems
	presetRanges = [
		{ 
			text: 'Today', 
			dateStart: () => startOfDay(new Date()), 
			dateEnd: () => endOfDay(new Date()) 
		},
		{
			text: 'Yesterday',
			dateStart: () => startOfDay(subDays(new Date(), 1)),
			dateEnd: () => endOfDay(subDays(new Date(), 1))
		},
		{
			text: 'Last 7 Days',
			dateStart: () => startOfDay(subDays(new Date(), 6)),
			dateEnd: () => endOfDay(new Date())
		},
		{
			text: 'Month to Date',
			dateStart: () => startOfDay(startOfMonth(new Date())),
			dateEnd: () => endOfDay(new Date())
		},
		{
			text: 'Previous Month',
			dateStart: () => startOfDay(startOfMonth(subMonths(new Date(), 1))),
			dateEnd: () => endOfDay(endOfMonth(subMonths(new Date(), 1)))
		},
		{
			text: 'Year to Date',
			dateStart: () => startOfDay(startOfYear(new Date())),
			dateEnd: () => endOfDay(new Date()),
		},
		{
			text: 'Previous Year',
			dateStart: () => startOfDay(subYears(new Date(), 1)),
			dateEnd: () => endOfDay(new Date())
		}
	];

	private fromDate: NgbDateStruct;
	private toDate: NgbDateStruct;
	private min: NgbDateStruct | null;
	private max: NgbDateStruct | null;
	@ViewChild('dp', { read: ElementRef, static: true })
	private inputElRef: ElementRef;
	@ViewChild('dp', { static: true })
	private dp: NgbInputDatepicker;
	private newDateRange: DateRange;

	constructor(private readonly dateAdapter: NgbDateNativeAdapter, private element: ElementRef) {}

	ngOnInit() {
		if (this.dateRange.dateBegin) { this.dateRange.dateBegin = startOfDay(this.dateRange.dateBegin); }
		if (this.dateRange.dateEnd) { this.dateRange.dateEnd = endOfDay(this.dateRange.dateEnd); }
		this.newDateRange = { ...this.dateRange };
		this.fromDate = this.dateAdapter.fromModel(this.dateRange.dateBegin);
		this.toDate = this.dateAdapter.fromModel(this.dateRange.dateEnd);
		this.min = this.minDate ? this.dateAdapter.fromModel(this.minDate) : null;
		this.max = this.maxDate ? this.dateAdapter.fromModel(this.maxDate) : null;
		this.inputElRef.nativeElement.value = this.formatInputText();
		if (this.fromDate) {
			this.dp.startDate = {
				year: this.fromDate.year,
				month: this.fromDate.month,
			};
		}
	}

	triggerChanged() {
		const event = new CustomEvent('change', { bubbles: true });
		this.element.nativeElement.dispatchEvent(event);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.dateRange || changes.disabled) { this.ngOnInit(); }
	}

	onDateChange(date: NgbDate, dp: NgbInputDatepicker) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
			this.newDateRange.dateBegin = this.dateAdapter.toModel(this.fromDate);
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
			this.newDateRange.dateEnd = this.dateAdapter.toModel(this.toDate);
		} else {
			this.toDate = null;
			this.fromDate = date;
			this.newDateRange.dateBegin = this.dateAdapter.toModel(this.fromDate);
			this.newDateRange.dateEnd = null;
		}
		// We always need to reset the input value, even though it isn't changing
		// here, because otherwise bootstrap's regular date picker will fill it
		// with whatever date they clicked automatically.
		this.inputElRef.nativeElement.value = this.formatInputText();
	}

	/** We need to toggle between opening and closing on click of the date range  */
	toggleDateRangePicker() {
		if (this.dp.isOpen()) { this.close(); } 
		else { this.open(); }
	}

	/** Open the date range picker.
	 * Initially sets the date range as whatever they currently have selected
	 */
	open() {
		this.newDateRange = { ...this.dateRange };
		this.fromDate = this.dateAdapter.fromModel(this.dateRange.dateBegin);
		this.toDate = this.dateAdapter.fromModel(this.dateRange.dateEnd);
		this.dp.open();
	}

	/** On click of the apply button in the date range picker */
	apply() {
		if (this.newDateRange.dateBegin == null && this.newDateRange.dateEnd == null) { return; }
		this.dateRange = this.newDateRange;
		if (!this.dateRange.dateEnd) { this.dateRange.dateEnd = this.dateRange.dateBegin; }

		// Set the times for the new dates
		this.dateRange.dateEnd = endOfDay(this.dateRange.dateEnd);
		this.dateRange.dateBegin = startOfDay(this.dateRange.dateBegin);

		this.inputElRef.nativeElement.value = this.formatInputText();
		this.dateRangeChange.emit(this.dateRange);
		this.triggerChanged();
		this.dp.close();
	}

	/** On click of the close button in the date range picker */
	close() { this.dp.close(); }

	/** On click of the clear button in the date range picker */
	clear() {
		this.dateRange = {};
		this.inputElRef.nativeElement.value = this.formatInputText();
		this.dateRangeChange.emit(this.dateRange);
		this.triggerChanged();
		this.dp.close();
	}

	/**
	 * Set the preset based on the passed in dates.
	 * 
	 * @param beginDate Begin date to set our date range to.
	 * @param endDate End date to set our date range to.
	 */
	setPreset(beginDate: Date, endDate: Date) {
		this.dateRange = new DateRange(beginDate, endDate);
		this.inputElRef.nativeElement.value = this.formatInputText();
		this.dateRangeChange.emit(this.dateRange);
		this.triggerChanged();
	}

	private formatInputText(): string {
		if (
			this.dateRange.dateBegin &&
			this.dateRange.dateEnd &&
			DateRange.isValidDate(this.dateRange.dateBegin) &&
			DateRange.isValidDate(this.dateRange.dateEnd)
		) {
			if (this.dateRange.dateBegin.toLocaleDateString() === this.dateRange.dateEnd.toLocaleDateString()) {
				return this.dateRange.dateBegin.toLocaleDateString();
			}
			return `${this.dateRange.dateBegin.toLocaleDateString()} - ${this.dateRange.dateEnd.toLocaleDateString()}`;
		}
		return 'Select date range...';
	}

	isHovered(date) {
		return (
			this.fromDate &&
			!this.toDate &&
			this.hoveredDate &&
			date.after(this.fromDate) &&
			date.before(this.hoveredDate)
		);
	}

	isInside = date => date.after(this.fromDate) && date.before(this.toDate);
	isFrom = date => date.equals(this.fromDate);
	isTo = date => date.equals(this.toDate);
	isWeekend(date: NgbDate) {
		const d = new Date(date.year, date.month - 1, date.day);
		return d.getDay() === 0 || d.getDay() === 6;
	}
	isDisabled = date => date.after(this.max) || date.before(this.min);
	isInFuture = date => date.after(this.toDate);
}
