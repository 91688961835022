export interface ICustomerListApprovalTbl {
	isCustomerListApproved: boolean;
	approvalDate?: Date;
	approvedByUser?: string;
};

export class CustomerListApprovalTbl implements ICustomerListApprovalTbl {
	isCustomerListApproved = false;
	approvalDate = new Date();
	approvedByUser = '';
};
