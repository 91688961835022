import { Directive, HostListener, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Directive({
	selector: '[backButton]'
})
export class BackButtonDirective {

	@Input('backButton') fallbackUrl: string = '/';
	@Input() queryParams: {};

	constructor(private router: Router, private location: Location) {}

	@HostListener('click')
	onClick(): void { this.back(); }

	back() {
		if (window.history.length > 1) {
			this.location.back();
			return;
		}
		this.router.navigateByUrl(this.fallbackUrl);
	}
}
