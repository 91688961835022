import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class PermissionVm {
	id: number;
	coreFeatures: boolean;
	manageUsers: boolean;
	applicationSettings: boolean;
	canChangePermissions: boolean;
	adminFeatures: boolean;
	permissionRoleString?: string;
	viewQuartzJobs: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(0, [...(validators['id'] ?? []), ...[Validators.required]]),
			'coreFeatures': new UntypedFormControl(false, [...(validators['coreFeatures'] ?? []), ...[]]),
			'manageUsers': new UntypedFormControl(false, [...(validators['manageUsers'] ?? []), ...[]]),
			'applicationSettings': new UntypedFormControl(false, [...(validators['applicationSettings'] ?? []), ...[]]),
			'canChangePermissions': new UntypedFormControl(false, [...(validators['canChangePermissions'] ?? []), ...[]]),
			'adminFeatures': new UntypedFormControl(false, [...(validators['adminFeatures'] ?? []), ...[]]),
			'permissionRoleString': new UntypedFormControl(null, [...(validators['permissionRoleString'] ?? []), ...[]]),
			'viewQuartzJobs': new UntypedFormControl(false, [...(validators['viewQuartzJobs'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): PermissionVmFromFg {
		return new PermissionVmFromFg(fg);
	}
}

export class PermissionVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get id(): number { return this.fg.get('id').value; }
	set id(val) { this.fg.get('id').setValue(val); }

	get coreFeatures(): boolean { return this.fg.get('coreFeatures').value; }
	set coreFeatures(val) { this.fg.get('coreFeatures').setValue(val); }

	get manageUsers(): boolean { return this.fg.get('manageUsers').value; }
	set manageUsers(val) { this.fg.get('manageUsers').setValue(val); }

	get applicationSettings(): boolean { return this.fg.get('applicationSettings').value; }
	set applicationSettings(val) { this.fg.get('applicationSettings').setValue(val); }

	get canChangePermissions(): boolean { return this.fg.get('canChangePermissions').value; }
	set canChangePermissions(val) { this.fg.get('canChangePermissions').setValue(val); }

	get adminFeatures(): boolean { return this.fg.get('adminFeatures').value; }
	set adminFeatures(val) { this.fg.get('adminFeatures').setValue(val); }

	get permissionRoleString(): string { return this.fg.get('permissionRoleString').value; }
	set permissionRoleString(val) { this.fg.get('permissionRoleString').setValue(val); }

	get viewQuartzJobs(): boolean { return this.fg.get('viewQuartzJobs').value; }
	set viewQuartzJobs(val) { this.fg.get('viewQuartzJobs').setValue(val); }

}
