/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { SilkierHistoryVm } from 'app/shared/generated/Silkier/Models/SilkierHistoryVm';
import { TableComponent } from 'app/shared/table/table.component';
import { ViewLogModalComponent } from '../view-log-modal/view-log-modal.component';
import { ViewExceptionModalComponent } from '../view-exception-modal/view-exception-modal.component';

@Component({
    selector: 'pcg-silkier-history',
    templateUrl: './silkier-history.component.html',
    styleUrls: ['./silkier-history.component.scss']
})
export class SilkierHistoryComponent implements OnInit {

    @ViewChild(TableComponent, {static: true}) table: TableComponent<SilkierHistoryVm>;

    columns = SilkierHistoryVm.ColumnDefs;

    constructor(
        public title: Title
        , public ms:SystemMessageService
        , public modalService: NgbModal
    ) { title.setTitle('Silkier History'); }

    ngOnInit(): void { }

    viewLogMessage(id: number){
        const modal = this.modalService.open(ViewLogModalComponent, {size: <any>'xl', animation: false});
        (<ViewLogModalComponent>modal.componentInstance).id = id;
    }

    viewExceptionMessage(id: number){
        const modal = this.modalService.open(ViewExceptionModalComponent, {size: <any>'xl', animation: false});
        (<ViewExceptionModalComponent>modal.componentInstance).id = id;
    }
}
