import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CustomerAliasListVm } from '../../shared/generated/Customers/Models/CustomerAliasListVm'

@Component({
  selector: 'pcg-customer-alias-list',
  templateUrl: './customer-alias-list.component.html',
  styleUrl: './customer-alias-list.component.scss'
})

export class CustomerAliasListComponent {
  @Input() customerId: number;
  columns = CustomerAliasListVm.ColumnDefs;
  dataSource: string = "api/Customers/Customers/GetCustomerAliasList/";

  constructor() { }

  async ngOnInit(): Promise<void> {
    this.dataSource += this.customerId;
  }
}
