export interface ITransactionEditTbl {
	transactionId: number;
	importHistoryId?: number;
	fileSource?: string;
	importedDate: Date;
	importedDateString?: string;
	salesRegionId?: number;
	distributorName?: string;
	transactionSource?: string;
	billToCustomerId?: number;
	shipToCustomerId?: number;
	transactionDate: Date;
	transactionDateString?: string;
	transactionNumber?: string;
	productGroup?: string;
	partsNumber?: string;
	salesAmount?: number;
	quantity?: number;
	unitSalesPrice?: number;
	listPrice?: number;
	percentDiscount?: number;
	commissionAmount?: number;
	commissionBracket?: string;
	commissionSummary?: string;
	compSegment?: string;
	customerTier?: string;
	gpoAffiliation?: string;
	gpoStatus?: string;
};

export class TransactionEditTbl implements ITransactionEditTbl {
	transactionId = 0;
	importHistoryId = 0;
	fileSource = '';
	importedDate = new Date();
	importedDateString = '';
	salesRegionId = 0;
	distributorName = '';
	transactionSource = '';
	billToCustomerId = 0;
	shipToCustomerId = 0;
	transactionDate = new Date();
	transactionDateString = '';
	transactionNumber = '';
	productGroup = '';
	partsNumber = '';
	salesAmount = 0;
	quantity = 0;
	unitSalesPrice = 0;
	listPrice = 0;
	percentDiscount = 0;
	commissionAmount = 0;
	commissionBracket = '';
	commissionSummary = '';
	compSegment = '';
	customerTier = '';
	gpoAffiliation = '';
	gpoStatus = '';
};
