<a 
	*ngIf="hasAccess && !openNewWindow" 
	(click)="onClick()" 
	[routerLink]="url" 
	tabindex="-1"
>
	<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>
<a
	*ngIf="hasAccess && openNewWindow"
	(click)="onClick()"
	[routerLink]="url"
	tabindex="-1"
	target="_blank"
	rel="noopener noreferrer"
>
	<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>
<span *ngIf="!hasAccess">
	<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</span>
<ng-template #contentTpl><ng-content></ng-content></ng-template>
