<div class="clearfix mt-3">
	<div class="float-left">
		<h1>{{ moduleName + ' - ' + name }}</h1>
		<br />
		{{ description }}
	</div>
</div>
<hr />
<div class="row mt-3">
	<div class="col-md-8 offset-md-2">
		<div class="embed-responsive embed-responsive-16by9 mb-3">
			<!-- the safe pipe uses the dom sanitizer  -->
			<iframe *ngIf="url" class="embed-responsive-item" [src]="url | safe" allowfullscreen></iframe>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-md-12 text-center">
		<a class="btn btn-cancel btn-lg" routerLink="/help">Back</a>
	</div>
</div>
