import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'pcg-error404',
	templateUrl: './error404.component.html',
	styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {

	constructor(public title: Title) { title.setTitle('Error 404'); }

	ngOnInit() { }
}
