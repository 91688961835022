import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class CustomerImportExceptionVm {
	customerImportExceptionsId: number;
	customerId: number;
	masterCustomer?: string;
	masterName?: string;
	masterStreetAddress?: string;
	masterCity?: string;
	masterState?: string;
	masterZipCode?: string;
	importedCustomer?: string;
	importedName?: string;
	importedStreetAddress?: string;
	importedCity?: string;
	importedState?: string;
	importedZipCode?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'customerImportExceptionsId': new UntypedFormControl(0, [...(validators['customerImportExceptionsId'] ?? []), ...[Validators.required]]),
			'customerId': new UntypedFormControl(0, [...(validators['customerId'] ?? []), ...[Validators.required]]),
			'masterCustomer': new UntypedFormControl(null, [...(validators['masterCustomer'] ?? []), ...[]]),
			'masterName': new UntypedFormControl(null, [...(validators['masterName'] ?? []), ...[]]),
			'masterStreetAddress': new UntypedFormControl(null, [...(validators['masterStreetAddress'] ?? []), ...[]]),
			'masterCity': new UntypedFormControl(null, [...(validators['masterCity'] ?? []), ...[]]),
			'masterState': new UntypedFormControl(null, [...(validators['masterState'] ?? []), ...[]]),
			'masterZipCode': new UntypedFormControl(null, [...(validators['masterZipCode'] ?? []), ...[]]),
			'importedCustomer': new UntypedFormControl(null, [...(validators['importedCustomer'] ?? []), ...[]]),
			'importedName': new UntypedFormControl(null, [...(validators['importedName'] ?? []), ...[]]),
			'importedStreetAddress': new UntypedFormControl(null, [...(validators['importedStreetAddress'] ?? []), ...[]]),
			'importedCity': new UntypedFormControl(null, [...(validators['importedCity'] ?? []), ...[]]),
			'importedState': new UntypedFormControl(null, [...(validators['importedState'] ?? []), ...[]]),
			'importedZipCode': new UntypedFormControl(null, [...(validators['importedZipCode'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['masterCustomer', {"orderId":0,"sortColumn":"masterCustomer","searchColumn":"masterCustomer","includeInExcel":true,"exportColumn":null,"displayName":"Master Customer","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['importedCustomer', {"orderId":1,"sortColumn":"importedCustomer","searchColumn":"importedCustomer","includeInExcel":true,"exportColumn":null,"displayName":"Imported Customer","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['update', {"orderId":2,"sortColumn":"update","searchColumn":"update","includeInExcel":false,"exportColumn":null,"displayName":"Update","isVisible":true,"canSearch":false,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['ignore', {"orderId":3,"sortColumn":"ignore","searchColumn":"ignore","includeInExcel":false,"exportColumn":null,"displayName":"Ignore","isVisible":true,"canSearch":false,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): CustomerImportExceptionVmFromFg {
		return new CustomerImportExceptionVmFromFg(fg);
	}
}

export class CustomerImportExceptionVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get customerImportExceptionsId(): number { return this.fg.get('customerImportExceptionsId').value; }
	set customerImportExceptionsId(val) { this.fg.get('customerImportExceptionsId').setValue(val); }

	get customerId(): number { return this.fg.get('customerId').value; }
	set customerId(val) { this.fg.get('customerId').setValue(val); }

	get masterCustomer(): string { return this.fg.get('masterCustomer').value; }
	set masterCustomer(val) { this.fg.get('masterCustomer').setValue(val); }

	get masterName(): string { return this.fg.get('masterName').value; }
	set masterName(val) { this.fg.get('masterName').setValue(val); }

	get masterStreetAddress(): string { return this.fg.get('masterStreetAddress').value; }
	set masterStreetAddress(val) { this.fg.get('masterStreetAddress').setValue(val); }

	get masterCity(): string { return this.fg.get('masterCity').value; }
	set masterCity(val) { this.fg.get('masterCity').setValue(val); }

	get masterState(): string { return this.fg.get('masterState').value; }
	set masterState(val) { this.fg.get('masterState').setValue(val); }

	get masterZipCode(): string { return this.fg.get('masterZipCode').value; }
	set masterZipCode(val) { this.fg.get('masterZipCode').setValue(val); }

	get importedCustomer(): string { return this.fg.get('importedCustomer').value; }
	set importedCustomer(val) { this.fg.get('importedCustomer').setValue(val); }

	get importedName(): string { return this.fg.get('importedName').value; }
	set importedName(val) { this.fg.get('importedName').setValue(val); }

	get importedStreetAddress(): string { return this.fg.get('importedStreetAddress').value; }
	set importedStreetAddress(val) { this.fg.get('importedStreetAddress').setValue(val); }

	get importedCity(): string { return this.fg.get('importedCity').value; }
	set importedCity(val) { this.fg.get('importedCity').setValue(val); }

	get importedState(): string { return this.fg.get('importedState').value; }
	set importedState(val) { this.fg.get('importedState').setValue(val); }

	get importedZipCode(): string { return this.fg.get('importedZipCode').value; }
	set importedZipCode(val) { this.fg.get('importedZipCode').setValue(val); }

}
