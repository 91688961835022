import { Injectable } from '@angular/core';
import Dexie, { TableSchema } from 'dexie';

import { IDexieTableSchema, ITableSchema, } from '../local-db-interfaces/ldb.interface';
import { LoadedStores } from '../tables/loaded.store';
import { DBStores } from './ldb.store.model';
import { IAuditHistoryTbl } from '../tables/Models/AuditHistoryTbl';
import { IReleaseNotesTbl } from '../tables/Models/ReleaseNotesTbl';
import { IImportHistoryListTbl } from '../tables/Transactions/Models/ImportHistoryListTbl';
import { ITransactionEditTbl } from '../tables/Transactions/Models/TransactionEditTbl';
import { ITransactionListFiltersTbl } from '../tables/Transactions/Models/TransactionListFiltersTbl';
import { ITransactionListTbl } from '../tables/Transactions/Models/TransactionListTbl';
import { ICustomersFromImportTbl } from '../tables/Transactions/Models/Upload/CustomersFromImportTbl';
import { IOssurUploadTbl } from '../tables/Transactions/Models/Upload/OssurUploadTbl';
import { ISalesRegionFromImportTbl } from '../tables/Transactions/Models/Upload/SalesRegionFromImportTbl';
import { IUploadFileTbl } from '../tables/Transactions/Models/Upload/UploadFileTbl';
import { ICustomerFileUploadTbl } from '../tables/Transactions/Models/Upload/CustomerFileUploadTbl';
import { IExceptionListFiltersTbl } from '../tables/Transactions/Models/Exceptions/ExceptionListFiltersTbl';
import { IExceptionListTbl } from '../tables/Transactions/Models/Exceptions/ExceptionListTbl';
import { ISilkierHistoryTbl } from '../tables/Silkier/Models/SilkierHistoryTbl';
import { ISilkierLogMessageTbl } from '../tables/Silkier/Models/SilkierLogMessageTbl';
import { ISilkierExceptionMessageTbl } from '../tables/Silkier/Models/SilkierExceptionMessageTbl';
import { IEditSalesRepTbl } from '../tables/Sales/Models/EditSalesRepTbl';
import { IEditSalesTerritoryTbl } from '../tables/Sales/Models/EditSalesTerritoryTbl';
import { ISalesRepListTbl } from '../tables/Sales/Models/SalesRepListTbl';
import { ISalesTerritoryListTbl } from '../tables/Sales/Models/SalesTerritoryListTbl';
import { IHelpFilesTbl } from '../tables/HelpFiles/Models/HelpFilesTbl';
import { ICustomerAliasListTbl } from '../tables/Customers/Models/CustomerAliasListTbl';
import { ICustomerEditTbl } from '../tables/Customers/Models/CustomerEditTbl';
import { ICustomerImportExceptionTbl } from '../tables/Customers/Models/CustomerImportExceptionTbl';
import { ICustomerListApprovalTbl } from '../tables/Customers/Models/CustomerListApprovalTbl';
import { ICustomerListTbl } from '../tables/Customers/Models/CustomerListTbl';
import { ICustomerSalesRepTbl } from '../tables/Customers/Models/CustomerSalesRepTbl';
import { IGlobalAuditHistoryTbl } from '../tables/Administration/Models/GlobalAuditHistoryTbl';
import { ILoginHistoryTbl } from '../tables/Administration/Models/LoginHistoryTbl';
import { IReleaseNoteTbl } from '../tables/Administration/Models/ReleaseNoteTbl';
import { IEditUserTbl } from '../tables/Administration/Models/User/EditUser/EditUserTbl';
import { IDowntimeNotificationTbl } from '../tables/Administration/Models/DowntimeNotification/DowntimeNotificationTbl';

@Injectable({
	providedIn: 'root'
})
export class AppDatabase extends Dexie {

	LoadedStores!: Dexie.Table<LoadedStores, number>;
	AuditHistoryTbl!: Dexie.Table<IAuditHistoryTbl, string>;
	ReleaseNotesTbl!: Dexie.Table<IReleaseNotesTbl, string>;
	ImportHistoryListTbl!: Dexie.Table<IImportHistoryListTbl, string>;
	TransactionEditTbl!: Dexie.Table<ITransactionEditTbl, string>;
	TransactionListFiltersTbl!: Dexie.Table<ITransactionListFiltersTbl, string>;
	TransactionListTbl!: Dexie.Table<ITransactionListTbl, string>;
	CustomersFromImportTbl!: Dexie.Table<ICustomersFromImportTbl, string>;
	OssurUploadTbl!: Dexie.Table<IOssurUploadTbl, string>;
	SalesRegionFromImportTbl!: Dexie.Table<ISalesRegionFromImportTbl, string>;
	UploadFileTbl!: Dexie.Table<IUploadFileTbl, string>;
	CustomerFileUploadTbl!: Dexie.Table<ICustomerFileUploadTbl, string>;
	ExceptionListFiltersTbl!: Dexie.Table<IExceptionListFiltersTbl, string>;
	ExceptionListTbl!: Dexie.Table<IExceptionListTbl, string>;
	SilkierHistoryTbl!: Dexie.Table<ISilkierHistoryTbl, string>;
	SilkierLogMessageTbl!: Dexie.Table<ISilkierLogMessageTbl, string>;
	SilkierExceptionMessageTbl!: Dexie.Table<ISilkierExceptionMessageTbl, string>;
	EditSalesRepTbl!: Dexie.Table<IEditSalesRepTbl, string>;
	EditSalesTerritoryTbl!: Dexie.Table<IEditSalesTerritoryTbl, string>;
	SalesRepListTbl!: Dexie.Table<ISalesRepListTbl, string>;
	SalesTerritoryListTbl!: Dexie.Table<ISalesTerritoryListTbl, string>;
	HelpFilesTbl!: Dexie.Table<IHelpFilesTbl, string>;
	CustomerAliasListTbl!: Dexie.Table<ICustomerAliasListTbl, string>;
	CustomerEditTbl!: Dexie.Table<ICustomerEditTbl, string>;
	CustomerImportExceptionTbl!: Dexie.Table<ICustomerImportExceptionTbl, string>;
	CustomerListApprovalTbl!: Dexie.Table<ICustomerListApprovalTbl, string>;
	CustomerListTbl!: Dexie.Table<ICustomerListTbl, string>;
	CustomerSalesRepTbl!: Dexie.Table<ICustomerSalesRepTbl, string>;
	GlobalAuditHistoryTbl!: Dexie.Table<IGlobalAuditHistoryTbl, string>;
	LoginHistoryTbl!: Dexie.Table<ILoginHistoryTbl, string>;
	ReleaseNoteTbl!: Dexie.Table<IReleaseNoteTbl, string>;
	EditUserTbl!: Dexie.Table<IEditUserTbl, string>;
	DowntimeNotificationTbl!: Dexie.Table<IDowntimeNotificationTbl, string>;
	versionNumber: number = 1;
	private dbName: string = 'local-db-app';

	constructor() {
		super('local-db-app');
		this.setLocalDbTable();
		this.seedData();
	}

	setLocalDbTable() {
		this.version(this.versionNumber).stores(this.setTablesSchema());
		console.log('database initialized');
		this.AuditHistoryTbl = this.table(DBStores.AuditHistoryTbl.TableName);
		this.ReleaseNotesTbl = this.table(DBStores.ReleaseNotesTbl.TableName);
		this.ImportHistoryListTbl = this.table(DBStores.ImportHistoryListTbl.TableName);
		this.TransactionEditTbl = this.table(DBStores.TransactionEditTbl.TableName);
		this.TransactionListFiltersTbl = this.table(DBStores.TransactionListFiltersTbl.TableName);
		this.TransactionListTbl = this.table(DBStores.TransactionListTbl.TableName);
		this.CustomersFromImportTbl = this.table(DBStores.CustomersFromImportTbl.TableName);
		this.OssurUploadTbl = this.table(DBStores.OssurUploadTbl.TableName);
		this.SalesRegionFromImportTbl = this.table(DBStores.SalesRegionFromImportTbl.TableName);
		this.UploadFileTbl = this.table(DBStores.UploadFileTbl.TableName);
		this.CustomerFileUploadTbl = this.table(DBStores.CustomerFileUploadTbl.TableName);
		this.ExceptionListFiltersTbl = this.table(DBStores.ExceptionListFiltersTbl.TableName);
		this.ExceptionListTbl = this.table(DBStores.ExceptionListTbl.TableName);
		this.SilkierHistoryTbl = this.table(DBStores.SilkierHistoryTbl.TableName);
		this.SilkierLogMessageTbl = this.table(DBStores.SilkierLogMessageTbl.TableName);
		this.SilkierExceptionMessageTbl = this.table(DBStores.SilkierExceptionMessageTbl.TableName);
		this.EditSalesRepTbl = this.table(DBStores.EditSalesRepTbl.TableName);
		this.EditSalesTerritoryTbl = this.table(DBStores.EditSalesTerritoryTbl.TableName);
		this.SalesRepListTbl = this.table(DBStores.SalesRepListTbl.TableName);
		this.SalesTerritoryListTbl = this.table(DBStores.SalesTerritoryListTbl.TableName);
		this.HelpFilesTbl = this.table(DBStores.HelpFilesTbl.TableName);
		this.CustomerAliasListTbl = this.table(DBStores.CustomerAliasListTbl.TableName);
		this.CustomerEditTbl = this.table(DBStores.CustomerEditTbl.TableName);
		this.CustomerImportExceptionTbl = this.table(DBStores.CustomerImportExceptionTbl.TableName);
		this.CustomerListApprovalTbl = this.table(DBStores.CustomerListApprovalTbl.TableName);
		this.CustomerListTbl = this.table(DBStores.CustomerListTbl.TableName);
		this.CustomerSalesRepTbl = this.table(DBStores.CustomerSalesRepTbl.TableName);
		this.GlobalAuditHistoryTbl = this.table(DBStores.GlobalAuditHistoryTbl.TableName);
		this.LoginHistoryTbl = this.table(DBStores.LoginHistoryTbl.TableName);
		this.ReleaseNoteTbl = this.table(DBStores.ReleaseNoteTbl.TableName);
		this.EditUserTbl = this.table(DBStores.EditUserTbl.TableName);
		this.DowntimeNotificationTbl = this.table(DBStores.DowntimeNotificationTbl.TableName);
	}

	seedData() {
		this.on('populate', async () => { await this.LoadedStores.add(new LoadedStores()); });
	}

	async migrateDB() {
		if (await Dexie.exists(this.dbName)) {
			const declaredSchema = this.getCanonicalComparableSchema(this);
			const dynDb = new Dexie(this.dbName);
			const installedSchema = await dynDb.open().then(this.getCanonicalComparableSchema);
			dynDb.close();
			if (declaredSchema !== installedSchema) {
				console.log('Db schema is not updated, so deleting the db.');
				await this.clearDB();
			}
		}
	}

	getCanonicalComparableSchema(db: Dexie): string {
		const tableSchemas: ITableSchema[] = db.tables.map((table) => this.getTableSchema(table));
		return JSON.stringify(tableSchemas.sort((a, b) => (a.name < b.name ? 1 : -1)));
	}

	getTableSchema(table: { name: string; schema: IDexieTableSchema; }): ITableSchema {
		const { name, schema } = table;
		const indexSources = schema.indexes.map((idx) => idx.src).sort();
		const schemaString = [schema.primKey.src, ...indexSources].join(',');
		return { name, schema: schemaString };
	}

	async clearDB() {
		console.log('deleting DB...');
		this.close();
		await this.delete();
		await this.open();
		console.log('DB deleted.');
	}

	private setTablesSchema() {
		return Object.entries(DBStores).reduce((tables, [key, value]) => {
			tables[value.TableName] = value.Columns;
			return tables;
		}, {} as Record<string, string>);
	}

}
