import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ViewLogModalService {

    constructor(private httpClient: HttpClient) { }

    getLog(httpParams: HttpParams) { return this.httpClient.get('api/Silkier/SilkierHistory/GetLog', { params: httpParams }); }
}
