export class LoadedStores {
	Id: number;
	AuditHistoryTbl!: boolean;
	ReleaseNotesTbl!: boolean;
	ImportHistoryListTbl!: boolean;
	TransactionEditTbl!: boolean;
	TransactionListFiltersTbl!: boolean;
	TransactionListTbl!: boolean;
	CustomersFromImportTbl!: boolean;
	OssurUploadTbl!: boolean;
	SalesRegionFromImportTbl!: boolean;
	UploadFileTbl!: boolean;
	CustomerFileUploadTbl!: boolean;
	ExceptionListFiltersTbl!: boolean;
	ExceptionListTbl!: boolean;
	SilkierHistoryTbl!: boolean;
	SilkierLogMessageTbl!: boolean;
	SilkierExceptionMessageTbl!: boolean;
	EditSalesRepTbl!: boolean;
	EditSalesTerritoryTbl!: boolean;
	SalesRepListTbl!: boolean;
	SalesTerritoryListTbl!: boolean;
	HelpFilesTbl!: boolean;
	CustomerAliasListTbl!: boolean;
	CustomerEditTbl!: boolean;
	CustomerImportExceptionTbl!: boolean;
	CustomerListApprovalTbl!: boolean;
	CustomerListTbl!: boolean;
	CustomerSalesRepTbl!: boolean;
	GlobalAuditHistoryTbl!: boolean;
	LoginHistoryTbl!: boolean;
	ReleaseNoteTbl!: boolean;
	EditUserTbl!: boolean;
	DowntimeNotificationTbl!: boolean;

	constructor() {
		this.Id = 1;
		this.AuditHistoryTbl = false;
		this.ReleaseNotesTbl = false;
		this.ImportHistoryListTbl = false;
		this.TransactionEditTbl = false;
		this.TransactionListFiltersTbl = false;
		this.TransactionListTbl = false;
		this.CustomersFromImportTbl = false;
		this.OssurUploadTbl = false;
		this.SalesRegionFromImportTbl = false;
		this.UploadFileTbl = false;
		this.CustomerFileUploadTbl = false;
		this.ExceptionListFiltersTbl = false;
		this.ExceptionListTbl = false;
		this.SilkierHistoryTbl = false;
		this.SilkierLogMessageTbl = false;
		this.SilkierExceptionMessageTbl = false;
		this.EditSalesRepTbl = false;
		this.EditSalesTerritoryTbl = false;
		this.SalesRepListTbl = false;
		this.SalesTerritoryListTbl = false;
		this.HelpFilesTbl = false;
		this.CustomerAliasListTbl = false;
		this.CustomerEditTbl = false;
		this.CustomerImportExceptionTbl = false;
		this.CustomerListApprovalTbl = false;
		this.CustomerListTbl = false;
		this.CustomerSalesRepTbl = false;
		this.GlobalAuditHistoryTbl = false;
		this.LoginHistoryTbl = false;
		this.ReleaseNoteTbl = false;
		this.EditUserTbl = false;
		this.DowntimeNotificationTbl = false;
	}
};
