<label 
	*ngIf="label" 
	[ngbTooltip]="labelTooltip" 
	[ngClass]="{ 'required-field': isRequired && !hideRequired }" 
	[for]='controlId' 
	class="control-label for"
>{{label}}</label>
<ng-content></ng-content>
<ng-container *ngIf='isError'>
	<span 
		class="text-danger" 
		*ngFor="let msg of errorMessages"
	>{{msg}}</span>
</ng-container>
