/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { faU, faUnlock, faUnlockAlt } from '@fortawesome/pro-solid-svg-icons';

import { EditUserVm } from 'app/shared/generated/Administration/Models/User/EditUser/EditUserVm';
import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { SecurityService } from 'app/core/security/security.service';
import { UserChangePasswordModalComponent } from './user-change-password-modal/user-change-password-modal.component';
import { ActivationService } from 'app/shared/services/activation.service';
import { ActivationEnum } from 'app/core/enums/generated/ActivationEnum';
import { UserPermissionsModalComponent } from './user-permissions-modal/user-permissions-modal.component';
import { CheckNetworkService } from 'local-db/services/check-network.service';
import { AppDatabase } from 'local-db/services/init.ldb.service';
import { SetLocaldbTablesService } from 'app/services/service-workers/set-localdb-tables.service' 
import { GlobalVariablesService } from 'app/services/global-variables.service';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'pcg-edit-user',
	templateUrl: './edit-user.component.html',
	styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {

	@ViewChild('tabset', { read: NgbNav }) tabs: NgbNav;

	id: number;
	
	subscriptions = new Subscription();
	formGroup = EditUserVm.Form;
	model = EditUserVm.GetModel(this.formGroup);
	model$: Observable<EditUserVm>;
	
	faIconName = { faUnlock, faUnlockAlt, faUser };
	
	userNameValidation = { pattern: "Only letters, numbers, and symbols ( . @ - _ ' ) are allowed" };
	fullNameValidation = { pattern: "Only letters and dashes ( - ) are allowed" };

	isLockedOut = false;
	canManageUsers = false;
	canChangePermissions = false;
	canSeePasswordButtons = false;
	isCurrentUser = false;
	
	activeTab: string;
	isOnline: boolean;

	constructor(
		private route: ActivatedRoute
		, private httpClient: HttpClient
		, public router: Router
		, private modalService: NgbModal
		, private ms: SystemMessageService
		, private nav: NavigationService
		, title: Title
		, private sec: SecurityService
		, private activationService: ActivationService
		, private localDb: AppDatabase
		, private localDbService: SetLocaldbTablesService
		, private globalVariablesService: GlobalVariablesService
	) { title.setTitle('Edit User'); }

	openWindow = (o: string) => { this.router.navigateByUrl('/' + o); };	

	async ngOnInit(): Promise<void> {
		this.isOnline = CheckNetworkService.checkNetworkStatus();
		this.id = Number(this.route.snapshot.paramMap.get('id'));	
		await this.addSecuritySubscription();
		await this.addRouteSubscription();
		this.nav.setOpenSecondaryMenu(['Edit User', 'Details']);
	}

	/** Update the local dynamic security object when the security updates */
	async addSecuritySubscription(): Promise<void> {
		this.subscriptions.add(
			this.sec.security$.subscribe(() => {
				this.canManageUsers = this.sec.hasAccess(o => o.manageUsers);
				this.canChangePermissions = this.sec.hasAccess(o => o.canChangePermissions);
				this.sec.setControlAccess(this.formGroup.controls['permissionId'], o => o.canChangePermissions);
			})
		);
	}

	/** Subscribe to route to pick up id changes and update page accordingly */
	async addRouteSubscription(): Promise<void> {
		this.subscriptions.add(
			this.route.paramMap.subscribe(async map => {
				// Allow user to view page if it is either their profile or they have admin access to view other people's profiles
				const user = this.sec.getUser();
				this.id = parseInt(map.get('id'), 10);
				if (!this.canManageUsers && this.id !== user.id) { this.router.navigateByUrl('workflow/1'); }
				this.isCurrentUser = this.id === this.sec.getUser().id;

				if (this.isOnline) {
					this.subscriptions.add(this.httpClient.get(`api/Administration/Users/GetUser/${this.id}`)
					.subscribe((model: EditUserVm) => {
						this.formGroup.patchValue(model);
						this.globalVariablesService.userName.next(model.fullName);
						this.canSeePasswordButtons = model.isActive 
							&& !model.isLockedOut 
							&& model.userId !== this.sec.getUser().id 
							&& model.userId !== 0;
						this.isLockedOut = model.isLockedOut;
					}));
				} else {
					this.grabUserFromLocalDb();
				}
		
				/* 
				let userCache = (await this.dataService.getAsync(
					DBStores.EditUserTbl.TableName
					, `api/Administration/Users/GetUser?id=${this.id}`
					, undefined
					, this.id
				)) as IEditUserTbl[]; 

				const vm = userCache;
				this.formGroup.patchValue(vm);
				*/
			})
		);
	}

	/** Set the navigation that sits under the main navigation */
	setSecondaryNav() { this.nav.setOpenSecondaryMenu(['Admin', 'Users', 'User List']); }

	/** The main save event for the page */
	saveUser(msg: SystemMessage) {
		if (!msg.value) { return; }
		const model = this.formGroup.value;
		this.id = model.userId;
		this.canSeePasswordButtons = model.isActive && !model.isLockedOut && model.userId !== this.sec.getUser().id && model.userId !== 0;
		this.sec.setSecurity(undefined, msg.value.userJsVm, msg.value.dynamicSecurity);
		if (msg.isSuccessful) {
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { this.router.navigate([`administration/users/edit-user/${this.id}`]); });		
		}
	}

	grabUserFromLocalDb() {
		// get data from localDb if offline and there is something in the localDb
		this.localDb.table("EditUserTbl").toArray()
			.then((response) => { 
				if (response.length > 0) {
					let filteredResponse = response.filter(o => o.userId === this.id && this.id !== 0);
					if (filteredResponse.length > 0) { 
						this.formGroup.patchValue(filteredResponse[filteredResponse.length - 1]);
						this.globalVariablesService.userName.next(filteredResponse[filteredResponse.length - 1].fullName);
					} else { // means a new user is being created offline
						this.formGroup.controls.isActive.setValue(true);
					}	
				}
			});
	}

	/** Unlock user, called when the unlock svg clicked on locked user */
	unlockUser() {
		this.subscriptions.add(this.ms.getHttpObservable(this, `api/Administration/Users/UnlockUser/${this.id}`)
			.subscribe(() => { this.isLockedOut = false; }));
	}

	/** Called on click of the reset password and welcome email buttons */
	resetPassword(isWelcomeEmail: boolean) {
		this.subscriptions.add(this.httpClient.post('api/Account/ForgotPassword', {
				email: this.formGroup.value.email,
				isWelcomeEmail
			}).subscribe((msg: SystemMessage) => {
				if (msg.isSuccessful) {
					this.ms.setSystemMessage(`An email has been sent to the user with a ${isWelcomeEmail ? 'welcome' : 'reset password'} link.`
						, undefined
						, 3000
					);
				} else { this.ms.setSystemMessage('An error occurred when attempting to send the email.', 'error'); }
			}));
	}

	deleteUser() {
		if(confirm("Are you sure you want to delete this user? This cannot be undone.")) {
			this.subscriptions.add(this.httpClient.get(`api/Administration/Users/DeleteUser/${this.id}`)
				.subscribe((msg: SystemMessage) => {
					if (msg.isSuccessful) {
						this.router.navigate(['administration/users']);
						this.ms.setSystemMessage('User has been succuessfully deleted.');
					}									
				}));
		}
	}

	changePermissions() {
		if (this.id === 0) { return; }
		this.subscriptions.add(this.ms.getHttpObservable(this, 'api/Administration/Users/SaveUser', this.formGroup).subscribe());
	}

	/** Opens the change password modal.
	 * Called on click of the Change Passoword button when user looking at their profile. */
	openChangePasswordModal() { this.modalService.open(UserChangePasswordModalComponent); }
	openPermissionsInfoModal() { this.modalService.open(UserPermissionsModalComponent, { size: 'xl' }); }
	activateDeactivate() { this.activationService.activateDeactivate(this.id, ActivationEnum.User, this.formGroup.value.isActive ? false : true); }
	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
