import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { getAdminNav } from './nav-areas/admin-nav';
import { usersNav } from './nav-areas/users-nav';
import { getHelpFilesNav } from './nav-areas/help-files-nav'
import { getCustomersNav } from './nav-areas/customers-nav'
import { getSalesRepNav } from './nav-areas/sales-representative-nav'
import { getTransactionNav } from './nav-areas/transactions-nav'
import { getTerritoriesNav } from './nav-areas/territories-nav'


export const getMainNavRoutes: () => NavRoute[] = function () {
	return [
		getAdminNav(),
		usersNav(),
		getHelpFilesNav(),
		getCustomersNav(),
		getSalesRepNav(),
		getTransactionNav(),
		getTerritoriesNav()
	];
};
