<br />
<img alt="Innova" class="system-logo" src="assets/images/logos/logo_transparent.png" />
<br />
<div class="forgot-password-container clearfix">
	<form *ngIf="!checkEmail" (ngSubmit)="onSubmit()" [formGroup]="forgotPasswordForm">
		<h2>Forgot Password</h2>
		<div class="row">
			<pcg-control-group class="col-sm-12" label="Email">
				<input 
					pcgControlRef 
					formControlName="email" 
					type="text" 
					class="form-control email" 
				/>
			</pcg-control-group>
		</div>
		<button type="submit" class="btn btn-save btn-lg">Send Email</button>
	</form>
	
	<div *ngIf="checkEmail">
		<h2>Forgot Password Confirmation</h2>
		<p>
			Your email was submitted successfully.
			<br /><br />
			If you do not receive an email from us shortly, please verify that you entered the correct email.
			<br /><br />
			If you continue to have issues, contact us at
			<a href="mailto:admin@innovasurgical.com">support&#64;paulconsulting.com</a>
		</p>
	</div>
	<a class="float-right" routerLink="/login">Back to Login</a>
</div>
