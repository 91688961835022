import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { QuicklinkModule } from 'ngx-quicklink';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from 'rollbar_config';
import { CustomerListComponent } from './Customers/customer-list/customer-list.component';

/**
 * This is the first module loaded. It bootstraps
 * the rest of the application.
 */
@NgModule({
	declarations: [ AppComponent ],
	imports: [
		BrowserModule.withServerTransition({ appId: 'ng-cli-universal' })
		, CoreModule
		, QuicklinkModule
		, BrowserAnimationsModule
		, ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: true
			, registrationStrategy: 'registerImmediately'
		})
	],
	providers: [
		{ 
			provide: ErrorHandler
			, useClass: RollbarErrorHandler 
		},
		{ 
			provide: RollbarService
			, useFactory: rollbarFactory 
		}
	],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
