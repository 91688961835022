import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
	return document.getElementsByTagName('base')[0].href;
}

const providers = [
	{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
	enableProdMode();
}

// Get the JSON web token from local storage
const jwt = localStorage.getItem('jwt') && localStorage.getItem('jwt').substr(0, 1) !== '"' ? localStorage.getItem('jwt')
	: JSON.parse(localStorage.getItem('jwt'));

// If we have a JSON web token, send AJAX request to get user information
// before bootstrapping Angular
if (jwt) {
	const xhr = new XMLHttpRequest();
	xhr.open('POST', getBaseUrl() + 'api/Account/GetUserInfo');
	xhr.setRequestHeader('Content-Type', 'application/json');
	xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);
	xhr.onload = function () {
		if (xhr.status === 200) {
			const model = JSON.parse(xhr.response);
			if (model.user === null) {
				localStorage.removeItem('jwt');
			}
			localStorage.setItem('dynamicSecurity', JSON.stringify(model.security));
			localStorage.setItem('user', JSON.stringify(model.user));
			localStorage.setItem('jwt', JSON.stringify(jwt));
		}
		bootstrapAngular();
	};
	xhr.send();
}
// If we don't have a token, just bootstrap Angular
else {
	localStorage.removeItem('dynamicSecurity');
	localStorage.removeItem('user');
	bootstrapAngular();
}

function bootstrapAngular() {
	platformBrowserDynamic(providers).bootstrapModule(AppModule)
		.catch(err => console.log(err));
}
