<form 
	(ngSubmit)="changePassword()" 
	[formGroup]="changePasswordForm"
>
	<div class="modal-header">
		<h5 class="modal-title" id="modal-basic-title">Change Password</h5>
		<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	
	<div class="modal-body">
		<div class="text-center">
			<u>Password Requirements</u><br/>
			Must be at least 10 characters in Length.<br/>
			Cannot match any of the passwords in our list of commonly used passwords.<br/>
		</div>
		<pcg-show-hide-password 
			[controlName]="'currentPassword'" 
			[parentForm]="changePasswordForm" 
			label="Current Password" 
			[validations]='{ "incorrectPassword": "Password is incorrect." }' 
			inputCssClass="form-control"
		></pcg-show-hide-password>

		<pcg-show-hide-password 
			[controlName]="'password'" 
			[parentForm]="changePasswordForm" 
			label="New Password"
			[validations]='{ 
				"commonPassword": "Pick a less common password."
				, pattern: "Password cannot contain more than 2 repeating characters."
				, sequentialChars: "Password cannot contain more than 3 sequential characters." 
			}' 
			inputCssClass="form-control"
		></pcg-show-hide-password>

		<pcg-show-hide-password 
			[controlName]="'confirmPassword'" 
			[parentForm]="changePasswordForm" 
			label="Confirm New Password"
			[validations]='{ "passwordsNotEqual": "Passwords do not match." }' 
			inputCssClass="form-control"
		></pcg-show-hide-password>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-cancel btn-lg" (click)="activeModal.dismiss()">Cancel</button>
		<button type="submit" class="btn btn-save btn-lg">Change Password</button>
	</div>
</form>
