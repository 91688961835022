<pcg-table #table [dataSource]="dataSource" [pcgSort]="[['name', 'asc']]" [columnDefs]="columns" isUsingMobileCard="true"
	uniqueColumn="name" [canExportTableToExcel]="true"
	deleteUrl="api/Customers/Customers/DeleteCustomerAlias" identificationColumn="customerAliasId"
	confirmMessage="Are you sure you want to delete this alias?">

	<ng-container matColumnDef="fileSource">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('fileSource').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('fileSource').displayName">{{ row.fileSource }}</td>
	</ng-container>

	<ng-container matColumnDef="name">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('name').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('name').displayName">{{ row.name }}</td>
	</ng-container>

	<ng-container matColumnDef="shippingStreetAddress">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('shippingStreetAddress').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('shippingStreetAddress').displayName">{{row.shippingStreetAddress }}</td>
	</ng-container>

	<ng-container matColumnDef="shippingCity">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('shippingCity').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('shippingCity').displayName">{{ row.shippingCity }}</td>
	</ng-container>

	<ng-container matColumnDef="shippingState">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('shippingState').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('shippingState').displayName">{{ row.shippingState }}</td>
	</ng-container>

	<ng-container matColumnDef="shippingZipCode">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('shippingZipCode').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('shippingZipCode').displayName">{{ row.shippingZipCode }}</td>
	</ng-container>

	<ng-container matColumnDef="canDelete">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('canDelete').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('canDelete').displayName">
			<pcg-delete-button [table]='table' [confirmMessage]='"Are you sure you want to delete this alias?"'
				url='api/Customers/Customers/DeleteCustomerAlias' [id]='row.customerAliasId'>
            </pcg-delete-button>
		</td>
	</ng-container>
</pcg-table>