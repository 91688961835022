// In order to keep core.module.ts as small as possible,
// router functionality is kept in this file.

// This is not done with other modules because it is conventient
// to have 'ng generate component' automatically add a component to
// the appropriate module by running it in that directory.

import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './security/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Error404Component } from './pages/error404/error404.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { EditUserComponent } from '../administration/users/edit-user/edit-user.component';
import { EditUserModule } from '../administration/users/edit-user/edit-user.module';
import { HelpFilesComponent } from '../help/help-files.component';
import { HelpVideosComponent } from '../help/help-videos/help-videos.component';
import { ReleaseNotesComponent } from './pages/release-notes/release-notes.component';
import { PermissionConst } from './security/generated/Permission';
import { SilkierHistoryComponent } from 'app/silkier/silkier-history/silkier-history.component';
import { UsersComponent } from 'app/administration/users/user-list/users.component';
import { TransactionListComponent } from 'app/transactions/transaction-list/transaction-list.component';
import { SalesRepresentativesComponent } from 'app/sales/sales-representatives/sales-representatives.component';
import { EditSalesRepresentativeComponent } from 'app/sales/sales-representatives/edit-sales-representative/edit-sales-representative.component';
import { TerritoriesComponent } from 'app/sales/territories/territories.component';
import { EditTerritoryComponent } from 'app/sales/territories/edit-territory/edit-territory.component';
import { WorkflowComponent } from '../workflow/workflow.component'

// This is the root of our navigation menu route definitions
const routes: Routes = [
	{ 
		path: ''
		, component: HomeComponent
		, pathMatch: 'full' 
	},
	{ 
		path: 'dashboard'
		, component: DashboardComponent
		, canActivate: [ AuthGuard ] 
	},
	{ 
		path: 'login'
		, component: LoginComponent 
	},
	{ 
		path: 'sales-representatives'
		, component: SalesRepresentativesComponent 
	},
	{ 
		path: 'sales-representatives/edit-sales-representative/:id'
		, component: EditSalesRepresentativeComponent 
	},
	{ 
		path: 'territories'
		, component: TerritoriesComponent
	},
	{ 
		path: 'territories/edit-territory/:id'
		, component: EditTerritoryComponent
	},
	{
		path: 'users'
		, component: UsersComponent 
	},
	{ 
		path: 'release-notes'
		, component: ReleaseNotesComponent
		, canActivate: [ AuthGuard ] 
	},
	{ 
		path: 'edit-user/:id'
		, component: EditUserComponent
		, canActivate: [ AuthGuard ] 
	},
	{ 
		path: 'forgot-password'
		, component: ForgotPasswordComponent 
	},
	{ 
		path: 'change-password/:id'
		, component: ChangePasswordComponent 
	},
	{ 
		path: 'quartz/history/pcg'
		, component: SilkierHistoryComponent
		, canActivate: [ AuthGuard ]
	},
	{
		path: 'users'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../administration/users/users.module').then(m => m.UsersModule)
	},
	{
		path: 'administration'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../administration/administration.module').then(m => m.AdministrationModule)
	},
	{
		path: 'help'
		, canActivate: [ AuthGuard ]
		, component: HelpFilesComponent
		, data: { security: PermissionConst.coreFeatures }
	},
	{
		path: 'help/:pram'
		, canActivate: [ AuthGuard ]
		, component: HelpFilesComponent
		, data: { security: PermissionConst.coreFeatures }
	},
	{
		path: 'help/help-video/:id'
		, canActivate: [ AuthGuard ]
		, component: HelpVideosComponent
		, data: { security: PermissionConst.coreFeatures }
	},
	{
		path: 'master-customers'
		, loadChildren: () => import('../Customers/customers-module').then(m => m.CustomerModule)
	},
	{
		path: 'transactions'
		, canActivate: [ AuthGuard ]
		, component: TransactionListComponent
		, data: { security: PermissionConst.adminFeatures }
	},
	{
		path: 'transactions'
		, loadChildren: () => import('../transactions/transactions.module').then(m => m.TransactionsModule)
	},
	{
		path: 'workflow/:step'
		, canActivate: [ AuthGuard ]
		, component: WorkflowComponent
		, data: { security: PermissionConst.adminFeatures }
	},
	{ 
		path: '**'
		, redirectTo: '' 
	}
];

export class CoreRouting {
	static declarations = [
		HomeComponent
		, DashboardComponent
		, LoginComponent
		, Error404Component
		, ForgotPasswordComponent
		, ChangePasswordComponent
		, HelpFilesComponent
		, HelpVideosComponent
		, SilkierHistoryComponent
	];
	static imports = [
		RouterModule.forRoot(routes, 
			{
    			scrollPositionRestoration: 'enabled'
				, preloadingStrategy: QuicklinkStrategy
			}
		)
		, EditUserModule
	];
	static exports = [ RouterModule ];
	static providers = [ AuthGuard ];
}
