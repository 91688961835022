<pcg-table 
	#table 
	[dataSource]="dataSource"
	[pcgSort]="[['masterCustomer', 'desc']]"
	[columnDefs]="columns" 
	[canExportTableToExcel]="true"
	isUsingMobileCard="true"
	uniqueColumn="importedCustomer"
>
    <ng-container matColumnDef="masterCustomer">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('masterCustomer').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('masterCustomer').displayName">{{ row.masterCustomer }}</td>
    </ng-container>

    <ng-container matColumnDef="importedCustomer">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('importedCustomer').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('importedCustomer').displayName">{{ row.importedCustomer }}</td>
    </ng-container>

    <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef>{{ columns.get('update').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('update').displayName">
            <button (click)="updateCustomer(row)" type="button" class="btn btn-save">Update Master</button>
        </td>
    </ng-container>

    <ng-container matColumnDef="ignore">
        <th mat-header-cell *matHeaderCellDef>{{ columns.get('ignore').displayName }}</th>
        <td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('ignore').displayName">
            <button (click)="ignore(row)" type="button" class="btn btn-save" *ngIf="row.customerId != null">Ignore</button>
        </td>
    </ng-container>
</pcg-table>