<nav mat-tab-nav-bar [tabPanel]="tabPanel">
	<a *ngIf="routeNavId !== rootNavId" class="back" href="#" (click)="clickDropdown(routeNavId, true)">
		<fa-icon [icon]="faIconName.faArrowLeft" class="back-arrow"></fa-icon>
	</a>
	<ng-container *ngFor="let route of loopNavRoutes">
		<a mat-tab-link
			*ngIf="
				route.ellipseBefore &&
				!navService.shouldShowRouteLink(route, navRoutes, route.index) &&
				!navService.shouldShowRouteLink(navRoutes[route.index - 1], navRoutes, route.index)"
				class="ellipse"
		>
			...
		</a>
		<a mat-tab-link
			*ngIf="route.ellipseBefore || route.ellipseAfter || navService.shouldShowRouteLink(route, navRoutes)"
			class="subnav-item"
			[class.link-active]="forceSelect === route.id"
			[routerLinkActive]="['link-active']"
			[routerLinkActiveOptions]="{ exact: route.exact }"
			[routerLink]="[route.path]" 
			[queryParams]="route.queryParams" 
			(click)="route.openNewTab ? openInNewTab(route.path) : clickedNav.emit()"
		>
			<span *ngIf="route.name != 'Back'">{{ route.name }}</span>
			<span *ngIf="route.name == 'Back'">
				<fa-icon [icon]="faIconName.faArrowLeft" class="back-arrow"></fa-icon>
			</span>
		</a>
		<a mat-tab-link
			*ngIf="
				route.ellipseAfter &&
				!navService.shouldShowRouteLink(route, navRoutes) &&
				!navService.shouldShowRouteLink(navRoutes[route.index + 1], navRoutes)"
				class="ellipse"
		>
			...
		</a>
		<a mat-tab-link
			*ngIf="route && route.children && !route.hasSideNav" class="dropdown"
			class="subnav-item" 
			href="#" 
			(click)="clickDropdown(route.id)"
		>
			{{ route.name }} ▼
		</a>
	</ng-container>
</nav>
