<div class="table-top-container clearfix hide-on-print">

	<!-- TOP LEFT -->
	<div class="top-left-container form-inline">
		<!-- Show/hide button -->
		<pcg-button-hide-show 
			[columnDefs]="columnDefs" 
			class="mt-2 mr-2"
		></pcg-button-hide-show>
		<!-- Filters button -->
		<pcg-button-filter 
			*ngIf='filterId' 
			id="pcg-button-filter" 
			class="mt-2 mr-2" 
			[filterId]="filterId"
		></pcg-button-filter>
		<!-- Reset filters button -->
		<pcg-button-reset-filter 
			*ngIf='showResetFilterBtn()' 
			class="mt-2 mr-2"
		></pcg-button-reset-filter>
		<!-- Applied filters -->
		<div 
			*ngIf="filterMap && btnFilterService.getActiveFilters(ajaxData, filterMap)" 
			class="applied-filters-ctnr"
		>
			<div class="applied-filters form-inline alert-success form-control-sm mt-2" role="alert"> 
				Applied Filters: {{ btnFilterService.getActiveFilters(ajaxData, filterMap) }}
			</div>
		</div>
	</div>
	
	<!-- TOP RIGHT -->
	<div class="top-right-container">
		<!-- Table actions -->
		<div id="pageActions">
			<button 
				ngbTooltip="Column Tooltips" 
				(click)="tooltipButtonClicked()" 
				class="new-page-action mt-2" 
				*ngIf="hasTooltips && isMobile"
			>
				<fa-icon [icon]="faIconName.faQuestion" class="page-action-icon"></fa-icon>
			</button>
			<button ngbTooltip="Export to Excel" (click)="table.getExcel()" class="new-page-action mt-2" *ngIf="canExportTableToExcel">
				<img src="assets/images/actions/xlsxgreen.svg" class="page-action-icon">
			</button>
		</div>
		<!-- Table search -->
		<div *ngIf="canGlobalSearch" class="search-container form-inline">
			<input
				aria-label="Table search"
				type="search"
				class="form-control form-control-sm mt-2"
				placeholder="Search..."
				(keyup)="filterSource.next($event.target.value)"
				(click)="clearSearch($event)"
			/>
		</div>
	</div>

	<!-- Column tooltip modal -->
	<ng-template #tooltipModal let-modal>
		<div class="modal-header">
			<h5>Column Tooltips</h5>
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body flex-body">
			<pcg-table-top-tooltip [innerHTML]="tooltipContent"></pcg-table-top-tooltip>
		</div>
	</ng-template>

</div>
