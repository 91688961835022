import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { PermissionProfile } from 'app/core/enums/generated/PermissionProfile';
import { SecurityService } from 'app/core/security/security.service';
import { PermissionVm } from 'app/shared/generated/Administration/Models/Security/Permissions/PermissionVm';
import { EditUserService } from 'app/administration/users/edit-user/edit-user.service';

@Component({
    selector: 'pcg-user-permissions-modal',
    templateUrl: './user-permissions-modal.component.html',
    styleUrls: ['./user-permissions-modal.component.scss']
})
export class UserPermissionsModalComponent implements OnInit, OnDestroy {

    subscriptions: Subscription = new Subscription();
	permissions: PermissionVm[];
	formGroup: UntypedFormGroup = PermissionVm.Form;
	model = PermissionVm.GetModel(this.formGroup);

	faCheck = faCheck;
	isAdmin: boolean;

	constructor(
		public activeModal: NgbActiveModal
		, private editUserService: EditUserService
		, private sec: SecurityService
	) {}

	ngOnInit(): void { this.getPermissions(); }

	getPermissions(): void {
		this.editUserService.getPermissions((model: PermissionVm[]) => (this.permissions = model));
		this.isAdmin = this.sec.getSecurity()?.permissionRole === PermissionProfile.Admin;
	}

	ngOnDestroy(): void { this.subscriptions.unsubscribe(); }
}
