<form [formGroup]="formGroup" pcgSubmitUrl="api/Sales/SalesRepresentative/AddCustomer" (messageReceive)="saveCustomer($event)">
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Add Customer to Sales Rep</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <pcg-control-group class="col-md-12" label="Customer">
                <pcg-select 
                    placeholder="Select a Customer" 
                    [sendFormData]="false" 
                    pcgControlRef
                    dataSource="api/Select/Customers" 
                    formControlName="customerId" 
                ></pcg-select>
            </pcg-control-group>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-cancel btn-lg" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-save btn-lg">Add Customer</button>
    </div>
</form>