<form [formGroup]="formGroup" pcgSubmitUrl="api/Customers/Customers/SaveCustomer"
    (messageReceive)="saveCustomer($event)">
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <pcg-control-group class="col-md-8" label="Master Customer Name">
                    <input pcgControlRef class="form-control" type="text" formControlName="name" />
                </pcg-control-group>
            </div>
            <div class="row">
                <pcg-control-group class="col-md-8" label="Email">
                    <input pcgControlRef class="form-control email" type="text" formControlName="email" />
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Purchase Order">
                    <input pcgControlRef class="form-control" type="text" formControlName="purchaseOrder" />
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Invoice Number">
                    <input pcgControlRef class="form-control" type="text" formControlName="invoiceNumber" />
                </pcg-control-group>
            </div>

            <div class="row">
                <pcg-control-group class="col-md-8" label="Region">
                    <pcg-select dataSource="api/Select/Regions" placeholder="Select a Region" formControlName="region"
                        pcgControlRef></pcg-select>
                </pcg-control-group>
            </div>
        </div>
        <div class="col-md-6">
            <mat-tab-group dynamicHeight>
                <mat-tab label="Shipping">
                    <div class="row">
                        <pcg-control-group class="col-md-8" label="Shipping Address">
                            <input pcgControlRef class="form-control" type="text"
                                formControlName="shippingStreetAddress" />
                        </pcg-control-group>
                    </div>

                    <div class="row">
                        <pcg-control-group class="col-md-8" label="City">
                            <input pcgControlRef class="form-control" type="text" formControlName="shippingCity" />
                        </pcg-control-group>
                    </div>

                    <div class="row">
                        <pcg-control-group class="col-md-8" label="State">
                            <input pcgControlRef class="form-control" type="text" formControlName="shippingState" />
                        </pcg-control-group>
                    </div>

                    <div class="row">
                        <pcg-control-group class="col-md-8" label="Zip Code">
                            <input pcgControlRef class="form-control" type="text" formControlName="shippingZipCode" />
                        </pcg-control-group>
                    </div>
                    <div class="row">
                        <pcg-control-group class="col-md-8" label="Use as billing address?">
                            <input pcgControlRef class="checkbox" type="checkbox" formControlName="billingIsShipping" />
                        </pcg-control-group>
                    </div>
                </mat-tab>
                <mat-tab label="Billing" [disabled]="formGroup.get('billingIsShipping').value">
                    <div class="row">
                        <pcg-control-group class="col-md-8" label="Billing Address">
                            <input pcgControlRef class="form-control" type="text"
                                formControlName="billingStreetAddress" />
                        </pcg-control-group>
                    </div>

                    <div class="row">
                        <pcg-control-group class="col-md-8" label="City">
                            <input pcgControlRef class="form-control" type="text" formControlName="billingCity" />
                        </pcg-control-group>
                    </div>

                    <div class="row">
                        <pcg-control-group class="col-md-8" label="State">
                            <input pcgControlRef class="form-control" type="text" formControlName="billingState" />
                        </pcg-control-group>
                    </div>

                    <div class="row">
                        <pcg-control-group class="col-md-8" label="Zip Code">
                            <input pcgControlRef class="form-control" type="text" formControlName="billingZipCode" />
                        </pcg-control-group>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

        <pcg-fixed-buttons>
            <a class="btn btn-cancel btn-lg" backButton="/workflow/1">Back</a>
            <button type="submit" *ngIf="id !== 0" class="btn btn-save btn-lg">Save Changes</button>
            <button type="submit" *ngIf="id === 0" class="btn btn-save btn-lg">Save</button>
        </pcg-fixed-buttons>
    </div>
</form>

<mat-tab-group>
    <mat-tab label="Alias">
        <pcg-customer-alias-list [customerId]="id"></pcg-customer-alias-list>
    </mat-tab>
    <mat-tab label="Sales Representatives">
        <app-sales-representatives [customerId]="id"></app-sales-representatives>
    </mat-tab>    
</mat-tab-group>
  