import { Component } from '@angular/core';
import { TransactionEditVm } from '../../shared/generated/Transactions/Models/TransactionEditVm'
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'pcg-transaction-edit',
  templateUrl: './transaction-edit.component.html',
  styleUrl: './transaction-edit.component.scss'
})
export class TransactionEditComponent {
  formGroup = TransactionEditVm.Form;
  model = TransactionEditVm.GetModel(this.formGroup);

  constructor(title: Title, public httpClient: HttpClient, public route: ActivatedRoute, public nav: NavigationService) {
    title.setTitle('Edit Transaction');
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
      let id = paramMap.get('id');
      this.formGroup.patchValue(await firstValueFrom(this.httpClient.get<TransactionEditComponent>(`api/Transactions/Transactions/GetEditTransactionViewModel/${id}`)));
    });
    
    this.formGroup.disable();    
  }
}
