import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { TableComponent } from 'app/shared/table/table.component';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { HelpFilesService } from './help-files.service';
import { HelpFilesVm } from 'app/shared/generated/HelpFiles/Models/HelpFilesVm';
import { SecurityService } from 'app/core/security/security.service';

@Component({
	selector: 'pcg-help-files',
	templateUrl: './help-files.component.html',
	styleUrls: ['./help-files.component.scss']
})
export class HelpFilesComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<HelpFilesVm>;
	@ViewChild('helpfile', { static: true }) modalContent: TemplateRef<any>;
	@ViewChild('helpvideo', { static: true }) modalVideoContent: TemplateRef<any>;

	columns = HelpFilesVm.ColumnDefs;
	formGroup = HelpFilesVm.Form;
	strName: SafeHtml;

	subscriptions = new Subscription();

	canCreateEditDelete = false;

	constructor(
		public title: Title
		, private httpClient: HttpClient
		, public modalService: NgbModal
		, private route: ActivatedRoute
		, public ms: SystemMessageService
		, private sec: SecurityService
		, private helpFilesService: HelpFilesService
	) {
		title.setTitle('Help');			
		this.navToPwa();
	}

	ngOnInit() {
		this.canCreateEditDelete = this.sec.hasAccess(o => o.adminFeatures);
		if (!this.canCreateEditDelete) {
			this.columns.delete('delete');
			this.columns.delete('helpFileId');
		}
	}

	navToPwa() {
		this.route.url.subscribe(params => {
				if (params[1] && params[1].path == "pwa") {
					this.subscriptions.add(this.httpClient.get("api/HelpFiles/HelpFiles/GetPwaPath").subscribe((url: any) => {
						window.open(url.message, '_blank' );
						history.back();
					}));
				}
			});
	}

	generateUrl(videoid: number) { return '/help/help-video/' + videoid; }

	openModal(id: number, videoId: number = null) {
		// Remove validators for source file
		this.formGroup.controls['sourceFile'].clearValidators();
		this.formGroup.controls['sourceFile'].updateValueAndValidity();

		if (videoId !== null) {
			this.formGroup.controls['file'].clearValidators();
			this.formGroup.controls['file'].updateValueAndValidity();
			this.modalService.open(this.modalVideoContent, { beforeDismiss: () => { this.formGroup = HelpFilesVm.Form; return true; } });
		} else {
			this.formGroup.controls['videoId'].clearValidators();
			this.formGroup.controls['videoId'].updateValueAndValidity();
			this.modalService.open(this.modalContent, { beforeDismiss: () => { this.formGroup = HelpFilesVm.Form; return true; } });
		}

		if (id !== 0) {
			this.subscriptions.add(
				this.helpFilesService.GetHelpFileDetails(id).subscribe((model: HelpFilesVm) => {
					this.formGroup.patchValue(model);
					this.formGroup.controls['file'].clearValidators();
					this.formGroup.controls['file'].updateValueAndValidity();
					if (videoId !== null) {
						this.formGroup.controls['sourceFile'].clearValidators();
						this.formGroup.controls['sourceFile'].updateValueAndValidity();
					} else {
						this.formGroup.controls['videoId'].clearValidators();
						this.formGroup.controls['videoId'].updateValueAndValidity();
					}
			}));
		}
	}
}
