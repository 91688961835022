import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {CustomerSalesRepVm} from '../../shared/generated/Customers/Models/CustomerSalesRepVm'
import { SystemMessage } from '../../core/system-message/system-message-service';

@Component({
  selector: 'pcg-add-sales-rep-modal',
  templateUrl: './add-sales-rep-modal.component.html',
  styleUrl: './add-sales-rep-modal.component.scss'
})
export class AddSalesRepModalComponent {
  @Input() customerId;
  
  formGroup = CustomerSalesRepVm.Form;
  model = CustomerSalesRepVm.GetModel(this.formGroup);

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.formGroup.get('customerId')?.setValue(this.customerId);
    this.formGroup.get('salesRepId')?.setValue(null);
  }

  saveSalesRep(message: SystemMessage) {
    if(message.isSuccessful){
      this.activeModal.close(true);
    }
  }
}
