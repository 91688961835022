import { Directive, HostListener, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { validateForm } from '../form-validateForm.function';
import { CheckNetworkService } from 'local-db/services/check-network.service';
import { SetLocaldbTablesService } from 'app/services/service-workers/set-localdb-tables.service'

@Directive({
	selector: '[pcgSubmitUrl]'
	//, host: {'(submit)': 'onSubmit($event)'}
})
export class SubmitUrlDirective implements OnDestroy {

	@Input('pcgSubmitUrl') url: string;
	@Input() additionalFields: any;
	@Input() localDbTbl: string = "";

	@Output() messageReceive: EventEmitter<SystemMessage> = new EventEmitter<SystemMessage>();
	@Output() messageSuccess: EventEmitter<SystemMessage> = new EventEmitter<SystemMessage>();
	@Output() messageError: EventEmitter<SystemMessage> = new EventEmitter<SystemMessage>();

	subscriptions = new Subscription();

	constructor(
		public formGroupDirective: FormGroupDirective
		, public ms: SystemMessageService
		, private localDbService: SetLocaldbTablesService
	) {}

	@HostListener('submit', ['$event']) async onSubmit($event) {
		if (this.formGroupDirective.form.valid) {
			var isOnline = CheckNetworkService.checkNetworkStatus();	
			
			if (isOnline === true) {
				// Send system message save event if form valid
				this.subscriptions.add(
					this.ms.getHttpObservable(this, this.url, this.formGroupDirective.form, this.additionalFields)
						.subscribe((msg: SystemMessage) => {
							this.messageReceive.emit(msg);
							if (msg && msg.isSuccessful) { this.messageSuccess.emit(msg); }
							if (msg && !msg.isSuccessful) { this.messageError.emit(msg); }
						}));
			} else if (this.localDbTbl !== "") {
				this.ms.setSystemMessage("Data Saved", "success");
				this.localDbService.setLocalDbTable(this.localDbTbl, this.formGroupDirective.form.value, true);
			}
		} else {
			validateForm(this.formGroupDirective.form); // If form is not valid, show validation errors
		}
	}

	ngOnDestroy() { this.subscriptions.unsubscribe(); } // Unsubscribe to cancel any requests
}
