import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditSalesTerritoryVm {
	salesTerritoryId: number;
	name?: string;
	region?: number[];
	manager?: string;
	salesRep1?: string;
	salesRep2?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'salesTerritoryId': new UntypedFormControl(0, [...(validators['salesTerritoryId'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[Validators.required, Validators.minLength(2), Validators.maxLength(100)]]),
			'region': new UntypedFormControl([], [...(validators['region'] ?? []), ...[Validators.required]]),
			'manager': new UntypedFormControl(null, [...(validators['manager'] ?? []), ...[]]),
			'salesRep1': new UntypedFormControl(null, [...(validators['salesRep1'] ?? []), ...[]]),
			'salesRep2': new UntypedFormControl(null, [...(validators['salesRep2'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): EditSalesTerritoryVmFromFg {
		return new EditSalesTerritoryVmFromFg(fg);
	}
}

export class EditSalesTerritoryVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get salesTerritoryId(): number { return this.fg.get('salesTerritoryId').value; }
	set salesTerritoryId(val) { this.fg.get('salesTerritoryId').setValue(val); }

	get name(): string { return this.fg.get('name').value; }
	set name(val) { this.fg.get('name').setValue(val); }

	get manager(): string { return this.fg.get('manager').value; }
	set manager(val) { this.fg.get('manager').setValue(val); }

	get salesRep1(): string { return this.fg.get('salesRep1').value; }
	set salesRep1(val) { this.fg.get('salesRep1').setValue(val); }

	get salesRep2(): string { return this.fg.get('salesRep2').value; }
	set salesRep2(val) { this.fg.get('salesRep2').setValue(val); }

}
