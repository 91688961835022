import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class TransactionListVm {
	transactionId: number;
	importHistoryId: number;
	fileSource?: string;
	importedDate: Date;
	importedDateString?: string;
	salesRegion?: string;
	distributorName?: string;
	transactionSource?: string;
	billToCustomer?: string;
	shipToCustomer?: string;
	transactionDate: Date;
	transactionDateString?: string;
	transactionNumber?: string;
	productGroup?: string;
	partsNumber?: string;
	salesAmount: number;
	quantity: number;
	unitSalesPrice: number;
	listPrice: number;
	percentDiscount: number;
	commissionAmount: number;
	commissionBracket?: string;
	commissionSummary?: string;
	compSegment?: string;
	customerTier?: string;
	gpoAffiliation?: string;
	gpoStatus?: string;
	canDelete: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'transactionId': new UntypedFormControl(0, [...(validators['transactionId'] ?? []), ...[Validators.required]]),
			'importHistoryId': new UntypedFormControl(0, [...(validators['importHistoryId'] ?? []), ...[Validators.required]]),
			'fileSource': new UntypedFormControl(null, [...(validators['fileSource'] ?? []), ...[]]),
			'importedDate': new UntypedFormControl('', [...(validators['importedDate'] ?? []), ...[Validators.required]]),
			'importedDateString': new UntypedFormControl(null, [...(validators['importedDateString'] ?? []), ...[]]),
			'salesRegion': new UntypedFormControl(null, [...(validators['salesRegion'] ?? []), ...[]]),
			'distributorName': new UntypedFormControl(null, [...(validators['distributorName'] ?? []), ...[]]),
			'transactionSource': new UntypedFormControl(null, [...(validators['transactionSource'] ?? []), ...[]]),
			'billToCustomer': new UntypedFormControl(null, [...(validators['billToCustomer'] ?? []), ...[]]),
			'shipToCustomer': new UntypedFormControl(null, [...(validators['shipToCustomer'] ?? []), ...[]]),
			'transactionDate': new UntypedFormControl('', [...(validators['transactionDate'] ?? []), ...[Validators.required]]),
			'transactionDateString': new UntypedFormControl(null, [...(validators['transactionDateString'] ?? []), ...[]]),
			'transactionNumber': new UntypedFormControl(null, [...(validators['transactionNumber'] ?? []), ...[]]),
			'productGroup': new UntypedFormControl(null, [...(validators['productGroup'] ?? []), ...[]]),
			'partsNumber': new UntypedFormControl(null, [...(validators['partsNumber'] ?? []), ...[]]),
			'salesAmount': new UntypedFormControl(0, [...(validators['salesAmount'] ?? []), ...[Validators.required]]),
			'quantity': new UntypedFormControl(0, [...(validators['quantity'] ?? []), ...[Validators.required]]),
			'unitSalesPrice': new UntypedFormControl(0, [...(validators['unitSalesPrice'] ?? []), ...[Validators.required]]),
			'listPrice': new UntypedFormControl(0, [...(validators['listPrice'] ?? []), ...[Validators.required]]),
			'percentDiscount': new UntypedFormControl(0, [...(validators['percentDiscount'] ?? []), ...[Validators.required]]),
			'commissionAmount': new UntypedFormControl(0, [...(validators['commissionAmount'] ?? []), ...[Validators.required]]),
			'commissionBracket': new UntypedFormControl(null, [...(validators['commissionBracket'] ?? []), ...[]]),
			'commissionSummary': new UntypedFormControl(null, [...(validators['commissionSummary'] ?? []), ...[]]),
			'compSegment': new UntypedFormControl(null, [...(validators['compSegment'] ?? []), ...[]]),
			'customerTier': new UntypedFormControl(null, [...(validators['customerTier'] ?? []), ...[]]),
			'gpoAffiliation': new UntypedFormControl(null, [...(validators['gpoAffiliation'] ?? []), ...[]]),
			'gpoStatus': new UntypedFormControl(null, [...(validators['gpoStatus'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(false, [...(validators['canDelete'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['transactionId', {"orderId":0,"sortColumn":"transactionId","searchColumn":"transactionId","includeInExcel":true,"exportColumn":null,"displayName":"Transaction ID","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['importHistoryId', {"orderId":1,"sortColumn":"importHistoryId","searchColumn":"importHistoryId","includeInExcel":true,"exportColumn":null,"displayName":"Batch Number","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['fileSource', {"orderId":2,"sortColumn":"fileSource","searchColumn":"fileSource","includeInExcel":true,"exportColumn":null,"displayName":"File Source","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['importedDateString', {"orderId":3,"sortColumn":"importedDate","searchColumn":"importedDate","includeInExcel":true,"exportColumn":null,"displayName":"Imported Date","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['salesRegion', {"orderId":4,"sortColumn":"salesRegion","searchColumn":"salesRegion","includeInExcel":true,"exportColumn":null,"displayName":"Sales Region","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['distributorName', {"orderId":5,"sortColumn":"distributorName","searchColumn":"distributorName","includeInExcel":true,"exportColumn":null,"displayName":"Distributor Name","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['transactionSource', {"orderId":6,"sortColumn":"transactionSource","searchColumn":"transactionSource","includeInExcel":true,"exportColumn":null,"displayName":"Transaction Source","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['billToCustomer', {"orderId":7,"sortColumn":"billToCustomer","searchColumn":"billToCustomer","includeInExcel":true,"exportColumn":null,"displayName":"Bill To Customer","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['shipToCustomer', {"orderId":8,"sortColumn":"shipToCustomer","searchColumn":"shipToCustomer","includeInExcel":true,"exportColumn":null,"displayName":"Ship To Customer","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['transactionDateString', {"orderId":9,"sortColumn":"transactionDate","searchColumn":"transactionDate","includeInExcel":true,"exportColumn":null,"displayName":"Transaction Date","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['transactionNumber', {"orderId":10,"sortColumn":"transactionNumber","searchColumn":"transactionNumber","includeInExcel":true,"exportColumn":null,"displayName":"Transaction Number","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['productGroup', {"orderId":11,"sortColumn":"productGroup","searchColumn":"productGroup","includeInExcel":true,"exportColumn":null,"displayName":"Product Group","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['partsNumber', {"orderId":12,"sortColumn":"partsNumber","searchColumn":"partsNumber","includeInExcel":true,"exportColumn":null,"displayName":"Parts Number","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['salesAmount', {"orderId":13,"sortColumn":"salesAmount","searchColumn":"salesAmount","includeInExcel":true,"exportColumn":null,"displayName":"Sales Amount","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"currency"}],
			['quantity', {"orderId":14,"sortColumn":"quantity","searchColumn":"quantity","includeInExcel":true,"exportColumn":null,"displayName":"Quantity","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['unitSalesPrice', {"orderId":15,"sortColumn":"unitSalesPrice","searchColumn":"unitSalesPrice","includeInExcel":true,"exportColumn":null,"displayName":"Unit Sales Price","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"currency"}],
			['listPrice', {"orderId":16,"sortColumn":"listPrice","searchColumn":"listPrice","includeInExcel":true,"exportColumn":null,"displayName":"List Price","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"currency"}],
			['percentDiscount', {"orderId":17,"sortColumn":"percentDiscount","searchColumn":"percentDiscount","includeInExcel":true,"exportColumn":null,"displayName":"Percent Discount","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"percent"}],
			['commissionAmount', {"orderId":18,"sortColumn":"commissionAmount","searchColumn":"commissionAmount","includeInExcel":true,"exportColumn":null,"displayName":"Commission Amount","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"currency"}],
			['commissionBracket', {"orderId":19,"sortColumn":"commissionBracket","searchColumn":"commissionBracket","includeInExcel":true,"exportColumn":null,"displayName":"Commission Bracket","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['commissionSummary', {"orderId":20,"sortColumn":"commissionSummary","searchColumn":"commissionSummary","includeInExcel":true,"exportColumn":null,"displayName":"Commission Summary","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['compSegment', {"orderId":21,"sortColumn":"compSegment","searchColumn":"compSegment","includeInExcel":true,"exportColumn":null,"displayName":"Comp Segment","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['customerTier', {"orderId":22,"sortColumn":"customerTier","searchColumn":"customerTier","includeInExcel":true,"exportColumn":null,"displayName":"Customer Tier","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['gpoAffiliation', {"orderId":23,"sortColumn":"gpoAffiliation","searchColumn":"gpoAffiliation","includeInExcel":true,"exportColumn":null,"displayName":"GPO Affiliation","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['gpoStatus', {"orderId":24,"sortColumn":"gpoStatus","searchColumn":"gpoStatus","includeInExcel":true,"exportColumn":null,"displayName":"GPO Status","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['canDelete', {"orderId":25,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":false,"exportColumn":null,"displayName":"Delete","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): TransactionListVmFromFg {
		return new TransactionListVmFromFg(fg);
	}
}

export class TransactionListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get transactionId(): number { return this.fg.get('transactionId').value; }
	set transactionId(val) { this.fg.get('transactionId').setValue(val); }

	get importHistoryId(): number { return this.fg.get('importHistoryId').value; }
	set importHistoryId(val) { this.fg.get('importHistoryId').setValue(val); }

	get fileSource(): string { return this.fg.get('fileSource').value; }
	set fileSource(val) { this.fg.get('fileSource').setValue(val); }

	get importedDate(): Date { return this.fg.get('importedDate').value; }
	set importedDate(val) { this.fg.get('importedDate').setValue(val); }

	get importedDateString(): string { return this.fg.get('importedDateString').value; }
	set importedDateString(val) { this.fg.get('importedDateString').setValue(val); }

	get salesRegion(): string { return this.fg.get('salesRegion').value; }
	set salesRegion(val) { this.fg.get('salesRegion').setValue(val); }

	get distributorName(): string { return this.fg.get('distributorName').value; }
	set distributorName(val) { this.fg.get('distributorName').setValue(val); }

	get transactionSource(): string { return this.fg.get('transactionSource').value; }
	set transactionSource(val) { this.fg.get('transactionSource').setValue(val); }

	get billToCustomer(): string { return this.fg.get('billToCustomer').value; }
	set billToCustomer(val) { this.fg.get('billToCustomer').setValue(val); }

	get shipToCustomer(): string { return this.fg.get('shipToCustomer').value; }
	set shipToCustomer(val) { this.fg.get('shipToCustomer').setValue(val); }

	get transactionDate(): Date { return this.fg.get('transactionDate').value; }
	set transactionDate(val) { this.fg.get('transactionDate').setValue(val); }

	get transactionDateString(): string { return this.fg.get('transactionDateString').value; }
	set transactionDateString(val) { this.fg.get('transactionDateString').setValue(val); }

	get transactionNumber(): string { return this.fg.get('transactionNumber').value; }
	set transactionNumber(val) { this.fg.get('transactionNumber').setValue(val); }

	get productGroup(): string { return this.fg.get('productGroup').value; }
	set productGroup(val) { this.fg.get('productGroup').setValue(val); }

	get partsNumber(): string { return this.fg.get('partsNumber').value; }
	set partsNumber(val) { this.fg.get('partsNumber').setValue(val); }

	get salesAmount(): number { return this.fg.get('salesAmount').value; }
	set salesAmount(val) { this.fg.get('salesAmount').setValue(val); }

	get quantity(): number { return this.fg.get('quantity').value; }
	set quantity(val) { this.fg.get('quantity').setValue(val); }

	get unitSalesPrice(): number { return this.fg.get('unitSalesPrice').value; }
	set unitSalesPrice(val) { this.fg.get('unitSalesPrice').setValue(val); }

	get listPrice(): number { return this.fg.get('listPrice').value; }
	set listPrice(val) { this.fg.get('listPrice').setValue(val); }

	get percentDiscount(): number { return this.fg.get('percentDiscount').value; }
	set percentDiscount(val) { this.fg.get('percentDiscount').setValue(val); }

	get commissionAmount(): number { return this.fg.get('commissionAmount').value; }
	set commissionAmount(val) { this.fg.get('commissionAmount').setValue(val); }

	get commissionBracket(): string { return this.fg.get('commissionBracket').value; }
	set commissionBracket(val) { this.fg.get('commissionBracket').setValue(val); }

	get commissionSummary(): string { return this.fg.get('commissionSummary').value; }
	set commissionSummary(val) { this.fg.get('commissionSummary').setValue(val); }

	get compSegment(): string { return this.fg.get('compSegment').value; }
	set compSegment(val) { this.fg.get('compSegment').setValue(val); }

	get customerTier(): string { return this.fg.get('customerTier').value; }
	set customerTier(val) { this.fg.get('customerTier').setValue(val); }

	get gpoAffiliation(): string { return this.fg.get('gpoAffiliation').value; }
	set gpoAffiliation(val) { this.fg.get('gpoAffiliation').setValue(val); }

	get gpoStatus(): string { return this.fg.get('gpoStatus').value; }
	set gpoStatus(val) { this.fg.get('gpoStatus').setValue(val); }

	get canDelete(): boolean { return this.fg.get('canDelete').value; }
	set canDelete(val) { this.fg.get('canDelete').setValue(val); }

}
