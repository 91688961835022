<pcg-table 
	#table 
	[ajaxData]="filters" 
	[pcgSort]="[['dateChanged', 'desc']]" 
	dataSource="api/AuditHistory/GetAuditHistory"
	[serviceDataSource]="'api/AuditHistory/GetAuditHistory'"
	[columnDefs]="columns" 
	(tableReceive)="setTableRecieve($event)" 
	[canExportTableToExcel]="true"
	isUsingMobileCard="true"
	uniqueColumn="dateChangedFormatted"
>
    <!-- Date Changed -->
	<ng-container matColumnDef="dateChangedFormatted">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('dateChangedFormatted').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('dateChangedFormatted').displayName">{{ row.dateChangedFormatted }}</td>
	</ng-container>
	<!-- Field -->
	<ng-container matColumnDef="field">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('field').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('field').displayName">{{ row.field }}</td>
	</ng-container>
	<!-- Changed From -->
	<ng-container matColumnDef="changedFrom">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('changedFrom').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('changedFrom').displayName">{{ row.changedFrom }}</td>
	</ng-container>
	<!-- Changed To -->
	<ng-container matColumnDef="changedTo">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('changedTo').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('changedTo').displayName">{{ row.changedTo }}</td>
	</ng-container>
	<!-- Changed By -->
	<ng-container matColumnDef="changedBy">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('changedBy').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('changedBy').displayName">{{ row.changedBy }}</td>
	</ng-container>
	
</pcg-table>
