import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const usersNav: () => NavRoute = () => ({
	name: getName()
	, security: security => security.manageUsers
	, href: 'users'
	, children: getUserAreas()
});

function getName() : string {
	var name: string;
	var url = window.location.href;
	
	if (
		url.indexOf('users/edit-user/') > -1
		|| url.indexOf('users/user-security/') > -1
		|| url.indexOf('users/user-audit-history/') > -1
		|| url.indexOf('users/user-login-history/') > -1
	) { name = "Edit User"; } 
	else { name = "Users"; }

	return name;
}

function getUserAreas() : NavRoute[] {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf('users/edit-user/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('users/user-security/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('users/user-audit-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('users/user-login-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	// Users List and Global Login History
	if (id === "") { 
		return [
			{
				name: 'Users List'
				, exact: true
				, path: 'users'
			},
			{
				name: 'Global Login History'
				, exact: true
				, path: 'users/login-history'
			}
		]; 
	}

	// Creating a new User
	if (+id === 0) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `users`
			},
			{
				name: 'Details'
				, exact: true
				, path: `users/edit-user/${id}`
			}
		];
	}

	// User details
	return [
		{
			name: 'Back'
			, exact: true
			, path: `users`
		},
		{
			name: 'Details'
			, exact: true
			, path: `users/edit-user/${id}`
		},
		{
			name: 'Security'
			, exact: true
			, path: `users/user-security/${id}`
		},
		{
			name: 'Login History'
			, exact: true
			, path: `users/user-login-history/${id}`
		},
		{
			name: 'Audit History'
			, exact: true
			, path: `users/user-audit-history/${id}`
		},
	];
}
