<form
	[formGroup]="formGroup" 
	pcgSubmitUrl="api/Administration/Users/SaveUser" 
	[localDbTbl]="'EditUserTbl'"
	(messageReceive)="saveUser($event)"
>
	<div>
		<input type="hidden" formControlName="userId" />
		<input *ngIf="id === 0" type="hidden" formControlName="isActive" />
		<input *ngIf="id !== 0" type="hidden" formControlName="userName" />
		<input *ngIf="id !== 0" type="hidden" formControlName="permissionId" />

		<div class="row mt-2 mb-2 ml-2">
			<div id="activation">
				<div *ngIf="isLockedOut" id="divUnlockUser" (click)="unlockUser()">
					<fa-icon 
						[icon]="faIconName.faUnlock" 
						class="unlockIcon fa-2x" 
						ngbTooltip="This user is locked out. Click here to unlock the user and send them an email with login instructions."
					></fa-icon>	
					<span class="unlockLable">Locked Out (Click to unlock)</span>
				</div>
				<pcg-control-group class="activate">
					<p 
						[class.text-danger]="!model.isActive" 
						[class.d-none]="model.isActive"
					>This user is deactivated.</p>
					<a 
						*ngIf="(canManageUsers ? true : null) && (id !== 0)"
						[class.btn-danger]="model.isActive"
						[class.btn-save]="!model.isActive"
						class="btn btn-sm"
						(click)="activateDeactivate(); model.isActive = !model.isActive;"
					>
						{{ model.isActive ? 'Deactivate' : 'Reactivate' }}
					</a>
					<button #submit [hidden]="true"></button>
				</pcg-control-group>
			</div>
		</div>

		<div class="section-ctnr">
			<div class="section-head">
				<fa-icon [icon]="faIconName.faUser" class="mr-3"></fa-icon>
				<h1>User</h1>
			</div>
			<hr>
			<div class="row">
				<pcg-control-group class="col-md-4" label="Full Name" [validations]="fullNameValidation">
					<input 
						pcgAutofocus 
						pcgNoSpecialChars 
						pcgControlRef 
						maxlength="100" 
						class="form-control" 
						type="text" 
						formControlName="fullName" 
					/>
				</pcg-control-group>

				<pcg-control-group *ngIf="id === 0 || model.isNewUser" class="col-md-4" label="Username" [validations]="userNameValidation">
					<input 
						pcgControlRef 
						class="form-control" 
						type="text" 
						maxlength="256" 
						formControlName="userName" 
					/>
				</pcg-control-group>
			</div>
			<div class="row">
				<pcg-control-group class="col-md-4" label="Email">
					<input 
						pcgControlRef 
						class="form-control email" 
						type="text" 
						formControlName="email" 
					/>
				</pcg-control-group>

				<pcg-control-group 
					class="col-md-4" 
					label="Phone" 
				> 
					<input 
						pcgPhoneExt 
						pcgControlRef 
						class="form-control" 
						type="text" 
						inputmode="tel" 
						formControlName="phoneNumber" 
						maxlength="21"
					/>
				</pcg-control-group>
			</div>

			<div class="row" *ngIf="id === 0 || model.isNewUser">
				<pcg-control-group class="col-md-4" label="Permission">
					<pcg-select 
						placeholder="Select an Permission" 
						[sendFormData]="false" 
						pcgControlRef
						dataSource="api/Select/Permissions" 
						formControlName="permissionId" 
						(change)="changePermissions()"
					></pcg-select>
				</pcg-control-group>
				<div class="col-md-2 btn-ctnr">
					<input 
						[class.permissionInfoCurrentUser]="isCurrentUser" 
						[class.permissionInfoNotCurrentUser]="!isCurrentUser"
						type="button" 
						value="Permissions Info" 
						(click)="openPermissionsInfoModal()" 
						class="btn btn-save btn-sm" 
					/>
				</div>
			</div>
		</div>

		<div class="row">
			<div *ngIf="canSeePasswordButtons" class="col-md-2 mobileFriendly">
				<span ngbTooltip="Send user a welcome email with their username and the ability to choose a new password." triggers="pointerup hover">
					<input 
						type="button" 
						(click)="resetPassword(true)" 
						class="btn btn-save btn-sm"
						value="Send Welcome Email" 
						id="btnSendWelcomeMessage" 
					/>
				</span>
			</div>					
		</div>

		<pcg-fixed-buttons>
			<a *ngIf="!isCurrentUser || canManageUsers" class="btn btn-cancel btn-lg" backButton="/users">Back</a>
			<button type="submit" *ngIf="id !== 0" class="btn btn-save btn-lg">Save</button>
			<button type="submit" *ngIf="id === 0" class="btn btn-save btn-lg">Next</button>
		</pcg-fixed-buttons>
	</div>
</form>	
