import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class CustomerAliasListVm {
	customerAliasId: number;
	fileSource?: string;
	name?: string;
	shippingStreetAddress?: string;
	shippingCity?: string;
	shippingState?: string;
	shippingZipCode?: string;
	canDelete: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'customerAliasId': new UntypedFormControl(0, [...(validators['customerAliasId'] ?? []), ...[Validators.required]]),
			'fileSource': new UntypedFormControl(null, [...(validators['fileSource'] ?? []), ...[]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[]]),
			'shippingStreetAddress': new UntypedFormControl(null, [...(validators['shippingStreetAddress'] ?? []), ...[]]),
			'shippingCity': new UntypedFormControl(null, [...(validators['shippingCity'] ?? []), ...[]]),
			'shippingState': new UntypedFormControl(null, [...(validators['shippingState'] ?? []), ...[]]),
			'shippingZipCode': new UntypedFormControl(null, [...(validators['shippingZipCode'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(false, [...(validators['canDelete'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['fileSource', {"orderId":0,"sortColumn":"fileSource","searchColumn":"fileSource","includeInExcel":true,"exportColumn":null,"displayName":"File Source","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['name', {"orderId":1,"sortColumn":"name","searchColumn":"name","includeInExcel":true,"exportColumn":null,"displayName":"Alias Name","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['shippingStreetAddress', {"orderId":2,"sortColumn":"shippingStreetAddress","searchColumn":"shippingStreetAddress","includeInExcel":true,"exportColumn":null,"displayName":"Shipping Street Address","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['shippingCity', {"orderId":3,"sortColumn":"shippingCity","searchColumn":"shippingCity","includeInExcel":true,"exportColumn":null,"displayName":"Shipping City","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['shippingState', {"orderId":4,"sortColumn":"shippingState","searchColumn":"shippingState","includeInExcel":true,"exportColumn":null,"displayName":"Shipping State","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['shippingZipCode', {"orderId":5,"sortColumn":"shippingZipCode","searchColumn":"shippingZipCode","includeInExcel":true,"exportColumn":null,"displayName":"Shipping Zip Code","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['canDelete', {"orderId":6,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":false,"exportColumn":null,"displayName":"Delete","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): CustomerAliasListVmFromFg {
		return new CustomerAliasListVmFromFg(fg);
	}
}

export class CustomerAliasListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get customerAliasId(): number { return this.fg.get('customerAliasId').value; }
	set customerAliasId(val) { this.fg.get('customerAliasId').setValue(val); }

	get fileSource(): string { return this.fg.get('fileSource').value; }
	set fileSource(val) { this.fg.get('fileSource').setValue(val); }

	get name(): string { return this.fg.get('name').value; }
	set name(val) { this.fg.get('name').setValue(val); }

	get shippingStreetAddress(): string { return this.fg.get('shippingStreetAddress').value; }
	set shippingStreetAddress(val) { this.fg.get('shippingStreetAddress').setValue(val); }

	get shippingCity(): string { return this.fg.get('shippingCity').value; }
	set shippingCity(val) { this.fg.get('shippingCity').setValue(val); }

	get shippingState(): string { return this.fg.get('shippingState').value; }
	set shippingState(val) { this.fg.get('shippingState').setValue(val); }

	get shippingZipCode(): string { return this.fg.get('shippingZipCode').value; }
	set shippingZipCode(val) { this.fg.get('shippingZipCode').setValue(val); }

	get canDelete(): boolean { return this.fg.get('canDelete').value; }
	set canDelete(val) { this.fg.get('canDelete').setValue(val); }

}
