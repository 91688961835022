import { Component, OnInit, Input, ContentChildren, QueryList, AfterContentInit, ViewChild, Host, Optional, OnDestroy, ElementRef
	, HostListener, AfterViewChecked, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatColumnDef, MatTable, MatHeaderRowDef, MatFooterRowDef } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

import { PcgSortDirective } from './sort/sort';
import { PcgTableColumn } from './pcg-table-column.interface';
import { TableNoServerSide } from './table-no-server-side.class';
import { param } from '../http-params';
import { TableServerSide } from './table-server-side.class';
import { SecurityService } from '../../core/security/security.service';
import { ButtonFilterService } from './table-top/button-filter/button-filter.service';
import { FormatHelperService } from '../helpers/format-helper.service';
import { TableService } from './table.service';
import { GlobalService } from '../services/global.service'
import { Router } from '@angular/router';

export interface PcgTableResult {
	recordsTotal: number; // The total number of records
	recordsFiltered: number; // The total number of records after filtering by search
	data: any; // The data returned from the server
	value: any; // Additional data which may be returned from the server
	exportLocation?: string; // If an export is returned, this is its location
	hasProtectedFields: boolean; // Whether or not the table has any protected fields
}

export interface PcgTableInputColumn {
	searchText?: string; // The string the user is searching on, if any
	sortColumnNum?: number; // Controls the order of sorting for columns in multi-column sorting
	sortDirection?: string; // Control direction of sorting: 'asc' for ascending, 'desc' for descending
}

export interface PcgTableInput {
	columns: PcgTableInputColumn[]; // Column sort and per column search information
	start: number; // The number of records to skip while paging
	length: number; // The number of records on a page
	searchText: string; // The global search string
	exactMatch: boolean; // Whether or not to do exact match on the global search
	exportType?: string; // Can be set to either PDF or Excel to get an export based on the table
	reportName?: string; // If we are doing an export, the report name. If nothing set, looks for an h1 tag
	excelPassword?: string; // Excel password is necessary if we have protected fields
}

@Component({
	selector: 'pcg-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnInit, AfterContentInit, AfterViewChecked, OnDestroy {

	@Input() dataSource;
	@Input() serviceDataSource = "";
	@Input() columnDefs: Map<string, PcgTableColumn> = new Map<string, PcgTableColumn>();
	@Input() multipleSearch = false;
	@Input() showTableTop = true;
	@Input() showPagination = true;
	@Input() serverSide = true;
	@Input() pageLengths = [25, 50, 100, 250, 500];
	@Input() pageSize = 100;
	@Input() searchDebounceTime = 400;
	@Input() alwaysBoxed = false; // Forces the table to be in box regardless of screen size
	@Input() responsive = true; // Whether or not to put the table in a responsive box
	@Input() fixedHeader = true; // Whether or not to have a sticky header, only available with responsive grids
	@Input() fixedFooter = true; // Whether or not to have a sticky footer, only available with responsive grids
	@Input() callbackFunc: (colResult: PcgTableResult) => void;
	@Input() ajaxData: any;
	@Input() canGlobalSearch = true;
	@Input() isRowClickable = false;
	@Input() tblData: any = null;

	// Inputs for new mobile cards, including pcg-delete-button inputs
	@Input() isUsingMobileCard = false;	/* Whether or not table should use the new mobile card design */
	@Input() identificationColumn: string; /* Specifies which column is contains the ID of each data element */	
	@Input() uniqueColumn: string; /* Specifies unique (left-side) column for each table */	
	@Input() isAdmin: boolean; /* Delete button inputs */
	@Input() confirmMessage: string; /* Delete button inputs */
	@Input() deleteUrl: string; /* Delete button inputs */	
	@Input() redirectFunction: (args: any) => void; /* clickRow function passed from each table */

	@Input() canExportTableToExcel = false;
	@Input() canPrintTable = false;
	@Input() hasTooltips = false;
	@Input() tooltipContent: string;

	// Inputs for implementing the table dropdown filter features	
	@Input() filterId: string = null; /** Pass through filter id to implement table dropdown filters. */
	@Input() filterMap: {} = null; /** Pass through a filtermap to show filters being utilized. */
	@Input() emptyFilters: {} = null; /** Pass through empty filter array for special reset button visibility behavior. */
	@Input() canResetFilters = true; /** Pass through false if using the table dropdown filters and they do not have reset capabilities */

	@Output() tableReceive = new EventEmitter<PcgTableResult>();
	@Output() clickRow = new EventEmitter();

	@ContentChildren(MatColumnDef) contentColumnDefs: QueryList<MatColumnDef>;

	@ViewChild(MatTable, { static: true }) table: MatTable<T>;
	@ViewChild(MatHeaderRowDef, { static: true }) searchHeader: MatHeaderRowDef;
	@ViewChild(MatFooterRowDef, { static: true }) footerRow: MatFooterRowDef;
	@ViewChild('tableContainer', { static: true }) tableContainer: ElementRef;

	// This object contains the table data sent to the server on the previous request
	prevTableInput: PcgTableInput = null;

	// Keep track of total records
	totalDataCount = 0;
	filteredDataCount = 0;

	// Used to set the top offest for the search row
	searchRowTopOffset = 0;
	tableNum = 0;

	// The current filter text / exact match
	filterSource = new BehaviorSubject<string>('');
	filter$ = this.filterSource.asObservable();
	exactMatchSource = new BehaviorSubject<boolean>(false);
	exactMatch$ = this.exactMatchSource.asObservable();
	perColumnSearchSource = new BehaviorSubject<string[]>([]);
	perColumnSearch$ = this.perColumnSearchSource.asObservable();

	// Pagination variables
	currentPageSource = new BehaviorSubject<number>(1);
	currentPage$ = this.currentPageSource.asObservable();
	pageSizeSource: BehaviorSubject<number>;
	pageSize$: Observable<number>;

	// Data variables
	data: T[];
	dataNoServerSideUpdateSource = new BehaviorSubject<T[]>([]);
	dataSource$: Observable<T[]>;
	filteredDataSource = new BehaviorSubject<T[]>([]);
	filteredData$ = this.filteredDataSource.asObservable();
	dataOnPageSource = new BehaviorSubject<any[]>([{}]);
	dataOnPage$ = this.dataOnPageSource.asObservable();
	
	subscriptions: Subscription = new Subscription(); // Keep track of our AJAX call subscriptions
	
	isResponsive = false; // Whether or not the table is currently responsive
	hasProtectedFields = false; // Whether or not the table has protected fields
	hasCompletedServerRequest = false; // Whether or not the table has completed a server request

	// Mobile card variables
	isMobile: boolean; // Whether or not screen size is in mobile-view
	tableData: PcgTableResult; // This is the data that is looped through for the mobile cards

	constructor(
		@Host() @Optional() public pcgSort: PcgSortDirective
		, private sec: SecurityService
		, private http: HttpClient
		, private cdRef: ChangeDetectorRef
		, public btnFilterService: ButtonFilterService
		, public tableService: TableService
		, private router: Router // don't remove. Needed for redirectFunction
	) {}

	// Fix responsive and search header top on resize
	@HostListener('window:resize')
	onResize() {
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		this.fixResponsive();
		// I need this timeout because it takes 100 ms for the gross dynamic nav sticky CSS to be added in header.component.ts :(
		setTimeout(() => { this.fixSearchHeaderTop(); }, 150);
	}

	// A few simple lambda functions that are used in the template
	hasData = () => this.filteredDataCount !== 0;
	hasNoData = () => this.filteredDataCount === 0;
	getColDefs = () => Array.from(this.columnDefs.keys());
	getVisibleColDefs = () => this.getColDefs().filter(key => this.columnDefs.get(key).isVisible !== false);
	getSearchColDefs = () => this.getColDefs().map(key => this.columnDefs.get(key).searchColumn || key);
	getColHeaderDefs = () => this.getVisibleColDefs().map(key => `${key}_search`);
	getColSearchClasses = (key: string) => this.columnDefs.get(key).multiSearchCellClasses;
	canSearch = (key: string) => this.columnDefs.get(key).canSearch !== false;
	isNdc = (key: string) => this.columnDefs.get(key).isNdc !== false;

	ngOnInit() {
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		if (!this.filterId) { this.canResetFilters = false; } // Set canResetFilters is dropdown filters are not being implemented

		window['tableCount'] = window['tableCount'] ?? 1;
		this.perColumnSearchSource.next(Array.from(new Array(this.getColDefs().length), () => ''));

		if (
			typeof this.dataSource !== 'string' 
			&& FormatHelperService.GetIsNullyOrWhitespace(this.serviceDataSource)
			&& this.tblData === null
		) { 
			this.dataSource$ = this.dataSource instanceof Observable 
				? this.dataSource 
				: of(this.dataSource); 
		}

		this.tableNum = window['tableCount']++;
		this.pageSizeSource = new BehaviorSubject<number>(this.pageSize);
		this.pageSize$ = this.pageSizeSource.asObservable();

		if (typeof this.dataSource === 'string') {
			// Add our server-side scripting events
			if (this.serverSide === true) { new TableServerSide(this).addServersideEvents(); } 
			else {
				this.ajaxReload(o => {
					this.data = o.data;
					new TableNoServerSide(this).addNoServersideEvents();
				});
			}
		} else { 
			/** Add our client-side scripting events */
			if (this.tblData !== null) { this.data = this.tblData; }
			new TableNoServerSide(this).addNoServersideEvents();
		}

		if (this.redirectFunction == null) {this.redirectFunction = () => {}}
	}

	ngAfterContentInit() {
		// Add passed in column definitions
		let hasFooter = false;
		this.contentColumnDefs.forEach(o => {
			this.table.addColumnDef(o);
			if (typeof o.footerCell !== 'undefined') { hasFooter = true; }
		});

		if (hasFooter) { this.table.addFooterRowDef(this.footerRow); }
		// Add the multiple search, if desired
		if (this.multipleSearch) { this.table.addHeaderRowDef(this.searchHeader); }
	}

	/** This is hacky, and unfortunately, I don't know a better place to put this code.
	 * We need to constantly make sure the table becomes overflow: auto / fixed height if it becomes wider
	 * than the table container. It's also necessary to make sure the offset for the second header row
	 * containing searches is correct. Both of these things can change based on all kinds of table operations,
	 * and it needs to be fixed based on what is rendered.
	 */
	ngAfterViewChecked() {
		this.fixResponsive();
		this.fixSearchHeaderTop();
	}

	// Unsubscribe from our subscription(s)
	ngOnDestroy() {
		if (this.multipleSearch) {
			const dynamicStyle = document.getElementById(`searchHeader-${this.tableNum}`);
			if (dynamicStyle) { document.head.removeChild(dynamicStyle); }
		}
		this.subscriptions.unsubscribe();
	}

	updatePerColumnSearch(index: number, newValue: string) {
		const newColumnSearch = this.perColumnSearchSource.value.slice(0); // Make shallow copy of current column search
		newColumnSearch[index] = newValue; // Set new value
		this.perColumnSearchSource.next(newColumnSearch); // Update the per column search values
	}

	getExcel(exportName: string = null) { this.ajaxReload(() => {}, 'Excel', exportName); }
	getPdf(exportName: string = null) { this.ajaxReload(() => {}, 'PDF', exportName); }
	emitClick(row) { this.clickRow.emit(row); }

	ajaxReload(
		ajaxReturnFunc: (colResult: PcgTableResult) => void = () => {}
		, exportType: string = null
		, reportName: string = null
	) {
		// Create a column list containig sort and per column search information
		const perColSearches = this.perColumnSearchSource.value;
		const columns: PcgTableInputColumn[] = [];
		const colNames = this.getColDefs();
		for (let i = 0; i < perColSearches.length; ++i) {
			const theCol: PcgTableInputColumn = { searchText: perColSearches[i] };
			const sortData = this.pcgSort.sortData;
			const colName = colNames[i];
			const mySort = sortData.find(o => o[0] === colName);
			if (mySort) {
				theCol.sortColumnNum = this.pcgSort.sortData.indexOf(mySort);
				theCol.sortDirection = mySort[1];
			} else {
				theCol.sortColumnNum = null;
				theCol.sortDirection = null;
			}
			columns.push(theCol);
		}

		// Default the report name to what is in the h1, if nothing provided
		const theH1 = document.querySelector('h1'); 
		if (theH1 && !reportName) { reportName = theH1.innerText; } 

		// Create the object to send to the server
		const obj: PcgTableInput = {
			columns: null,
			start: this.pageSizeSource.value * (this.currentPageSource.value - 1),
			length: !this.showPagination ? -1 : this.pageSizeSource.value,
			searchText: this.filterSource.value,
			exactMatch: this.exactMatchSource.value,
			exportType,
			reportName
		};

		// Check if they are changing exact match + have no search string
		if (
			this.prevTableInput !== null &&
			this.prevTableInput.exactMatch !== obj.exactMatch &&
			obj.searchText === ''
		) {
			this.prevTableInput = obj;
			return; // Don't do a request if they are checking/unchecking exact match with no search string
		}
		// Remember the previous table input data
		this.prevTableInput = obj;

		// Get a query string based off of the user supplied ajax data
		const userAjaxDataString = !this.ajaxData
			? ''
			: typeof this.ajaxData === 'string'
				? this.ajaxData
				: param(this.ajaxData);

		// Cancel any current AJAX calls to the server
		this.subscriptions.unsubscribe();
		this.subscriptions = new Subscription();

		// User must provide an Excel password if we have protected fields
		if (exportType === 'Excel' && this.hasProtectedFields) {
			this.sec.promptPassword(excelPassword => { this.sendServerRequest(obj, userAjaxDataString, excelPassword, columns, ajaxReturnFunc); });
			return;
		}

		this.hasCompletedServerRequest = exportType === 'Excel' || exportType === 'PDF';
		if (!FormatHelperService.GetIsNullyOrWhitespace(this.serviceDataSource) || !FormatHelperService.GetIsNullyOrWhitespace(this.dataSource))
			this.sendServerRequest(obj, userAjaxDataString, '', columns, ajaxReturnFunc);
		else { } 
		return true;
	}

	/** Show or hide a column in the table. This is equivalent to
	 * clicking the "Show / Hide" button and toggling the visibility
	 * of a column.
	 */
	hideShowColumn(columnName: string, isVisible: boolean) { this.columnDefs.get(columnName).isVisible = isVisible; }

	// Removes specified columns from cards (right-side) on mobile
	getMobileColDefs() {
		const visibleColDefs = this.getVisibleColDefs();
		return visibleColDefs.filter(def => def !== this.uniqueColumn && def !== 'canDelete');
	}

	/**
	 * Send the table information to the server.
	 * Table data will be updated when it returns.
	 */
	public sendServerRequest(
		obj: PcgTableInput
		, userAjaxDataString: string
		, excelPassword: string
		, columns: PcgTableInputColumn[]
		, ajaxReturnFunc: (colResult: PcgTableResult) => void
	) {
		if (FormatHelperService.GetIsNullyOrWhitespace(this.serviceDataSource)) { 
			this.subscriptions.add(
				this.http.get(
					`${this.dataSource}?` +
						`${param(obj, true)}&${userAjaxDataString}&excelPassword=${encodeURIComponent(excelPassword)}` +
						`&columnJson=${encodeURIComponent(
							JSON.stringify(
								columns.map(o => {
									const newObj: any = {};
									if (o.searchText !== null && o.searchText !== '') { newObj.searchText = o.searchText; }
									if (o.sortColumnNum !== null) { newObj.sortColumnNum = o.sortColumnNum; }
									if (o.sortDirection !== null) { newObj.sortDirection = o.sortDirection; }
									return newObj;
								})
							)
						)}`
				).subscribe((tr: PcgTableResult) => { this.setTable(tr, ajaxReturnFunc); })
			);
		} else {
			this.tableService.getData(this.serviceDataSource, obj, userAjaxDataString, excelPassword, columns)
				.subscribe((tr: PcgTableResult) => { this.setTable(tr, ajaxReturnFunc); }
			);
		}
	}

	private setTable(
		tableResult: PcgTableResult
		, ajaxReturnFunc: (colResult: PcgTableResult) => void
	) {
		if (tableResult.exportLocation) { window.open(tableResult.exportLocation); } 
		else {
			this.totalDataCount = tableResult.recordsTotal;
	
			// Need to apply filters if we are handling data
			if (this.serverSide === false) { this.dataNoServerSideUpdateSource.next(tableResult.data); } 
			else {
				// Otherwise, set the next page and counts from server
				this.filteredDataCount = tableResult.recordsFiltered;
				this.data = this.tblData === null 
					? this.filteredDataCount === 0 
						? [{}] 
						: tableResult.data
					: this.tblData;
				this.dataOnPageSource.next(this.data);
				this.hasCompletedServerRequest = true;
			}
	
			if (tableResult.hasProtectedFields) { this.hasProtectedFields = true; }
			this.tableReceive.emit(tableResult);
			ajaxReturnFunc(tableResult);
			if (this.callbackFunc) { this.callbackFunc(tableResult); }
			this.tableData = tableResult;	// tableData is used for new mobile card view
		}
	}

	/** This fixes the sticky offset for the search columns, when multiple search is enabled */
	private fixSearchHeaderTop() {
		if (this.multipleSearch) {
			const tableHeadRow = this.tableContainer.nativeElement.querySelector('table thead tr th');
			const computedStyles = getComputedStyle(tableHeadRow);
			const newSearchRowTopOffset = parseInt(computedStyles.top, 10) + parseInt(computedStyles.height, 10) - 1;

			if (newSearchRowTopOffset !== this.searchRowTopOffset) {
				this.searchRowTopOffset = newSearchRowTopOffset;
				this.setDynamicStyle(
					`searchHeader-${this.tableNum}`,
					`.pcg-table-fixed-header .pcg-table-${this.tableNum} thead tr.search-row th,
					.pcg-table-fixed-header .pcg-table-${this.tableNum} thead tr.search-row {
						top: ${newSearchRowTopOffset}px
					}`
				);
				this.cdRef.detectChanges();
			}
		}
	}

	private setDynamicStyle(
		name: string
		, styles: string
	) {
		let dynamicStyle = document.getElementById(name);
		if (dynamicStyle) { document.head.removeChild(dynamicStyle); }
		dynamicStyle = document.createElement('style');
		dynamicStyle.id = name;
		dynamicStyle.innerHTML = styles;
		document.head.appendChild(dynamicStyle);
	}

	/** Make the table container overflow auto and fixed height
	 *  based on whether or not the table is overflowing its container.
	 */
	private fixResponsive() {
		if (this.alwaysBoxed && !this.isResponsive) {
			this.isResponsive = true;
			this.cdRef.detectChanges();
		} else if (this.responsive) {
			const tableContainerWidth = this.tableContainer.nativeElement.getBoundingClientRect().width;
			const tableWidth = this.tableContainer.nativeElement.querySelector('table').getBoundingClientRect().width;
			if (this.isResponsive !== tableWidth > tableContainerWidth) {
				this.isResponsive = tableWidth > tableContainerWidth;
				this.cdRef.detectChanges();
			}
		}
	}
}
