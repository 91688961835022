<!-- REGULAR VERSION -->
<div *ngIf="isNotSmallScreen; else smallPassword">
    <div class="row">
        <pcg-control-group class="col-sm-12" [label]="label" [validations]="validations">
            <div class="showHidePassword" [formGroup]="parentForm">
                <input 
                    pcgControlRef 
                    formControlName="{{controlName}}" 
                    ngDefaultControl
                    [type]="showPassword ? 'text' : 'password'" 
                    [ngClass]="inputCssClass" 
                />
                <button 
                    type="button" 
                    class="showHidePassword" 
                    (click)="toggleShowHidePassword()"
                >
                    <fa-icon [icon]="showPassword ? faIconName.faEye : faIconName.faEyeSlash"></fa-icon>
                </button>
            </div>
        </pcg-control-group>
    </div>
</div>

<!-- SMALL VERSION -->
<ng-template #smallPassword>
    <label class="sr-only" for="password">Password: </label>
    <div class="showHidePassword" [formGroup]="parentForm">
          <input 
              pcgControlRef 
              formControlName="{{controlName}}" 
              ngDefaultControl
              [type]="showPassword ? 'text' : 'password'" 
              [ngClass]="inputCssClass" 
          />
          <button 
              type="button" 
              class="showHidePassword" 
              (click)="toggleShowHidePassword()"
          >
            <fa-icon [icon]="showPassword ? faIconName.faEye : faIconName.faEyeSlash"></fa-icon>
          </button>
    </div>
</ng-template>




