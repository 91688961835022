export interface ICustomerAliasListTbl {
	customerAliasId: number;
	fileSource?: string;
	name?: string;
	shippingStreetAddress?: string;
	shippingCity?: string;
	shippingState?: string;
	shippingZipCode?: string;
	canDelete: boolean;
};

export class CustomerAliasListTbl implements ICustomerAliasListTbl {
	customerAliasId = 0;
	fileSource = '';
	name = '';
	shippingStreetAddress = '';
	shippingCity = '';
	shippingState = '';
	shippingZipCode = '';
	canDelete = false;
};
