<div class="modal-header">
	<h5 class="modal-title" id="modal-basic-title">Inactivity Timeout</h5>
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body text-center">
	<p>Your session will timeout soon. Click the button below to extend your session.</p>
	<p>Otherwise, you will be logged out in {{subscribeTimer}} seconds.</p>
</div>
<div class="modal-footer">
	<button class="btn btn-save btn-lg" (click)="modal.dismiss()">Extend Session</button>
</div>

