import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class SalesRepListVm {
	salesRepId: number;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	team?: string;
	territory?: string;
	region?: string;
	commission: number;
	discount: number;
	canDelete: boolean;
	payrollId?: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'salesRepId': new UntypedFormControl(0, [...(validators['salesRepId'] ?? []), ...[Validators.required]]),
			'fullName': new UntypedFormControl(null, [...(validators['fullName'] ?? []), ...[]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[]]),
			'team': new UntypedFormControl(null, [...(validators['team'] ?? []), ...[]]),
			'territory': new UntypedFormControl(null, [...(validators['territory'] ?? []), ...[]]),
			'region': new UntypedFormControl(null, [...(validators['region'] ?? []), ...[]]),
			'commission': new UntypedFormControl(0, [...(validators['commission'] ?? []), ...[Validators.required]]),
			'discount': new UntypedFormControl(0, [...(validators['discount'] ?? []), ...[Validators.required]]),
			'canDelete': new UntypedFormControl(false, [...(validators['canDelete'] ?? []), ...[]]),
			'payrollId': new UntypedFormControl(null, [...(validators['payrollId'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['fullName', {"orderId":0,"sortColumn":"fullName","searchColumn":"fullName","includeInExcel":true,"exportColumn":null,"displayName":"Full Name","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['email', {"orderId":1,"sortColumn":"email","searchColumn":"email","includeInExcel":true,"exportColumn":null,"displayName":"Email","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['phoneNumber', {"orderId":2,"sortColumn":"phoneNumber","searchColumn":"phoneNumber","includeInExcel":true,"exportColumn":null,"displayName":"Phone Number","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['team', {"orderId":3,"sortColumn":"team","searchColumn":"team","includeInExcel":true,"exportColumn":null,"displayName":"Team","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['territory', {"orderId":4,"sortColumn":"territory","searchColumn":"territory","includeInExcel":true,"exportColumn":null,"displayName":"Territory","isVisible":true,"canSearch":false,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['region', {"orderId":5,"sortColumn":"region","searchColumn":"region","includeInExcel":true,"exportColumn":null,"displayName":"Region","isVisible":true,"canSearch":false,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['commission', {"orderId":6,"sortColumn":"commission","searchColumn":"commission","includeInExcel":true,"exportColumn":null,"displayName":"Commission","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"percent"}],
			['discount', {"orderId":7,"sortColumn":"discount","searchColumn":"discount","includeInExcel":true,"exportColumn":null,"displayName":"Discount","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":"percent"}],
			['payrollId', {"orderId":8,"sortColumn":"payrollId","searchColumn":"payrollId","includeInExcel":true,"exportColumn":null,"displayName":"Payroll ID","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['canDelete', {"orderId":9,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":false,"exportColumn":null,"displayName":"Delete","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): SalesRepListVmFromFg {
		return new SalesRepListVmFromFg(fg);
	}
}

export class SalesRepListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get salesRepId(): number { return this.fg.get('salesRepId').value; }
	set salesRepId(val) { this.fg.get('salesRepId').setValue(val); }

	get fullName(): string { return this.fg.get('fullName').value; }
	set fullName(val) { this.fg.get('fullName').setValue(val); }

	get email(): string { return this.fg.get('email').value; }
	set email(val) { this.fg.get('email').setValue(val); }

	get phoneNumber(): string { return this.fg.get('phoneNumber').value; }
	set phoneNumber(val) { this.fg.get('phoneNumber').setValue(val); }

	get team(): string { return this.fg.get('team').value; }
	set team(val) { this.fg.get('team').setValue(val); }

	get territory(): string { return this.fg.get('territory').value; }
	set territory(val) { this.fg.get('territory').setValue(val); }

	get region(): string { return this.fg.get('region').value; }
	set region(val) { this.fg.get('region').setValue(val); }

	get commission(): number { return this.fg.get('commission').value; }
	set commission(val) { this.fg.get('commission').setValue(val); }

	get discount(): number { return this.fg.get('discount').value; }
	set discount(val) { this.fg.get('discount').setValue(val); }

	get canDelete(): boolean { return this.fg.get('canDelete').value; }
	set canDelete(val) { this.fg.get('canDelete').setValue(val); }

	get payrollId(): number { return this.fg.get('payrollId').value; }
	set payrollId(val) { this.fg.get('payrollId').setValue(val); }

}
