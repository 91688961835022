import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SpinnerService {

	constructor() { }

	private loadingSource = new Subject<boolean>();

	loadingSource$ = this.loadingSource.asObservable();

	showSpinner() { this.loadingSource.next(true); }
	hideSpinner() { this.loadingSource.next(false); }
}
