<div id="addContent" class="hide-on-print">
	<div id="actionElements">
		<a routerLink="/territories/edit-territory/0" class="btn btn-save btn-sm inline-add-btn mt-2">Add Territory</a>
	</div>
</div>
<pcg-table #table [dataSource]="dataSource" [pcgSort]="[['fullName', 'asc']]" [columnDefs]="columns"
	[isRowClickable]="true" isUsingMobileCard="true" uniqueColumn="name" [redirectFunction]="redirectTerritory"
	[canExportTableToExcel]="true" deleteUrl="api/Sales/SalesTerritory/DeleteTerritory"
	identificationColumn="territoryId" confirmMessage="Are you sure you want to delete this territory?">

	<ng-container matColumnDef="name">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>
			{{ columns.get('name').displayName }}
		</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('name').displayName">
			<pcg-edit-link [url]="'/territories/edit-territory/' + row.territoryId">
				{{ row.name }}
			</pcg-edit-link>
		</td>
	</ng-container>

	<ng-container matColumnDef="region">
		<th mat-header-cell *matHeaderCellDef>{{ columns.get('region').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('region').displayName">{{ row.region }}</td>
	</ng-container>

	<ng-container matColumnDef="manager">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('manager').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('manager').displayName">{{ row.manager }}</td>
	</ng-container>
	<ng-container matColumnDef="salesRep1">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('salesRep1').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('salesRep1').displayName">{{ row.salesRep1 }}
		</td>
	</ng-container>
	<ng-container matColumnDef="salesRep2">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('salesRep2').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('salesRep2').displayName">{{ row.salesRep2 }}
		</td>
	</ng-container>
	<ng-container matColumnDef="canDelete">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('canDelete').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('canDelete').displayName">
			<pcg-delete-button [table]='table' [confirmMessage]='"Are you sure you want to delete this territory?"'
				url='api/Sales/SalesTerritory/DeleteTerritory' [id]='row.territoryId'></pcg-delete-button>
		</td>
	</ng-container>
</pcg-table>