import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { EditUserVm } from 'app/shared/generated/Administration/Models/User/EditUser/EditUserVm';
import { PcgTableInput, PcgTableInputColumn } from 'app/shared/table/table.component';
import { AppDatabase } from 'local-db/services/init.ldb.service';
import { param } from 'app/shared/http-params';

@Injectable({
    providedIn: 'root'
})
export class CachePrimerService {

    constructor(
        private http: HttpClient
        , private localDb: AppDatabase
    ) { }

    primeCache(): void {
        const tableInput: PcgTableInput = {
            columns: null,
			start: 0,
			length: 100,
			searchText: "",
			exactMatch: false,
			exportType: null,
			reportName: null
        }

        let perColumnSearchSource = new BehaviorSubject<string[]>([]);
        perColumnSearchSource.next(Array.from(new Array(Array.from(EditUserVm.ColumnDefs.keys()).length), () => ''));
        let perColSearches = perColumnSearchSource.value;

        const columns: PcgTableInputColumn[] = [];
		const colNames = Array.from(EditUserVm.ColumnDefs.keys());
		for (let i = 0; i < perColSearches.length; ++i) {
			const theCol: PcgTableInputColumn = { searchText: perColSearches[i] };
			const sortData = [['fullName', 'asc']];
			const colName = colNames[i];
			const mySort = sortData.find(o => o[0] === colName);
			if (mySort) {
				theCol.sortColumnNum = 0;
				theCol.sortDirection = mySort[1];
			} else {
				theCol.sortColumnNum = 0;
				theCol.sortDirection = null;
			}
			columns.push(theCol);
		}

        let filters = { isActive: true };
        let userAjaxDataString = param(filters);

        // precache the user list
        this.http.get(
            `api/Administration/Users/GetUserList?` +
            `${param(tableInput, true)}&${userAjaxDataString}&excelPassword=${encodeURIComponent("")}` +
            `&columnJson=${encodeURIComponent(
                JSON.stringify(
                    columns.map((o, index)=> {
                        const newObj: any = {};
                        if (index > 0) { o.sortColumnNum = null; }
                        if (o.searchText !== null && o.searchText !== '') { newObj.searchText = o.searchText; }
                        if (o.sortColumnNum !== null) { newObj.sortColumnNum = o.sortColumnNum; }
                        if (o.sortDirection !== null) { newObj.sortDirection = o.sortDirection; }
                        return newObj;
                    })
                )
            )}`
        ).subscribe((d: any) => { 
            for (let x = 0; x < d.data.length; x++) {
                this.localDb.EditUserTbl.put({
                    userId: d.data[x].userId,
                    userName: d.data[x].userName,
                    fullName: d.data[x].fullName,
                    phoneNumber: d.data[x].phoneNumber,
                    email: d.data[x].email,
                    permissionId: d.data[x].permissionId,
                    isActive: d.data[x].isActive,
                    isLockedOut: d.data[x].isLockedOut,
                    inactivityTimeout: d.data[x].inactivityTimeout,
                    canBeDeleted: d.data[x].canBeDeleted,
                    friendlyId: d.data[x].fullName,
                    isUpdatedOffline: false,
                    isNewUser: d.data[x].isNewUser
                });
            } 
        });

        //precache the permissions select
        this.http.get("api/Select/Permissions").subscribe((d: any) => {});
    }
}
