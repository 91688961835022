import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject, of, throwError } from 'rxjs';
import { tap, retryWhen, flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface SystemMessageSettings {
	centeredStyles: any; // Styles used to keep message centered
	openDate: Date; // Date the system message was opened
	messageClass?: string; // Should be 'success', 'warning', or 'error'
	message?: string; // The message to display in system message
	htmlMessage?: SafeHtml; //HTML Message to display in System message
	messageOpen: boolean; // Whether or not message is currently visible
	firstLoad: boolean; // Used to stop fade in on load
	timeOut?: number; // Time until the message disappears in milliseconds
}

export interface SystemMessage {
	messageClass?: string; // Should be 'success', 'warning', or 'error'
	message?: string; // The message to display in system message
	isSuccessful: boolean; // Whether or not the request was successful
	model?: any; // Can be used to replace a model property in the calling component
	value?: any; // Can be used in javascript passed in
	javaScript?: string; // Javascript to be run when a message returns
	route: string; //if a route is specified the user will be able to click the system message to get brought there
	timeout?: number;
}

/**
 * This service is a singleton that controls the content
 * and visibility of system messages throughout the application.
 */
@Injectable({
	providedIn: 'root'
})
export class SystemMessageService {

	// Set initial settings
	settings: SystemMessageSettings = {
		centeredStyles: this.getCenteredStyles(),
		messageOpen: false,
		firstLoad: true,
		messageClass: '',
		openDate: new Date()
	};

	// Create a source and observable to send setting changes
	settingsSource = new Subject<SystemMessageSettings>();
	settings$ = this.settingsSource.asObservable();

	//route for whenever we want the user to click the error message to bring them somewhere
	route: string;

	// Bind the resize event on load. This will only happen one time
	// since the service is a singleton. The event will never be removed.
	constructor(
		private http: HttpClient
		, private router: Router
		, private sanitizer: DomSanitizer
	) { window.addEventListener('resize', this.onResize.bind(this), true); }

	/**
	 * Show a system message
	 *
	 * @param message The message to show in the system message.
	 * @param messageClass Should be either 'success', 'warning', or 'error'
	 * @param timeOut Time to show message in milliseconds. Default is to show
	 * until it is manually closed for errors. The default is 5000 for warning
	 * and success messages. You can pass in 0 to make a success or warning message not close.
	 */
	setSystemMessage(message: string, messageClass = 'success', timeOut: number = null, route: string = null) {
		const newSettings = {
			...this.settings,
			message,
			htmlMessage: this.sanitizer.bypassSecurityTrustHtml(message),
			messageClass,
			messageOpen: true,
			firstLoad: false,
			openDate: new Date(),
			timeOut: timeOut || (messageClass !== 'error' ? 1600 : 5000)
		};
		
		this.settingsSource.next(newSettings);
		this.settings = newSettings;
		this.route = route;

		setTimeout(() => {
			// Close the system message on a timer, if necessary
			if (newSettings.timeOut && newSettings.openDate === this.settings.openDate) {
				this.route = null;
				this.closeSystemMessage();
			}
		}, newSettings.timeOut);
	}

	/** Close a system message */
	closeSystemMessage() {
		if (this.route != null) this.router.navigateByUrl(this.route);
		this.settings.messageOpen = false;
		this.settingsSource.next(this.settings);
	}

	/**
	 * Creates an observable which gets a system message from the server and processes it.
	 * Example Call: myForm$ = this.ms.getHttpObservable(this, 'api/Save', this.myForm);
	 *
	 * @param url The location of the server API call that returns a system message.
	 * @param form The form group from which to get parameters to pass to API method.
	 * @param params Additional parameters pass to API method.
	 */
	post(url: string, form: UntypedFormGroup = null, params: any = null) { return this.getHttpObservable(null, url, form, params); }

	/**
	 * Creates an observable which gets a system message from the server and processes it.
	 * Example Call: myForm$ = this.ms.getHttpObservable(this, 'api/Save', this.myForm);
	 *
	 * @param component A reference to the current component. Generally should just = this.
	 * @param url The location of the server API call that returns a system message.
	 * @param form The form group from which to get parameters to pass to API method.
	 * @param params Additional parameters pass to API method.
	 */
	getHttpObservable(component: any, url: string, form: UntypedFormGroup = null, params: any = null, timeout: number = null) {
		const objToSend = { ...(form === null ? {} : form.getRawValue()), ...params };
		return this.http.post<SystemMessage>(url, objToSend, {}).pipe(
			tap(msg => {
				// Show the system message, if a message was given
				if (msg.message !== null && msg.message !== '') {
					this.setSystemMessage(
						msg.message,
						!msg.messageClass ? 'success' : msg.messageClass,
						msg.timeout !== null ? msg.timeout : timeout,
						msg.route
					);
				}
				// Merge model into form, if provided
				if (msg.model !== null && form != null) { form.patchValue(msg.model); }
			}),
			// Retry when we receive a confirmation message and they click 'OK'
			retryWhen(errors =>
				errors.pipe(
					flatMap((err: HttpErrorResponse) => {
						// Error 466 is thrown when we do Notification.Confirm in the backend
						if (err.status === 466 && confirm(err.error)) {
							// Set confirmedMessages to an empty list, if it's undefined
							if (!objToSend.confirmedMessages) { objToSend.confirmedMessages = []; }
							// Append the message to the confirmed messages array on objToSend
							// so it will be sent when we retry the request
							objToSend.confirmedMessages.push(err.error);
							return of(err.status);
						}
						return throwError(err);
					})
				)
			)
		);
	}

	/** Need to adjust CSS styles for system message on resize to keep it centered */
	private onResize() {
		this.settings.centeredStyles = this.getCenteredStyles();
		this.settingsSource.next(this.settings);
	}

	/** Set width and position of system message.
	 * Maximum width is 650px. It will go smaller than that if needed.
	 */
	private getCenteredStyles() {
		const mm = document.body.clientWidth;
		const mw = 650;
		return {
			minWidth: (mm < mw ? mm : mw) + 'px',
			left: (mm < mw ? 0 : (mm - mw) / 2) + 'px'
		};
	}
}
