import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { ForgotPasswordVm } from 'app/shared/generated/Models/ForgotPasswordVm';

@Component({
	selector: 'pcg-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnDestroy, AfterViewInit {

	forgotPasswordForm = ForgotPasswordVm.Form;
	forgotPasswordSub: Subscription = new Subscription();
	checkEmail = false;

	constructor(private ms: SystemMessageService) { }

    ngAfterViewInit() { }

	onSubmit() {
		if (this.forgotPasswordForm.valid) {
			this.forgotPasswordSub.add(this.ms.getHttpObservable(this, 'api/Account/ForgotPassword', this.forgotPasswordForm)
				.subscribe(msg => { if (msg && msg.isSuccessful) { this.checkEmail = true; } }));
		} else {
			validateForm(this.forgotPasswordForm);
		}
	}

	ngOnDestroy() { this.forgotPasswordSub.unsubscribe(); }
}
