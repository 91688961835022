import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class UploadFileVm {
	fileSource?: any;
	file?: any;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'fileSource': new UntypedFormControl('', [...(validators['fileSource'] ?? []), ...[Validators.required]]),
			'file': new UntypedFormControl(null, [...(validators['file'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): UploadFileVmFromFg {
		return new UploadFileVmFromFg(fg);
	}
}

export class UploadFileVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get fileSource(): any { return this.fg.get('fileSource').value; }
	set fileSource(val) { this.fg.get('fileSource').setValue(val); }

	get file(): any { return this.fg.get('file').value; }
	set file(val) { this.fg.get('file').setValue(val); }

}
