import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { TableComponent } from 'app/shared/table/table.component';
import { ReleaseNotesVm } from 'app/shared/generated/Models/ReleaseNotesVm';

@Component({
	selector: 'pcg-release-notes',
	templateUrl: './release-notes.component.html',
	styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<ReleaseNotesVm>;
	columns = ReleaseNotesVm.ColumnDefs;

	constructor(public title: Title) { }

	ngOnInit() { this.title.setTitle('Release Notes'); }
}
