<!-- Modal for prompting for a password to secure a file -->
<form *ngIf='!promptForUsersPassword' (ngSubmit)="setPassword()" [formGroup]="changePasswordForm">
	<div class="modal-header">
		<h5 class="modal-title" id="modal-basic-title">{{ title }}</h5>
		<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<div class="text-center">
			{{ message }}<br/><br/>
			<div *ngIf='showPasswordInfo'>
				<u>Password Requirements</u><br/>
				Must be at least 10 characters in Length.<br/>
				Cannot match any of the passwords in our list of commonly used passwords.<br/>
			</div>
		</div>

		<div class='row'>
			<pcg-control-group [validations]='{ "commonPassword": "Pick a less common password." }' class="col-sm-12"
				label="New Password">
				<input 
					pcgControlRef 
					(keyup.enter)='setPassword()' 
					formControlName="password" 
					type="password" 
					class="form-control" 
				/>
			</pcg-control-group>
		</div>

		<div class='row'>
			<pcg-control-group [validations]='{ "passwordsNotEqual": "Passwords do not match." }' class="col-sm-12"
				label="Confirm New Password">
				<input 
					pcgControlRef 
					(keyup.enter)='setPassword()' 
					formControlName="confirmPassword" 
					type="password" 
					class="form-control" 
				/>
			</pcg-control-group>
		</div>
	</div>

	<div class="modal-footer">
		<button type='button' class="btn btn-cancel btn-lg" (click)="activeModal.dismiss()">Cancel</button>
		<button type="submit" class="btn btn-save btn-lg">Set Password</button>
	</div>
</form>

<!-- Modal for prompting for current user's password -->
<div *ngIf='promptForUsersPassword'>
	<div class="modal-header">
		<h5 class="modal-title" id="modal-basic-title">{{ title }}</h5>
		<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	
	<div class="modal-body">
		<div class="text-center">
			{{ message }}<br/><br/>
		</div>
		<input [(ngModel)]='password' (keyup.enter)='setPassword()' type="password" class="form-control" />
	</div>

	<div class="modal-footer">
		<button type='button' class="btn btn-cancel btn-lg" (click)="activeModal.dismiss()">Cancel</button>
		<button type="button" (click)='setPassword()' class="btn btn-save btn-lg">Continue</button>
	</div>
</div>
