import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

import { NavRoute } from '../nav.route.interface';
import { NavigationService, NavDividerCharacter } from '../navigation.service';
import { Router } from '@angular/router';

@Component({
	selector: 'pcg-top-secondary-nav',
	templateUrl: './top-secondary-nav.component.html',
	styleUrls: ['./top-secondary-nav.component.scss']
})
export class TopSecondaryNavComponent implements OnInit, OnChanges {

	@Input() navRoutes: NavRoute[] = [];
	@Input() rootNavId: string;
	@Input() routeNavId: string;
	@Input() forceSelect: string;
	@Input() tabPanel: any;
	@Output() clickedNav = new EventEmitter<boolean>();

	loopNavRoutes: NavRoute[] = [];

	faIconName = { faArrowLeft };

	constructor(public navService: NavigationService, public router: Router) { }

	ngOnInit() { this.loopNavRoutes = this.getNavRoutes(); }

	ngOnChanges(changes: SimpleChanges) {
		const chng = changes['navRoutes'];
		const cur = chng.currentValue;
		const prev = chng.previousValue;
		if (cur !== prev) { this.loopNavRoutes = this.getNavRoutes(); }
	}

	// Show or hide submenu based on dropdown click
	clickDropdown(childMenuId: string, back = false) {
		if (back) {
			const regexp = new RegExp(`${NavDividerCharacter}[^${NavDividerCharacter}]+$`);
			childMenuId = childMenuId.replace(regexp, '');
		}
		this.navService.setCurrOpenNavMenu(childMenuId, true);
		return false;
	}

	getNavRoutes() {
		const routeIndex = this.navService.getCurrRouteIndex(this.navRoutes);
		return this.navRoutes?.map((o, i) => {
			return { ...o, index: i };
		}).filter(route => !route?.shouldDisplay || route.ellipseAfter || route.ellipseBefore || route?.shouldDisplay(routeIndex)) ?? [];
	}

	openInNewTab(namedRoute: string) {
		let newRelativeUrl = this.router.createUrlTree([namedRoute]);
		let baseUrl = window.location.href.replace(this.router.url, '');
		this.NavToPreviousRoute(this.router.getCurrentNavigation().previousNavigation.finalUrl.toString())
		window.open(baseUrl + newRelativeUrl, '_blank');
	}

	/*This is to keep the nav from redirecting to the app's home screen after a navlink opens in a new tab e.g. Quartz */
	NavToPreviousRoute = (path: string) => this.router.navigateByUrl(path);	
}
