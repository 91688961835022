import { de } from 'date-fns/locale';
import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const getCustomersNav: () => NavRoute = () => ({
	name: 'Master Customers',
	children: getCustomersArea(),
});

function getCustomersArea(): NavRoute[] {
	var id: string = "";
	var url: string = window.location.href;

	if(url.indexOf('master-customers/edit-master-customer/') > -1) {
		id = url.substring(url.lastIndexOf('/') + 1);
	}

	if(id === "") {
		return [
			{
				name: 'Master Customers'
				, exact: true
				, path: 'master-customers/master-customer-list'
			},
			{
				name: 'Exceptions'
				, exact: true
				, path: `master-customers/master-customer-import-exceptions`
			},
		]
	}

	else {
		return [
			{
				name: 'Back'
				, exact: true
				, path: 'workflow/1'
			},
			{
				name: 'Details'
				, exact: true
				, path: `master-customers/edit-master-customer/${id}`
			}
		]
	}
}
