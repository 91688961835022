import { Component, ViewChild } from '@angular/core';
import { TableComponent } from 'app/shared/table/table.component';
import { SalesTerritoryListVm } from 'app/shared/generated/Sales/Models/SalesTerritoryListVm'
import { Title } from '@angular/platform-browser';
import { SharedModule } from 'app/shared/shared.module';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NavigationService } from 'app/shared/navigation/navigation.service';
@Component({
  selector: 'app-territories',
  standalone: true,
  imports: [SharedModule, RouterModule],
  templateUrl: './territories.component.html',
  styleUrls: ['./territories.component.css']
})
export class TerritoriesComponent {

  @ViewChild(TableComponent, { static: false }) table: TableComponent<SalesTerritoryListVm>;
  columns = SalesTerritoryListVm.ColumnDefs;
  dataSource = "api/Sales/SalesTerritory/GetSalesTerritoryList";
  constructor(title: Title, public nav: NavigationService, public route: ActivatedRoute, public router: Router) {
    title.setTitle('Territory List');
  }

  async reloadTable() {
    this.table.ajaxData;
    this.table.ajaxReload();
  }
  redirectTerritory(row) {
    this.router.navigate(['/territories/edit-territory/', row.territoryId])
  }
}
