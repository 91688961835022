import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class CustomerListVm {
	customerId: number;
	name?: string;
	shippingStreetAddress?: string;
	shippingCity?: string;
	shippingState?: string;
	shippingZipCode?: string;
	billingStreetAddress?: string;
	billingCity?: string;
	billingState?: string;
	billingZipCode?: string;
	email?: string;
	purchaseOrder?: string;
	invoiceNumber?: string;
	region?: string;
	canDelete: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'customerId': new UntypedFormControl(0, [...(validators['customerId'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[]]),
			'shippingStreetAddress': new UntypedFormControl(null, [...(validators['shippingStreetAddress'] ?? []), ...[]]),
			'shippingCity': new UntypedFormControl(null, [...(validators['shippingCity'] ?? []), ...[]]),
			'shippingState': new UntypedFormControl(null, [...(validators['shippingState'] ?? []), ...[]]),
			'shippingZipCode': new UntypedFormControl(null, [...(validators['shippingZipCode'] ?? []), ...[]]),
			'billingStreetAddress': new UntypedFormControl(null, [...(validators['billingStreetAddress'] ?? []), ...[]]),
			'billingCity': new UntypedFormControl(null, [...(validators['billingCity'] ?? []), ...[]]),
			'billingState': new UntypedFormControl(null, [...(validators['billingState'] ?? []), ...[]]),
			'billingZipCode': new UntypedFormControl(null, [...(validators['billingZipCode'] ?? []), ...[]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[]]),
			'purchaseOrder': new UntypedFormControl(null, [...(validators['purchaseOrder'] ?? []), ...[]]),
			'invoiceNumber': new UntypedFormControl(null, [...(validators['invoiceNumber'] ?? []), ...[]]),
			'region': new UntypedFormControl(null, [...(validators['region'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(false, [...(validators['canDelete'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['name', {"orderId":0,"sortColumn":"name","searchColumn":"name","includeInExcel":true,"exportColumn":null,"displayName":"Master Customer Name","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['shippingStreetAddress', {"orderId":1,"sortColumn":"shippingStreetAddress","searchColumn":"shippingStreetAddress","includeInExcel":true,"exportColumn":null,"displayName":"Shipping Address","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['shippingCity', {"orderId":2,"sortColumn":"shippingCity","searchColumn":"shippingCity","includeInExcel":true,"exportColumn":null,"displayName":"Shipping City","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['shippingState', {"orderId":3,"sortColumn":"shippingState","searchColumn":"shippingState","includeInExcel":true,"exportColumn":null,"displayName":"Shipping State","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['shippingZipCode', {"orderId":4,"sortColumn":"shippingZipCode","searchColumn":"shippingZipCode","includeInExcel":true,"exportColumn":null,"displayName":"Shipping Zip Code","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['email', {"orderId":5,"sortColumn":"email","searchColumn":"email","includeInExcel":true,"exportColumn":null,"displayName":"Email","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['billingStreetAddress', {"orderId":6,"sortColumn":"billingStreetAddress","searchColumn":"billingStreetAddress","includeInExcel":true,"exportColumn":null,"displayName":"Billing Address","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['billingCity', {"orderId":7,"sortColumn":"billingCity","searchColumn":"billingCity","includeInExcel":true,"exportColumn":null,"displayName":"Billing City","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['billingState', {"orderId":8,"sortColumn":"billingState","searchColumn":"billingState","includeInExcel":true,"exportColumn":null,"displayName":"Billing State","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['billingZipCode', {"orderId":9,"sortColumn":"billingZipCode","searchColumn":"billingZipCode","includeInExcel":true,"exportColumn":null,"displayName":"Billing Zip Code","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['purchaseOrder', {"orderId":10,"sortColumn":"purchaseOrder","searchColumn":"purchaseOrder","includeInExcel":true,"exportColumn":null,"displayName":"Purchase Order","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['invoiceNumber', {"orderId":11,"sortColumn":"invoiceNumber","searchColumn":"invoiceNumber","includeInExcel":true,"exportColumn":null,"displayName":"Invoice Number","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['region', {"orderId":12,"sortColumn":"region","searchColumn":"region","includeInExcel":true,"exportColumn":null,"displayName":"Region","isVisible":false,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}],
			['canDelete', {"orderId":13,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":false,"exportColumn":null,"displayName":"Delete","isVisible":true,"canSearch":true,"isNdc":false,"multiSearchCellClasses":null,"format":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): CustomerListVmFromFg {
		return new CustomerListVmFromFg(fg);
	}
}

export class CustomerListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }

	get customerId(): number { return this.fg.get('customerId').value; }
	set customerId(val) { this.fg.get('customerId').setValue(val); }

	get name(): string { return this.fg.get('name').value; }
	set name(val) { this.fg.get('name').setValue(val); }

	get shippingStreetAddress(): string { return this.fg.get('shippingStreetAddress').value; }
	set shippingStreetAddress(val) { this.fg.get('shippingStreetAddress').setValue(val); }

	get shippingCity(): string { return this.fg.get('shippingCity').value; }
	set shippingCity(val) { this.fg.get('shippingCity').setValue(val); }

	get shippingState(): string { return this.fg.get('shippingState').value; }
	set shippingState(val) { this.fg.get('shippingState').setValue(val); }

	get shippingZipCode(): string { return this.fg.get('shippingZipCode').value; }
	set shippingZipCode(val) { this.fg.get('shippingZipCode').setValue(val); }

	get billingStreetAddress(): string { return this.fg.get('billingStreetAddress').value; }
	set billingStreetAddress(val) { this.fg.get('billingStreetAddress').setValue(val); }

	get billingCity(): string { return this.fg.get('billingCity').value; }
	set billingCity(val) { this.fg.get('billingCity').setValue(val); }

	get billingState(): string { return this.fg.get('billingState').value; }
	set billingState(val) { this.fg.get('billingState').setValue(val); }

	get billingZipCode(): string { return this.fg.get('billingZipCode').value; }
	set billingZipCode(val) { this.fg.get('billingZipCode').setValue(val); }

	get email(): string { return this.fg.get('email').value; }
	set email(val) { this.fg.get('email').setValue(val); }

	get purchaseOrder(): string { return this.fg.get('purchaseOrder').value; }
	set purchaseOrder(val) { this.fg.get('purchaseOrder').setValue(val); }

	get invoiceNumber(): string { return this.fg.get('invoiceNumber').value; }
	set invoiceNumber(val) { this.fg.get('invoiceNumber').setValue(val); }

	get region(): string { return this.fg.get('region').value; }
	set region(val) { this.fg.get('region').setValue(val); }

	get canDelete(): boolean { return this.fg.get('canDelete').value; }
	set canDelete(val) { this.fg.get('canDelete').setValue(val); }

}
