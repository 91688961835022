import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AmazingTimePickerModule } from './atp-library/atp-time-picker.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { PcgSmartyModule } from '@pcg/pcg-shared';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { PcgSelectComponent } from './components/pcg-select-component/select.component';
import { CollapsibleDividerComponent } from './components/collapsible-divider/collapsible-divider.component';
import { ControlGroupComponent } from './components/control-group/control-group.component';
import { ControlRefDirective } from './directives/control-ref.directive';
import { PhoneDirective } from './directives/masking/phone.directive';
import { PhoneExtDirective } from './directives/masking/phone-ext.directive';
import { StepsComponent } from './components/steps/steps.component';
import { SvgModule } from './svg/svg.module';
import { ClockPickerComponent } from './components/clock-picker/clock-picker.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DualListBoxComponent } from './components/dual-list-box/dual-list-box.component';
import { RadioButtonListComponent } from './components/radio-button-list/radio-button-list.component';
import { TouchRadioButtonListComponent } from './components/touch-radio-button-list/touch-radio-button-list.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FixedButtonsComponent } from './components/fixed-buttons/fixed-buttons.component';
import { NumericDirective } from './directives/masking/numeric.directive';
import { CurrencyService } from './directives/masking/currency.service';
import { CurrencyDirective } from './directives/masking/currency.directive';
import { BackButtonDirective } from './directives/back-button/back-button.directive';
import { PercentageDirective } from './directives/masking/percentage.directive';
import { PercentageService } from './directives/masking/percentage.service';
import { NdcDirective } from './directives/masking/ndc.directive';
import { SubmitUrlDirective } from './directives/submit-url.directive';
import { ShowHidePasswordComponent } from './components/show-hide-password/show-hide-password.component';
import { AutofocusDirective } from './directives/autofocus.directive';

@NgModule({
	declarations: [
		ControlGroupComponent
		, ControlRefDirective
		, CollapsibleDividerComponent
		, PhoneDirective
		, PhoneExtDirective
		, PercentageDirective
		, NdcDirective
		, StepsComponent
		, ClockPickerComponent
		, DateRangeComponent
		, DualListBoxComponent
		, RadioButtonListComponent
		, TouchRadioButtonListComponent
		, FileUploadComponent
		, FixedButtonsComponent
		, NumericDirective
		, CurrencyDirective
		, SubmitUrlDirective
		, BackButtonDirective
		, ShowHidePasswordComponent
		, AutofocusDirective
		, PcgSelectComponent
	],
	imports: [
		CommonModule
		, ReactiveFormsModule
		, FormsModule
		, SvgModule
		, NgbDatepickerModule
		, AmazingTimePickerModule
		, AngularEditorModule
		, AngularDualListBoxModule
		, NgbPopoverModule
		, NgSelectModule
		, NgbTooltipModule
		, RouterModule
		, FontAwesomeModule
		, PcgSmartyModule
	],
	exports: [
		ControlGroupComponent
		, ControlRefDirective
		, CollapsibleDividerComponent
		, PhoneDirective
		, PhoneExtDirective
		, NumericDirective
		, CurrencyDirective
		, PercentageDirective
		, NdcDirective
		, StepsComponent
		, SvgModule
		, ClockPickerComponent
		, DateRangeComponent
		, DualListBoxComponent
		, RadioButtonListComponent
		, TouchRadioButtonListComponent
		, FileUploadComponent
		, FixedButtonsComponent
		, SubmitUrlDirective
		, BackButtonDirective
		, ShowHidePasswordComponent
		, FontAwesomeModule
		, PcgSelectComponent
		, PcgSmartyModule
		, AutofocusDirective
	],
	providers: [
		CurrencyService
		, PercentageService
	]
})
export class FormElementsModule {}
