import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { TableComponent } from '../../table.component';
import { param } from '../../../http-params';


@Component({
	selector: 'pcg-delete-button',
	templateUrl: './delete-button.component.html',
	styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent<T> implements OnDestroy {

	@Input() url: string; // URL to send delete request to
	@Input() id: string; // The id of the record to delete
	@Input() confirmMessage = 'Are you sure you want to delete this?'; // Confirmation message
	@Input() table: TableComponent<T>; // Table for ajax reload
	
	@Output() delete = new EventEmitter<any>(); // Can be used to trigger a custom function on delete

	subscriptions = new Subscription();

	faIconName = { faTrashCan };

	constructor(private ms: SystemMessageService) {}

	deleteRecord() {
		if (!this.confirmMessage || confirm(this.confirmMessage)) {
			const idQueryStr =
				typeof this.id === 'string' || typeof this.id === 'number'
					? 'id=' + encodeURIComponent(this.id)
					: param(this.id);
			this.subscriptions.add(
				this.ms.getHttpObservable(this, this.url + '?' + idQueryStr).subscribe(obj => {
					this.delete.emit(obj);
					if (this.table) { this.table.ajaxReload(); }
				})
			);
		}
	}

	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
