<div
	id="systemMessagesWrapper"
	[@visibilityChanged]="model.messageOpen"
	(@visibilityChanged.start)="displayMessage = 'block'"
	(@visibilityChanged.done)="displayMessage = model.messageOpen ? 'block' : 'none'"
	[style.display]="displayMessage"
	[ngStyle]="model.centeredStyles"
	(click)="closeSystemMessage()"
	class="mobile"
>
	<div [ngClass]="{ 'd-none': model.firstLoad }">
		<div aria-hidden="true" id="systemMessageCloser" class="mobile mobileClose"> X </div>

		<div 
			role="alert" 
			aria-live="polite" 
			aria-atomic="true" 
			id="systemMessages" 
			[ngClass]="[model.messageClass]" 
			class="mobile"
		>
			<span id="sysMessageHeader" class="mobileHide">{{ model.messageClass }}!</span>
			<div [innerHTML]="model.htmlMessage"></div>
		</div>
	</div>
</div>
