import { Injectable } from '@angular/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CheckNetworkService {

    networkStatus$: Subscription = Subscription.EMPTY;

    constructor() { }

    static checkNetworkStatus() {
        var networkStatus: boolean = false;
        var networkStatus$: Subscription = Subscription.EMPTY;
        networkStatus = navigator.onLine;
        networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
            .pipe(map(() => navigator.onLine))
            .subscribe(status => {
                console.log('status', status);
                networkStatus = status;
                return networkStatus;
            }
        );
        return networkStatus;
    }
}
